import {
  VStack,
  Flex,
  Box,
  Spacer,
  Button,
  Text,
  Center,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { GraveColor } from "../../global/interfaces";

interface GravePositionSetterProps {
  plotWidth: number;
  plotHeight: number;
  graveColor: GraveColor;
  setXOffset: (pos: string) => void;
  setYOffset: (pos: string) => void;
}
const maxSize = 120;
const graveHeight = 190;
const graveWidth = 160;

const GravePositionSetter: FunctionComponent<GravePositionSetterProps> = ({
  plotHeight,
  plotWidth,
  graveColor,
  setXOffset,
  setYOffset,
}) => {
  const {
    scaledPlotWidth,
    scaledPlotHeight,
    scaledGraveHeight,
    scaledGraveWidth,
    unscaleFactor,
  } = getScaledDimensions(plotHeight, plotWidth);

  const [gravePositionX, setGravePositionX] = useState(0);
  const [gravePositionY, setGravePositionY] = useState(0);
  const moveGravePosition = (orientation: string) => {
    if (orientation === "up" && gravePositionY > 0) {
      setGravePositionY((pos) => pos - 2);
    }
    if (orientation === "left" && gravePositionX > 0) {
      setGravePositionX((pos) => pos - 2);
    }
    if (
      orientation === "down" &&
      gravePositionY < scaledPlotHeight - scaledGraveHeight - 2
    ) {
      setGravePositionY((pos) => pos + 2);
    }
    if (
      orientation === "right" &&
      gravePositionX < scaledPlotWidth - scaledGraveWidth - 2
    ) {
      setGravePositionX((pos) => pos + 2);
    }

    setXOffset(`${Math.floor(gravePositionX * unscaleFactor)}px`);
    setYOffset(`${Math.floor(gravePositionY * unscaleFactor)}px`);
  };
  return (
    <VStack px="4" width="100%" height={"100%"} spacing={0}>
      <Text fontSize={"sm"}>Position</Text>
      <Flex>
        <Box
          width={`${scaledPlotWidth}px`}
          height={`${scaledPlotHeight}px`}
          border="1px dashed"
          borderColor="primary.600"
        >
          <Center
            position={"relative"}
            left={`${gravePositionX}px`}
            top={`${gravePositionY}px`}
            width={`${scaledGraveWidth}px`}
            height={`${scaledGraveHeight}px`}
            bg="primary.700"
          >
            <Box
              borderTopRadius={"32px"}
              width={"34px"}
              height={"40px"}
              bg={graveColor}
            ></Box>
          </Center>
        </Box>

        <VStack ml={2} spacing={6}>
          <Spacer />
          <Button
            variant="ghost"
            borderTopRadius={"32px"}
            size="xs"
            onClick={() => moveGravePosition("up")}
          >
            ↑
          </Button>
          <Button
            variant="ghost"
            borderBottomRadius={"32px"}
            size="xs"
            onMouseDown={() => moveGravePosition("down")}
          >
            ↓
          </Button>
          <Spacer />
        </VStack>
      </Flex>
      <Flex p={2}>
        <Button
          mx={2}
          variant="ghost"
          borderLeftRadius={"32px"}
          size="xs"
          onClick={() => moveGravePosition("left")}
        >
          ←
        </Button>
        <Button
          mx={2}
          variant="ghost"
          borderRightRadius={"32px"}
          size="xs"
          onClick={() => moveGravePosition("right")}
        >
          →
        </Button>
      </Flex>
    </VStack>
  );
};

export default GravePositionSetter;

const getScaledDimensions = (
  plotHeight: number,
  plotWidth: number
): {
  scaledPlotWidth: number;
  scaledPlotHeight: number;
  scaledGraveWidth: number;
  scaledGraveHeight: number;
  unscaleFactor: number;
} => {
  if (plotHeight < plotWidth) {
    return {
      scaledPlotWidth: maxSize,
      scaledPlotHeight: plotHeight * (maxSize / plotWidth),
      scaledGraveWidth: graveWidth * (maxSize / plotWidth),
      scaledGraveHeight: graveHeight * (maxSize / plotWidth),
      unscaleFactor: plotWidth / maxSize,
    };
  } else {
    return {
      scaledPlotWidth: plotWidth * (maxSize / plotHeight),
      scaledPlotHeight: maxSize,
      scaledGraveWidth: graveWidth * (maxSize / plotHeight),
      scaledGraveHeight: graveHeight * (maxSize / plotHeight),
      unscaleFactor: plotHeight / maxSize,
    };
  }
};
