import { Flex, SimpleGrid } from "@chakra-ui/layout";
import React, { FunctionComponent } from "react";

interface CrossLayoutProps {
  topLeftElement: React.ReactNode;
  topRightElement: React.ReactNode;
  bottomLeftElement: React.ReactNode;
  bottomRightElement: React.ReactNode;
}

const CrossLayout: FunctionComponent<CrossLayoutProps> = (
  props: CrossLayoutProps
) => {
  const {
    topLeftElement,
    topRightElement,
    bottomLeftElement,
    bottomRightElement,
  } = props;
  return (
    <SimpleGrid columns={2} pt={4}>
      <Flex
        borderRightWidth="1px"
        borderBottomWidth="1px"
        borderColor="primary.900"
      >
        {topLeftElement}
      </Flex>
      <Flex>{topRightElement}</Flex>
      <Flex ml="8">{bottomLeftElement}</Flex>
      <Flex
        ml="-1px"
        mt="-1px"
        width="100%"
        borderLeftWidth="1px"
        borderTopWidth="1px"
        borderColor="primary.900"
      >
        {bottomRightElement}
      </Flex>
    </SimpleGrid>
  );
};

export default CrossLayout;
