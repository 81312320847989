import { Box, Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { buyNFT } from "../../flow/buy-nft";
import { cancelListing } from "../../flow/cancel-listing";
import {
  Balance,
  BuyNFTRequestBody,
  NftListing,
} from "../../global/interfaces";
import { toCustomDate } from "../../global/utils";
import { MarketIcon } from "../../icons/MarketIcon";

interface ListingInteractionPanelProps {
  listing: NftListing;
  isUserOwner?: boolean;
  balance: Balance;
}

const ListingInteractionPanel: FunctionComponent<
  ListingInteractionPanelProps
> = ({ listing, balance, isUserOwner }) => {
  return (
    <VStack w={"100%"} py={4}>
      <Box w={"90%"} p={4} boxShadow="md" borderRadius="24px">
        <Flex alignItems="center">
          <MarketIcon
            maxH="32px"
            maxW="32px"
            color="var(--chakra-colors-primary-800)"
          />
          <Text
            px={2}
            fontSize={"lg"}
          >{`Seller: ${listing.storefrontAddress}`}</Text>
        </Flex>
        <HStack py={4}>
          <VStack align={"flex-start"} spacing={0}>
            <Text color="primary.400" fontSize={"md"}>
              Listed on:
            </Text>
            <Text fontSize={"lg"} fontWeight={600}>
              {toCustomDate(listing.createdOn)}
            </Text>
          </VStack>
          <Spacer />
          <VStack align={"flex-end"} spacing={0}>
            <Text color="primary.400" fontSize={"md"}>
              Price:
            </Text>
            <Text fontSize={"lg"} fontWeight={600}>
              {`$${listing.price.toFixed(2)}`}
            </Text>
          </VStack>
        </HStack>
        {isUserOwner ? (
          <TriggerTransactionButton
            transactionRequestObject={
              {
                saleOfferId: listing.listingId,
                saleOwnerAddress: listing.storefrontAddress,
              } as BuyNFTRequestBody
            }
            disabled={false}
            helperText={"Remove your plot from the market."}
            doTransaction={cancelListing}
            buttonText={"Cancel listing"}
          ></TriggerTransactionButton>
        ) : (
          <TriggerTransactionButton
            transactionRequestObject={
              {
                saleOfferId: listing.listingId,
                saleOwnerAddress: listing.storefrontAddress,
              } as BuyNFTRequestBody
            }
            disabled={balance.fusd < listing.price}
            helperText={
              balance.fusd < listing.price
                ? `Your balance of $${balance.fusd.toFixed(
                    2
                  )} not enough for this plot.`
                : ""
            }
            doTransaction={buyNFT}
            buttonText={"Buy plot"}
            price={listing.price.toFixed(2)}
          ></TriggerTransactionButton>
        )}
      </Box>
    </VStack>
  );
};

export default ListingInteractionPanel;
