import { atom, selector, useRecoilState } from "recoil";
import { getTransactions } from "../api/get-transactions.api";
import { TransactionState } from "../global/constants";

const transactionsState = atom({
  key: "transactions",
  default: selector({
    key: "transactions/default",
    get: async ({ get }) => {
      const response = await getTransactions();
      return response.transactions;
    },
  }),
});

export const statusAtom = atom({
  key: "transactions::status",
  default: TransactionState.Idle,
});

export function useTransactions() {
  const [transactions, setTransactions] = useRecoilState(transactionsState);
  const [status, setStatus] = useRecoilState(statusAtom);

  return {
    transactions,
    status,
    async refresh() {
      setStatus(TransactionState.Processing);
      await getTransactions().then((res) => setTransactions(res.transactions));
      setStatus(TransactionState.Idle);
    },
  };
}
