import { VStack, Text } from "@chakra-ui/layout";
import React from "react";
import { FunctionComponent } from "react";
import { GraveDateType } from "../../global/interfaces";

interface GraveDateFromToTextProps {
  fromDate: string;
  toDate: string;
  dateType?: GraveDateType;
  [key: string]: any;
}

const GraveDateFromTo: FunctionComponent<any> = (
  props: GraveDateFromToTextProps
) => {
  const { fromDate, toDate, dateType, ...rest } = props;

  if (dateType === "full") {
    return (
      <VStack position="absolute" width="100%" ml={1} spacing={3.5}>
        <Text
          {...rest}
          overflow="hidden"
          maxWidth="120px"
          whiteSpace="nowrap"
          width="100%"
        >
          {fromDate}
        </Text>
        <Text
          {...rest}
          overflow="hidden"
          maxWidth="120px"
          whiteSpace="nowrap"
          width="100%"
        >
          {toDate}
        </Text>
      </VStack>
    );
  } else if (dateType === "year") {
    return (
      <Text
        position="absolute"
        {...rest}
        bottom="13px"
        maxWidth="120px"
        overflow="hidden"
        ml={4}
        whiteSpace="nowrap"
        width="100%"
      >
        {fromDate} - {toDate}
      </Text>
    );
  }

  return <></>;
};

export default GraveDateFromTo;
