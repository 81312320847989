import {
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TransactionState } from "../global/constants";
import { useBalance } from "../hooks/use-balance.hook";
import { useCurrentUser } from "../hooks/use-current-user.hook";
import { useCustomToast } from "../hooks/use-custom-toast.hook";
import * as fcl from "@onflow/fcl";
import { ExternalLinkIcon } from "../icons/ExternalLinkIcon";
import { useNecryptolisStatus } from "../hooks/use-status.hook";
import { AttentionIcon } from "../icons/AttentionIcon";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TransactionIcon } from "../icons/TransactionIcon";

interface TriggerTransactionButtonProps {
  transactionRequestObject: any;
  disabled: boolean;
  doTransaction: (transactionRequestObject: any, opts: any) => void;
  buttonText: string;
  helperText?: string;
  onSuccessCallback?: () => void;
  onSuccessMessage?: string;
  price?: string;
}

export const TriggerTransactionButton = (
  props: TriggerTransactionButtonProps
) => {
  const {
    transactionRequestObject,
    disabled,
    doTransaction,
    buttonText,
    onSuccessCallback,
    onSuccessMessage,
    helperText,
    price,
  } = props;
  const { refresh: refreshBalance } = useBalance();
  const { isLoggedIn, fclMethods } = useCurrentUser();
  const [status, setStatus] = useState<TransactionState>(TransactionState.Idle);
  const { displayToast } = useCustomToast();
  const [viewTransactionLink, setViewTransactionLink] = useState("");
  const { necryptolisStatus } = useNecryptolisStatus();
  const appInsights = useAppInsightsContext();

  const transactionTrackEvent = useTrackEvent(
    appInsights,
    "transactionTriggerButton",
    transactionRequestObject,
    true
  );

  const fullText = price
    ? `${buttonText} - $${parseFloat(price).toFixed(2)}`
    : buttonText;

  const handleClick = () => {
    if (
      necryptolisStatus.status &&
      necryptolisStatus.status.toLowerCase() !== "ok"
    ) {
      displayToast(
        "Necryptolis is closed right now. Please try later. " +
          necryptolisStatus.message
      );
      return;
    }

    doTransaction(transactionRequestObject, {
      onStart() {
        transactionTrackEvent({
          name: `Transaction started. - ${doTransaction.name}`,
          properties: {
            price,
            ...transactionRequestObject,
          },
        });
        setStatus(TransactionState.Processing);
      },
      async onComplete() {
        appInsights.trackTrace({
          message: "Transaction completed.",
        });
        setStatus(TransactionState.Idle);
      },
      async onError(err: Error | string) {
        displayToast(err);
        appInsights.trackTrace({
          message: `Transaction errored out with. ${JSON.stringify(err)}`,
          severityLevel: 3,
        });
        setStatus(TransactionState.Error);
      },
      async onSubmission(txId: string) {
        appInsights.trackTrace({
          message: `Transaction submitted.`,
        });
        console.log(
          `https://flow-view-source.com/${await fcl
            .config()
            .get("env")}/tx/${txId}`
        );
        setViewTransactionLink(
          `https://flow-view-source.com/${await fcl
            .config()
            .get("env")}/tx/${txId}`
        );
      },
      async onSuccess() {
        displayToast(
          onSuccessMessage || "Blockchain is syncing... Please be patient.",
          "success"
        );

        appInsights.trackTrace({
          message: "Transaction succeeded.",
        });
        refreshBalance();
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        setStatus(TransactionState.Success);
      },
    });
  };

  return (
    <VStack pt={4}>
      <Button
        variant="inverted"
        disabled={status !== TransactionState.Idle || disabled || !isLoggedIn}
        onClick={handleClick}
        leftIcon={
          <TransactionIcon
            maxW="24px"
            maxH="24px"
            color="var(--chakra-colors-primary-100)"
          />
        }
      >
        {fullText}
      </Button>
      {!isLoggedIn && !helperText && (
        <Flex>
          <AttentionIcon
            fillcolor={"var(--chakra-colors-primary-300)"}
            color={"var(--chakra-colors-primary-900)"}
            width={6}
            height={6}
            mx={2}
          />
          <Link fontSize="md" onClick={() => fclMethods.logIn()}>
            Log in to trigger transactions.
          </Link>
        </Flex>
      )}
      {helperText && (
        <HStack mt={2} textAlign={"center"}>
          <Icon />
          <Text fontSize="sm">{helperText}</Text>
        </HStack>
      )}
      {status !== TransactionState.Idle && (
        <Progress
          mt={1}
          width="100%"
          minW="50px"
          borderWidth="1px"
          borderColor="primary.700"
          backgroundColor="primary.800"
          isIndeterminate
          colorScheme="primary"
          borderRadius="4px"
        />
      )}
      {viewTransactionLink && (
        <Link
          px={1}
          color="primary.500"
          fontSize={"sm"}
          textDecoration="underline"
          href={viewTransactionLink}
          isExternal
        >
          View Transaction Status
          <ExternalLinkIcon
            color={"var(--chakra-colors-primary-500)"}
            width="16px"
            height="16px"
            mx="2px"
          />
        </Link>
      )}
    </VStack>
  );
};
