import { Box, Center, Flex, Stack, VStack } from "@chakra-ui/layout";
import {
  Button,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { Grave } from "../grave/Grave";
import {
  GraveShape,
  GraveColor,
  GraveDateType,
  CreateGraveRequestBody,
  GraveFont,
} from "../../global/interfaces";
import GravestoneDateTab from "./GravestoneDateTab";
import GravestoneShapeTab from "./GravestoneShapeTab";
import GravestoneTitleTab from "./GravestoneTitleTab";
import GravePositionSetter from "./GravePositionSetter";
import { BackIcon } from "../../icons/BackIcon";

interface CreateGravestoneFormProps {
  setIsFormValid: (isValid: boolean) => void;
  setMetadata: (metadata: { [key: string]: string | undefined }) => void;
  plotWidth: number;
  plotHeight: number;
  setCreateGravestoneRequestBody: (
    createGravestoneRequestBody: CreateGraveRequestBody
  ) => void;
}

const CreateGravestoneForm: FunctionComponent<CreateGravestoneFormProps> = ({
  setIsFormValid,
  setCreateGravestoneRequestBody,
  setMetadata,
  plotHeight,
  plotWidth,
}: CreateGravestoneFormProps) => {
  const [title, setTitle] = useState<string>("");
  const [graveShape, setGraveShape] = useState<GraveShape | undefined>();
  const [graveColor, setGraveColor] = useState<GraveColor>("tealBright");
  const [graveFont, setGraveFont] = useState<GraveFont>("roman");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [dateType, setDateType] = useState<GraveDateType>("full");
  const [pxOffsetX, setPxOffsetX] = useState("0px");
  const [pxOffsetY, setPxOffsetY] = useState("0px");

  const [progressValue, setProgressValue] = useState<number>(10);
  const [progressMessage, setProgressMessage] = useState<string>("");

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  function isDateValid(fromDate: string, dateType: GraveDateType): boolean {
    if (fromDate || dateType === "empty") {
      return true;
    }

    return false;
  }
  useEffect(() => {
    setIsFormValid(false);
    let progress = 1;
    if (graveShape) {
      progress += 33;
    }
    if (title && title.length < 100) {
      progress += 33;
    }
    if (isDateValid(fromDate, dateType)) {
      progress += 33;
    }
    if (!graveShape) {
      setProgressMessage("Please select gravestone type to progress further.");
    } else if (!title) {
      setProgressMessage("Please type in the title in the Title tab.");
    } else if (title.length >= 100) {
      setProgressMessage("Please keep the title under 100 characters.");
    } else if (!isDateValid(fromDate, dateType)) {
      setProgressMessage("Please input valid date in the Date tab.");
    } else {
      setProgressMessage("Gravestone ready for creation.");
      setIsFormValid(true);
    }

    setProgressValue(progress);
    setCreateGravestoneRequestBody({
      fromDate,
      toDate,
      title,
    } as CreateGraveRequestBody);
    setMetadata({
      shape: graveShape,
      color: graveColor,
      dateType,
      font: graveFont,
      xOffset: pxOffsetX,
      yOffset: pxOffsetY,
    });
  }, [
    graveShape,
    title,
    toDate,
    fromDate,
    dateType,
    setIsFormValid,
    setCreateGravestoneRequestBody,
    setMetadata,
    graveColor,
    graveFont,
    pxOffsetX,
    pxOffsetY,
  ]);

  return (
    <Box>
      <Stack p={8} height={"100%"} direction={["column", "row"]}>
        <VStack>
          <Center ml={2} mr={2} minWidth={"190px"} p={0}>
            {graveShape && (
              <Grave
                title={title}
                fromDate={fromDate}
                toDate={toDate}
                shape={graveShape}
                color={graveColor}
                font={graveFont}
                dateType={dateType}
              />
            )}
          </Center>
          {graveShape && (
            <GravePositionSetter
              plotWidth={plotWidth}
              plotHeight={plotHeight}
              graveColor={graveColor || "teal"}
              setXOffset={setPxOffsetX}
              setYOffset={setPxOffsetY}
            />
          )}
        </VStack>
        <Tabs
          align="center"
          py={2}
          colorScheme="primary"
          index={tabIndex}
          onChange={handleTabsChange}
          borderColor="primary.600"
        >
          <TabList>
            <Tab
              _selected={{
                color: "primary.100",
                borderBottom: "3px solid var(--chakra-colors-primary-100)",
              }}
              color={"primary.500"}
            >
              Shape
            </Tab>
            <Tab
              color={"primary.500"}
              _selected={{
                color: "primary.100",
                borderBottom: "3px solid var(--chakra-colors-primary-100)",
              }}
            >
              Title
            </Tab>
            <Tab
              color={"primary.500"}
              _selected={{
                color: "primary.100",
                borderBottom: "3px solid var(--chakra-colors-primary-100)",
              }}
            >
              Date
            </Tab>
          </TabList>
          <TabPanels padding={2}>
            <TabPanel>
              <VStack>
                <GravestoneShapeTab
                  graveType={graveShape}
                  setGraveType={setGraveShape}
                  graveColor={graveColor}
                  setGraveColor={setGraveColor}
                />
                <Button
                  rightIcon={
                    <BackIcon
                      transform="scaleX(-1.0)"
                      color="var(--chakra-colors-primary-100)"
                      maxH="12px"
                      maxW="12px"
                    />
                  }
                  variant="ghost"
                  onClick={() => setTabIndex(1)}
                >
                  Next
                </Button>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <GravestoneTitleTab
                  title={title}
                  setGraveTitle={setTitle}
                  font={graveFont}
                  setGraveFont={setGraveFont}
                />
                <Button variant="ghost" onClick={() => setTabIndex(2)}>
                  Next
                </Button>
              </VStack>
            </TabPanel>
            <TabPanel>
              <GravestoneDateTab
                dateType={dateType}
                setDateType={setDateType}
                fromDate={fromDate}
                toDate={toDate}
                setToDate={setToDate}
                setFromDate={setFromDate}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <VStack mx={12}>
        <Flex>
          <Icon mx={2} />
          <Text textAlign="center" fontSize="sm">
            {progressMessage}
          </Text>
        </Flex>
        <Progress
          width="100%"
          bgColor="primary.800"
          borderRadius={"8px"}
          colorScheme="primary"
          value={progressValue}
        />
      </VStack>
    </Box>
  );
};

export default CreateGravestoneForm;
