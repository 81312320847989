import React, { Suspense, useEffect, useState } from "react";
import { IconButton, useDisclosure, Spinner, Tooltip, Flex } from "@chakra-ui/react";
import { useCurrentUser } from "../../hooks/use-current-user.hook";
import { AccountInfoHeader } from "./AccountInfoHeader";
import AccountBalance from "./AccountBalance";
import { AccountUninitalizedIcon } from "../../icons/AccountUninitalizedIcon";
import { LoggedInIcon } from "../../icons/LoggedInIcon";
import { LoggedOutIcon } from "../../icons/LoggedOutIcon";
import { useCurrentUserInitalized } from "../../hooks/use-initialized.hook";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import AccountNFTsTab from "./AccountNFTsTab";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import InboxCountBadge from "./InboxCountBadge";
import InboxModal from "./InboxModal";

const AccountCluster = () => {
  const { user, isLoggedIn, fclMethods } = useCurrentUser();
  const { isUserInitalized } = useCurrentUserInitalized();
  const appInsights = useAppInsightsContext();
  const [isMobileUser, setIsMobileUser] = useState(false);

  useEffect(() => {
    function deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    }
    setIsMobileUser(deviceType() !== "desktop");
  }, [setIsMobileUser]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isInboxOpen, onOpen: onInboxOpen, onClose: onInboxCLose } = useDisclosure();
  return (
    <>
      <Tooltip
        label={
          isMobileUser ? "" : !isLoggedIn ? "Log in here!" : "User account"
        }
        placement="bottom"
        defaultIsOpen={!isLoggedIn}
        animation={
          !isLoggedIn
            ? "shake 2.82s cubic-bezier(.36,.07,.19,.97) infinite alternate;"
            : ""
        }
      >
        <Flex position="relative">
          <InboxCountBadge isAnimated address={user.addr} />
          <IconButton
            variant="grave"
            m={2}
            mb={0}
            zIndex={1111}
            height="64px"
            aria-label="Account"
            borderColor={
              isLoggedIn && !isUserInitalized ? "lilac" : "primary.100"
            }
            icon={
              !isLoggedIn ? (
                <LoggedOutIcon maxH="60px" maxW="60px" p={1} />
              ) : isUserInitalized ? (
                <LoggedInIcon
                  maxH="60px"
                  maxW="60px"
                  p={1}
                  color={"var(--chakra-colors-primary-100)"}
                />
              ) : (
                <AccountUninitalizedIcon maxH="64px" maxW="64px" p={1} pl={2} />
              )
            }
            onClick={() => {
              if (!isLoggedIn) {
                appInsights.trackTrace({ message: `Attempting to login.` });
                fclMethods.logIn();
              } else {
                appInsights.trackTrace({ message: `Opening account modal.` });
                onOpen();
              }
            }}
            onTouchEnd={() => {
              setTimeout(function () {
                if (!isOpen) {
                  if (!isLoggedIn) {
                    appInsights.trackTrace({ message: `Attempting to login.` });
                    fclMethods.logIn();
                  } else {
                    appInsights.trackTrace({ message: `Opening account modal.` });
                    onOpen();
                  }
                }
              }, 150);
            }}
          />
        </Flex>
      </Tooltip>
      <ThreeStripedModal
        isOpen={isOpen}
        onClose={onClose}
        modalIcon={
          <LoggedInIcon
            maxH="64px"
            maxW="64px"
            p={1}
            color={"var(--chakra-colors-primary-900)"}
          />
        }
        topContent={
          <AccountInfoHeader
            logout={fclMethods.logOut}
            address={user.addr}
            closeModal={onClose}
            onInboxOpen={onInboxOpen}
          />
        }
        midContent={<AccountBalance />}
        bottomContent={<AccountNFTsTab address={user.addr} />}
      />
      <InboxModal isOpen={isInboxOpen} onClose={onInboxCLose} address={user.addr}/>
    </>
  );
};

const WrappedAccountCluster = () => {
  return (
    <Suspense
      fallback={
        <Tooltip label="User account" placement="bottom">
          <IconButton
            variant="grave"
            m={2}
            mb={0}
            height="64px"
            width="64px"
            aria-label="Account"
            _hover={{ background: "primary.600" }}
            icon={<Spinner />}
          />
        </Tooltip>
      }
    >
      <AccountCluster />
    </Suspense>
  );
};

export default WrappedAccountCluster;
