import { Icon } from "@chakra-ui/react";
import React from "react";

export const BackIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="m20.9 33 22.6-22.6c1-1 1-2.7 0-3.8-1-1-2.7-1-3.8 0L15.2 31.3a2.7 2.7 0 0 0 0 3.8l24.5 24.5a2.7 2.7 0 1 0 3.8-3.8L20.9 33.1z"
    />
  </Icon>
);
