import { Box } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { GetGradientId } from "../icons/GradientDefsFakeGrave";

interface CornerHolesGraveProps {
    frontColor: string;
    backColor: string;
}

export const HighBottomGrave: FunctionComponent<CornerHolesGraveProps> = ({
    frontColor,
    backColor,
}: CornerHolesGraveProps) => (
    <Box maxWidth="150px" position="absolute" >
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="160">
            <path fill={`url(#${GetGradientId(frontColor)})`} d="M73 1C14 2 19 62 19 62v61H5v33h136v-33h-14V62s5-60-54-61z" />
            <path fill={frontColor} d="M81 1C22 2 27 62 27 62v61H13v33h135v-33h-13V62s5-60-54-61z" />
            <path stroke={backColor} strokeWidth=".5" d="M5 156h142.6" />
            <path stroke={backColor} strokeWidth=".5" d="M27 123h108" />
        </svg>
    </Box>
);
