import { Icon } from "@chakra-ui/react";
import React from "react";

export const MarketIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M27.6 57h-12a1 1 0 0 1-1-1V37c0-.6.4-1 1-1h12a1 1 0 0 1 1 1v19a1 1 0 0 1-1 1zm-11-2h10V38h-10v17zM49.5 50.3H34.1a1 1 0 0 1-1-1V37c0-.5.5-1 1-1h15.4a1 1 0 0 1 1 1v12.4a1 1 0 0 1-1 1zm-14.4-2h13.4V38H35.1v10.5zM15.6 28.3h-11a1 1 0 0 1-.8-.4 1 1 0 0 1-.1-.8L9.2 9.8a1 1 0 0 1 .9-.7h8.7a1 1 0 0 1 1 1.2l-3.3 17.2c0 .5-.5.8-1 .8zm-9.6-2h8.8l3-15.2h-7L6 26.4z"
    />
    <path
      fill={props.color}
      d="M11.8 34H8.4a4.7 4.7 0 0 1-4.8-4.7v-2c0-.5.5-1 1-1h11a1 1 0 0 1 1 1v2a4.7 4.7 0 0 1-4.8 4.8zm-6.2-5.7v1a2.8 2.8 0 0 0 2.8 2.8h3.4a2.8 2.8 0 0 0 2.8-2.8v-1h-9z"
    />
    <path
      fill={props.color}
      d="M26.5 28.3h-11a1 1 0 0 1-.9-1.1l3.3-17.3c0-.4.5-.8 1-.8h8.7a1 1 0 0 1 1 1l-1 17.3c-.1.5-.6 1-1 1zm-9.8-2h8.8l1-15.2h-6.9l-2.9 15.3z"
    />
    <path
      fill={props.color}
      d="M22.7 34h-3.4a4.7 4.7 0 0 1-4.7-4.7v-2c0-.5.4-1 1-1h11a1 1 0 0 1 .9 1v2a4.7 4.7 0 0 1-4.8 4.8zm-6.2-5.7v1a2.8 2.8 0 0 0 2.8 2.8h3.4a2.8 2.8 0 0 0 2.8-2.8v-1h-9z"
    />
    <path
      fill={props.color}
      d="M37.4 28.3H26.5a1 1 0 0 1-1-1L26.6 10c0-.5.5-.9 1-.9h8.8c.5 0 1 .4 1 1l1 17.2c0 .3 0 .5-.2.7a1 1 0 0 1-.7.3zm-9.9-2h8.9l-1-15.2h-6.9l-1 15.3z"
    />
    <path
      fill={props.color}
      d="M33.7 34h-3.4a4.7 4.7 0 0 1-4.8-4.7v-2c0-.5.5-1 1-1h11a1 1 0 0 1 1 1v2a4.7 4.7 0 0 1-4.8 4.8zm-6.2-5.7v1a2.8 2.8 0 0 0 2.8 2.8h3.4a2.8 2.8 0 0 0 2.8-2.8v-1h-9z"
    />
    <path
      fill={props.color}
      d="M48.4 28.3h-11a1 1 0 0 1-1-.9l-1-17.2a1 1 0 0 1 1-1H45c.5 0 .9.3 1 .7l3.3 17.2c0 .3 0 .6-.3.9a1 1 0 0 1-.7.3zm-10-2h8.8l-3-15.2h-6.8l1 15.3z"
    />
    <path
      fill={props.color}
      d="M44.6 34h-3.4a4.7 4.7 0 0 1-4.7-4.7v-2c0-.5.4-1 1-1h10.9a1 1 0 0 1 1 1v2a4.7 4.7 0 0 1-4.8 4.8zm-6.2-5.7v1a2.8 2.8 0 0 0 2.8 2.8h3.4a2.8 2.8 0 0 0 2.8-2.8v-1h-9z"
    />
    <path
      fill={props.color}
      d="M59.3 28.3h-11a1 1 0 0 1-.9-.8l-3.3-17.2c0-.3 0-.6.3-.8.1-.2.4-.4.7-.4H54c.4 0 .8.3 1 .7l5.4 17.3c.1.3 0 .6-.1.9a1 1 0 0 1-.9.3zm-10.1-2H58l-4.9-15.2h-6.8l2.9 15.3z"
    />
    <path
      fill={props.color}
      d="M55.6 34H52a4.7 4.7 0 0 1-4.7-4.7v-2c0-.5.4-1 1-1h11a1 1 0 0 1 1 1v2a4.7 4.7 0 0 1-4.8 4.8zm-6.2-5.7v1a2.8 2.8 0 0 0 2.7 2.8h3.5a2.8 2.8 0 0 0 2.7-2.8v-1h-9z"
    />
    <path
      fill={props.color}
      d="M56.2 57H7.6a1 1 0 0 1-1-1V33a1 1 0 0 1 2 0v21.8h46.6V33.1a1 1 0 1 1 2 0v22.8a1 1 0 0 1-1 1z"
    />
  </Icon>
);
