import { Icon } from "@chakra-ui/react";
import React from "react";

export const BuryIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M58 59.3H6.5c-.7 0-1.2-.6-1.2-1.3a9.5 9.5 0 0 1 11-9.4c2-3.3 4.9-6 8.3-7.5a1.3 1.3 0 0 1 1 2.3c-3.2 1.5-5.9 4-7.5 7.2-.3.6-.9.8-1.5.7l-1.8-.3A7 7 0 0 0 8 56.7h48.7a5.4 5.4 0 0 0-7.6-3.6 1.3 1.3 0 0 1-1.8-.7c-1.4-4-4.5-7.2-8.3-9a1.3 1.3 0 1 1 1-2.3c4 1.9 7.4 5.2 9.2 9.2A8 8 0 0 1 59.2 58c0 .7-.5 1.3-1.2 1.3zM32.3 38.9c-2.1 0-3.8-1.7-3.8-3.8v-25c0-.7.6-1.2 1.3-1.2h5c.7 0 1.2.5 1.2 1.2v25c0 2.1-1.7 3.8-3.7 3.8zM31 11.4v23.7a1.2 1.2 0 0 0 2.5 0V11.4H31z"
    />
    <path
      fill={props.color}
      d="M38 11.4H26.5a3 3 0 0 1 0-6.1H38a3 3 0 0 1 0 6.1zM26.5 7.8a.5.5 0 0 0 0 1H38c.3 0 .6-.2.6-.5s-.3-.5-.6-.5H26.5zM31 52.4h-.2a8 8 0 0 1-6.5-7L23 36.1a4.8 4.8 0 0 1 4.8-5.4h1.9c.7 0 1.2.6 1.2 1.3V35a1.2 1.2 0 0 0 2.5 0V32c0-.7.6-1.3 1.3-1.3h1.8a4.8 4.8 0 0 1 4.8 5.4l-1.1 9.3-.2 1a1.3 1.3 0 1 1-2.4-.7v-.6l1.2-9.3c0-.7-.1-1.3-.6-1.8-.4-.5-1-.8-1.7-.8H36v2a3.8 3.8 0 0 1-7.5 0v-2H28c-.6 0-1.2.3-1.7.8-.4.5-.6 1.1-.5 1.8l1 9.3c.4 2.4 2.2 4.3 4.6 4.8a1.3 1.3 0 0 1-.3 2.5z"
    />
    <path
      fill={props.color}
      d="M30.9 55.2c-.7 0-1.3-.6-1.3-1.3v-1c0-1.2.3-5.7 3.3-7.8 2-1.2 5-2 8.6 1.5l1.3 1.4a1.3 1.3 0 1 1-1.9 1.7l-1.2-1.3c-2.6-2.5-4.3-1.9-5.4-1.1-1.6 1-2.2 3.6-2.2 5.7v.9c0 .7-.5 1.3-1.2 1.3zM15.4 55l-.4-.1c-.7-.3-1-1-.7-1.7.9-2.5 1.5-3.7 1.5-3.7.3-.6 1.1-.9 1.7-.6.6.3.9 1 .6 1.7l-1.5 3.5c-.2.5-.7.9-1.2.9z"
    />
  </Icon>
);
