import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RecoilRoot } from "recoil";
import { reactPlugin } from "./global/appInsights";

export function Providers({ children }: any) {
  return (
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <RecoilRoot>
          <ChakraProvider theme={theme}>{children}</ChakraProvider>
        </RecoilRoot>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  );
}
