import { GraveColor } from "../../global/interfaces";
import { themeColors } from "../../theme/theme";

export const stemColorDeviation = 25;
export const nonVerticalness = 350;
export const stemWidth = 3;
export const stemNarrowingRate = 0.9;
export const defaultFlowerColor = "#99c9b3";
export const flowerSizeDeviation = 0.4;
export const flowerColorDeviation = 30;
export const flowerSize = 10;
export const splitFrequency = 0.16;
export const flowerFrequency = 0.12;
export const stemDeathChance = 0.015;
export const minimumStemWidth = 0.8;
export const directionChangeChance = 0.03;
export const flowerMinimumHeight = 8;
export const specialFlowerFrequency = 0.1;

export const upperLimit = (value: number, limit: number): number => {
  if (value > limit) return limit;
  return value;
};

export const GetLianaSettings = (
  id: number,
  graveColor: GraveColor,
  diffDays: number
): LianaSettings => {
  const stemColor =
    graveColor === "teal" ? themeColors.lilac : themeColors.primary[400];
  const flowerColor =
    graveColor === "teal" ? themeColors.lilac : themeColors.primary[100];
  const specialFlowerColor =
    graveColor === "teal" ? themeColors.primary[100] : themeColors.salmon;

  // range from 1.001 (the biggest liana) to ~1.2 (no liana)
  const weeksPassed = diffDays * 0.17 > 7.9 ? 7.9 : diffDays * 0.17;

  const stemNarrowingRate =
    (weeksPassed - 8) * 0.052 * ((weeksPassed - 8) * 0.052) + 1;

  return {
    stemColor,
    flowerColor,
    specialFlowerColor,
    stemNarrowingRate,
  };
};

export interface LianaSettings {
  stemColor: string;
  flowerColor: string;
  specialFlowerColor: string;
  stemNarrowingRate: number;
}

export const hexToColor = (hex: string, q5: any): any => {
  hex = hex.replace("#", "");
  let r = parseInt("0x" + hex.substring(0, 2));
  let g = parseInt("0x" + hex.substring(2, 4));
  let b = parseInt("0x" + hex.substring(4, 6));
  let a = hex.length > 6 ? parseInt("0x" + hex.substring(6, 8)) : 255;
  return q5.color(r, g, b, a);
};
