import { Icon } from "@chakra-ui/react";
import React from "react";

export const AddGraveIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M47.8 49.5h-2.2l2.5-21.3a5.3 5.3 0 0 1-2.4.2l-2.5 21.1H17l-4-34.4h4.4c.4 0 .8-.2 1-.5 0 0 4.8-6.3 11.7-6.3 2 0 3.9.5 5.5 1.3.2-.8.7-1.5 1.3-2A15 15 0 0 0 30 5.8c-7 0-11.9 5.1-13.3 6.8h-5.2a1.2 1.2 0 0 0-1.2 1.3l4.2 35.5h-2.1a1.2 1.2 0 0 0-1.2 1.2v4.6a1.2 1.2 0 0 0 1.2 1.2h35.3a1.2 1.2 0 0 0 1.2-1.2v-4.6a1.2 1.2 0 0 0-1.2-1.2zm-1.2 4.6h-33v-2.2h33v2.2z"
    />
    <circle
      fill="none"
      stroke={props.color}
      strokeWidth="2.3"
      strokeMiterlimit="10"
      cx="44.3"
      cy="17"
      r="11.1"
    />
    <g
      fill="none"
      stroke={props.color}
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path fill={props.color} d="M38.7 17h10.9M44.3 22.4V11.5" />
    </g>
  </Icon>
);
