import { atomFamily, selectorFamily, useRecoilState } from "recoil";
import { getMessages } from "../api/get-messages.api";
import { TransactionState } from "../global/constants";

export const valueAtom = atomFamily({
  key: "messages::state",
  default: selectorFamily({
    key: "messages::default",
    get: (address: string | undefined) => async () => getMessages(address),
  }),
});

export const statusAtom = atomFamily({
  key: "messages::status",
  default: TransactionState.Idle,
});

export function useMessages(address: string | undefined) {
  const [messages, setMessages] = useRecoilState(valueAtom(address));
  const [status, setStatus] = useRecoilState(statusAtom(address));

  return {
    messages: messages.messages,
    status,
    setMessages,
    async refresh() {
      setStatus(TransactionState.Processing);
      await getMessages(address).then(setMessages);
      setStatus(TransactionState.Idle);
    },
  };
}
