import { atomFamily, selectorFamily, useRecoilState } from "recoil";
import { getUserdata } from "../api/get-user-data.api";
import { TransactionState } from "../global/constants";

export const valueAtom = atomFamily({
  key: "user-data::state",
  default: selectorFamily({
    key: "user-data::default",
    get: (address: string | undefined) => async () => getUserdata(address),
  }),
});

export const statusAtom = atomFamily({
  key: "user-data::status",
  default: TransactionState.Idle,
});

export function useUserData(address: string | undefined) {
  const [userData, setUserData] = useRecoilState(valueAtom(address));
  const [status, setStatus] = useRecoilState(statusAtom(address));

  return {
    userData,
    status,
    async refresh() {
      setStatus(TransactionState.Processing);
      await getUserdata(address).then(setUserData);
      setStatus(TransactionState.Idle);
    },
  };
}
