import { MessagesResponse } from "../global/interfaces";

export const getMessages = async (
  address: string | undefined,
  page?: number
): Promise<MessagesResponse> => {
  let response = null;
  const emptyResponse = { messages: [], unreadCount: 0 }
  if (!address) {
    return emptyResponse
  }  
  try {
    response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/messages?address=${address}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const retval = (await response.json()) as MessagesResponse;
    retval.messages.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())

    return retval;
  } catch (error) {
    console.log("error getting messages");
    return emptyResponse;
  }
};
