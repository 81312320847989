import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import {
  Center,
  Flex,
  HStack,
  Input,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { FunctionComponent } from "react";
import { SelectIconButton } from "../../components/SelectIconButton";
import { GraveDateType } from "../../global/interfaces";

interface GravestoneDateTabProps {
  dateType: GraveDateType | undefined;
  setDateType: (type: GraveDateType) => void;
  fromDate: string;
  setFromDate: (date: string) => void;
  toDate: string;
  setToDate: (date: string) => void;
}

const GravestoneDateTab: FunctionComponent<GravestoneDateTabProps> = ({
  dateType,
  setDateType,
  toDate,
  setToDate,
  fromDate,
  setFromDate,
}) => {
  return (
    <VStack>
      <FormControl id="shape">
        <FormHelperText textColor="primary.100" textAlign="center">
          Select date type
        </FormHelperText>
        <Center>
          <HStack my={2}>
            {SelectIconButton(
              <Text>Year</Text>,
              () => setDateType("year"),
              dateType === "year"
            )}
            {SelectIconButton(
              <Text>Full</Text>,
              () => setDateType("full"),
              dateType === "full"
            )}
            {SelectIconButton(
              <Text>Nil</Text>,
              () => setDateType("empty"),
              dateType === "empty"
            )}
          </HStack>
        </Center>
      </FormControl>
      <Box minH="140px">
        {dateType === "year" && (
          <Flex>
            <FormControl id="fromDate" pr={2}>
              <FormLabel mb={0}>From:</FormLabel>
              <Input
                borderColor="primary.100"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </FormControl>
            <Text mt={7}>-</Text>
            <FormControl id="toDate" pl={2}>
              <FormLabel mb={0}>To:</FormLabel>
              <Input
                borderColor="primary.100"
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </FormControl>
          </Flex>
        )}
        {dateType === "full" && (
          <VStack>
            <FormControl id="fromDate">
              <FormLabel mb={0}>From:</FormLabel>
              <Input
                borderColor="primary.100"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </FormControl>

            <FormControl id="toDate">
              <FormLabel mb={0}>To:</FormLabel>
              <Input
                borderColor="primary.100"
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </FormControl>
          </VStack>
        )}
      </Box>
    </VStack>
  );
};

export default GravestoneDateTab;
