import { Icon } from "@chakra-ui/react";
import React from "react";

export const LoggedInIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M22.8 46.7v12a1 1 0 1 1-2.2 0v-5.5h-6.5v5.5a1 1 0 1 1-2.2 0V52C12 43 18 35 26.7 32.3a14.2 14.2 0 1 1 11.8 0c3.6 1 6.6 3 9 5.6h-3A18.5 18.5 0 0 0 14.1 51h6.4v-4.4a1 1 0 1 1 2.2 0zm21.8-27.3a12 12 0 1 0-24 0 12 12 0 0 0 24 0z"
    />
    <circle
      fill="none"
      stroke={props.color}
      strokeWidth="2"
      strokeMiterlimit="10"
      cx="49.1"
      cy="49.8"
      r="11.9"
    />
    <path
      fill="none"
      stroke={props.color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="m43.1 49.6 3 3.9 9.4-7.7"
    />
  </Icon>
);
