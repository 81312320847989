import { Icon } from "@chakra-ui/react";
import React from "react";

export const LocationIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill="none"
      stroke={props.color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M54.5 27C54.5 44.5 32 59.5 32 59.5S9.5 44.5 9.5 27a22.5 22.5 0 0 1 45 0z"
    />
    <circle
      fill="none"
      stroke={props.color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="32"
      cy="27"
      r="7.5"
    />
  </Icon>
);
