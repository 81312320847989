import { useEffect } from "react";
import {
  atom,
  selector,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { getPlotDatas } from "../api/get-plot-datas.api";
import { CemeteryPlotData } from "../global/interfaces";
import { expandCoordinates, serializeCoordinates } from "../global/utils";

const fetchedPlots = atom({
  key: "fetchedPlots",
  default: [] as CemeteryPlotData[],
});

export const currentCoordinatesState = atom({
  key: "CurrentCoordinates",
  default: serializeCoordinates({
    topLeft: { x: 0, y: 0 },
    bottomRight: { x: 0, y: 0 },
  }),
});

const plotDatasQuery = selector({
  key: "CurrentPlotDatas",
  get: async ({ get }) => {    
    const plots = await getPlotDatas(
      expandCoordinates(get(currentCoordinatesState))
    );

    return plots;
  },
});

export function usePlotDatas(): [SetterOrUpdater<string>, CemeteryPlotData[]] {
  const setCurrentScreenCoordinates = useSetRecoilState(
    currentCoordinatesState
  );
  const [fetchedPlotsState, setFetchedPlotsState] =
    useRecoilState(fetchedPlots);

  const plotsLoadable = useRecoilValueLoadable(plotDatasQuery);

  useEffect(() => {
    if (plotsLoadable.state === "hasValue")
      setFetchedPlotsState(plotsLoadable.contents);
  }, [plotsLoadable, setFetchedPlotsState]);

  switch (plotsLoadable.state) {
    case "hasValue":
      return [setCurrentScreenCoordinates, fetchedPlotsState];
    case "loading":
      return [setCurrentScreenCoordinates, fetchedPlotsState];
    case "hasError":
      return [setCurrentScreenCoordinates, []];
  }
}
