import {
  Box,
  Center,
  Text,
  Image,
  VStack,
  HStack,
  Badge,
  IconButton,
  Spinner,
  Flex,
  Button,
  Link,
} from "@chakra-ui/react";
import Logo from "../assets/necryptolis logo.svg";

import React, { Suspense } from "react";
import NecryptolisInfoCluster from "../clusters/necryptolisInfo/NecryptolisInfoCluster";
import { useNecryptolisStatus } from "../hooks/use-status.hook";
import { AttentionIcon } from "../icons/AttentionIcon";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const CentralPlot = () => {
  const { necryptolisStatus } = useNecryptolisStatus();
  const appInsights = useAppInsightsContext();
  appInsights.trackPageView({});

  const { message, status } = necryptolisStatus;

  const LogoHeadline = () => {
    if (!status || status.toLowerCase() === "ok")
      return <Image p={8} width="100%" alt="Necryptolis logo" src={Logo} />;
    else {
      return (
        <VStack>
          <Box
            position="absolute"
            bg="primary.700"
            p={1}
            borderRadius={8}
            transform="rotate(-30deg)"
            left="90px"
            top="35px"
          >
            <Box
              bg="primary.700"
              borderWidth={"1px"}
              borderColor="primary.100"
              borderRadius={8}
              px={4}
              py={2}
            >
              <Text color="primary.100" fontWeight={900}>
                CLOSED
              </Text>
            </Box>
          </Box>
          <Image
            p={8}
            pb={message ? 2 : 6}
            width="100%"
            alt="Necryptolis logo"
            src={Logo}
          />
          {message && (
            <Flex alignItems={"center"}>
              <AttentionIcon
                height="24px"
                width="24px"
                color="salmon"
                m={1}
              ></AttentionIcon>
              <Text color="primary.100" fontWeight={400} fontSize="sm">
                {message}
              </Text>
            </Flex>
          )}
        </VStack>
      );
    }
  };
  return (
    <Box height="300px" width="300px">
      <LogoHeadline />
      <Badge position="absolute" top="0px" right="0px" bg="primary.400" color="primary.600">
        {process.env["REACT_APP_ENV"] === "prod"
          ? "beta"
          : process.env["REACT_APP_ENV"]}
      </Badge>
      <Center>
        <VStack>
          <HStack spacing={0}>
            <Link href="https://web.necryptolis.com/faq" isExternal>
              <Button onTouchStart={() => window.location.href = 'https://web.necryptolis.com/faq'} fontWeight={"medium"} fontSize={"small"} variant={"grave"} borderRightRadius={6} p={2} height="28px" marginTop="36px">
                FAQ
              </Button>
            </Link>
            <NecryptolisInfoCluster />
            <Link href="https://web.necryptolis.com/blog" isExternal>
              <Button onTouchStart={() => window.location.href = 'https://web.necryptolis.com/blog'} fontWeight={"medium"} fontSize={"small"} variant={"grave"} borderLeftRadius={6} p={2} height="28px" marginTop="36px !important">
                Blog
              </Button>
            </Link>
          </HStack>
          <Text color={"primary.100"} fontWeight={100}>
            Internet's own graveyard.
          </Text>
        </VStack>
      </Center>
    </Box >
  );
};

export const WrappedCentralPlot = () => {
  return (
    <Suspense
      fallback={
        <Box height="300px" width="300px">
          <Image p={8} width="100%" alt="Necryptolis logo" src={Logo} />
          <Center>
            <VStack>
              <HStack>
                <IconButton
                  variant="grave"
                  m={6}
                  height="64px"
                  width="64px"
                  aria-label="Necryptolis"
                  icon={<Spinner />}
                />
              </HStack>
              <Text color={"primary.100"} fontWeight={100}>
                Internet's own graveyard.
              </Text>
            </VStack>
          </Center>
        </Box>
      }
    >
      <CentralPlot />
    </Suspense>
  );
};

export default WrappedCentralPlot;
