import {
  hexToColor,
  nonVerticalness,
  stemColorDeviation,
} from "./LianaSettings";

class Stem {
  x: number;
  y: number;
  stemWidth: number;
  direction: number;
  stemColor: string;
  stemNarrowingRate: number;

  constructor(
    x: number,
    y: number,
    stemWidth: number,
    p5: any,
    stemColor: string,
    stemNarrowingRate: number
  ) {
    this.x = x;
    this.y = y;
    this.stemWidth = stemWidth;
    this.direction = p5.map(p5.round(p5.random(0, 1)), 0, 1, -1, 1);
    this.stemColor = stemColor;
    this.stemNarrowingRate = stemNarrowingRate;
  }

  grow(p5: any) {
    let newX =
      this.x -
      (p5.random(0, (nonVerticalness * 300) / 400) * this.direction) / 30;
    let newY = this.y - 10;

    this.stemWidth /= this.stemNarrowingRate;

    p5.stroke(
      randomStemColor(p5, hexToColor(this.stemColor, p5), stemColorDeviation)
    );
    p5.strokeWeight(this.stemWidth);
    p5.line(this.x, this.y, newX, newY);

    this.x = newX;
    this.y = newY;
  }
}

const randomStemColor = (
  p5: any,
  stemColor: any,
  stemColorDeviation: number
) => {
  return p5.color(
    p5.red(stemColor) +
      p5.random(-stemColorDeviation, stemColorDeviation) * 0.2,
    p5.green(stemColor) +
      p5.random(-stemColorDeviation, stemColorDeviation) * 0.8,
    p5.blue(stemColor) +
      p5.random(-stemColorDeviation, stemColorDeviation) * 0.4
  );
};

export { Stem };
