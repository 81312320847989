import { Icon } from "@chakra-ui/react";
import React from "react";

export const TopOvalGraveIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M16.9 62.9a2 2 0 0 1-2-1.8L7.2 16.8a2 2 0 0 1 2-2.5H17a15.3 15.3 0 0 1 30.2 0h7.6a2 2 0 0 1 2 2.5L49.2 61a2 2 0 0 1-2 1.8H16.8z"
    />
  </Icon>
);
