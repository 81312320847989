import { useEffect, useState } from "react";
import { ScreenCoordinates } from "../global/interfaces";

export function useScrollDebounce(
  delay = 300
): [ScreenCoordinates, (value: ScreenCoordinates) => void] {
  const [screenCoordinates, setScreenCoordinates] = useState<ScreenCoordinates>(
    { topLeft: { x: 0, y: 0 }, bottomRight: { x: 0, y: 0 } }
  );
  const [newCoordinates, setNewCoordinates] = useState<ScreenCoordinates>({
    topLeft: { x: 0, y: 0 },
    bottomRight: { x: 0, y: 0 },
  });

  useEffect(() => {
    if (
      Math.abs(newCoordinates.topLeft.x - screenCoordinates.topLeft.x) > 99 ||
      Math.abs(newCoordinates.topLeft.y - screenCoordinates.topLeft.y) > 99 ||
      Math.abs(newCoordinates.bottomRight.x - screenCoordinates.bottomRight.x) >
        99 ||
      Math.abs(newCoordinates.bottomRight.y - screenCoordinates.bottomRight.y) >
        99
    ) {
      const delayFn = setTimeout(() => {
        setScreenCoordinates(newCoordinates);
      }, delay);

      return () => clearTimeout(delayFn);
    }
  }, [
    newCoordinates,
    delay,
    screenCoordinates.topLeft.x,
    screenCoordinates.topLeft.y,
    screenCoordinates.bottomRight.x,
    screenCoordinates.bottomRight.y,
  ]);

  return [screenCoordinates, setNewCoordinates];
}
