import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { NFTMetadata, NFTPlatform } from "../global/interfaces";

//https://flow-view-source.com/testnet/account/0x3c7e227e52ac6c0d/contract/Flovatar
//import Art from 0xd796ff17107bbff6
const CODE = fcl.cdc`
    import Flovatar from 0xFlovatar
    import FlovatarMarketplace from 0xFlovatarMarketplace
    import TopShot from 0xTopShot    
    import MetadataViews from 0xMetadataViews 
    
    pub struct MetadataCollectionItem {
        pub let id:UInt64
        pub let name: String?
        pub let image: String?
        pub let url: String?
        pub let contentType:String?
        pub let rarity:String?
    
        init(id:UInt64, name:String, image:String, url:String, contentType: String, rarity: String) {
            self.id=id
            self.name=name
            self.url=url
            self.image=image
            self.contentType=contentType
            self.rarity=rarity
        }
    }
    

    pub fun main(address: Address): {String : [MetadataCollectionItem]}? {    
        let resultMap : {String : [MetadataCollectionItem]} = {}
        let account = getAccount(address)

        let flovatarList= Flovatar.getFlovatars(address: address)
        
        if flovatarList.length > 0 {
            let items: [MetadataCollectionItem] = []
            for flovatar in flovatarList  {
                var name = flovatar.name
                if name == "" {
                    name="Flovatar #".concat(flovatar.id.toString())
                }

                var rarity="common"
                if flovatar.metadata.legendaryCount > 0 {
                    rarity="legendary"
                }else if flovatar.metadata.epicCount > 0 {
                    rarity="epic"
                }else if flovatar.metadata.rareCount > 0 {
                    rarity="rare"
                }


                let item=MetadataCollectionItem(
                    id: flovatar.id, 
                    name: name, 
                    image: "https://flovatar.com/api/image/".concat(flovatar.id.toString()),
                    url: "https://flovatar.com/flovatars/".concat(flovatar.id.toString()).concat("/"),
                    contentType: "image",
                    rarity: rarity
                )
                
                items.append(item)                
            }

            if items.length != 0 {
                resultMap["Flovatar"] = items
            }
        }

        if let col = account.getCapability(/public/MomentCollection)
        .borrow<&{TopShot.MomentCollectionPublic}>() {            
            let items: [MetadataCollectionItem] = []

            for nbaTopShotId in col.getIDs() {
                let nft = col.borrowMoment(id: nbaTopShotId)!

                let metadata = TopShot.getPlayMetaData(playID: nft!.data.playID)!
                let momentsInEditionCount = TopShot.getNumMomentsInEdition(setID: nft!.data.setID, playID: nft!.data.playID)!

                let item = MetadataCollectionItem(
                    id: nbaTopShotId, 
                    name: metadata["FullName"]!.concat(" - ").concat(metadata["PlayCategory"]!), 
                    image: "",
                    url: "",
                    contentType: "image",
                    rarity: nft.data.serialNumber.toString().concat("/").concat(momentsInEditionCount.toString())
                )
                items.append(item)
            }

            if items.length != 0 {
                resultMap["NBA Top Shot"] = items
            }
        }

        return resultMap
    }
`;

export async function fetchPlatformNfts(
    platform: NFTPlatform,
    address: string
): Promise<{ [key: string]: [NFTMetadata] } | string> {
    if (!address) {
        return {};
    }
    try {
        const userNftCollection = (await fcl
            .send([fcl.script(CODE), fcl.args([fcl.arg(address, t.Address)])])
            .then(fcl.decode)) as any;
        
        return userNftCollection;
    } catch (error) {
        console.log("error fetchPlatformNfts", error);
        return "Blockchain ERROR";
    }
}
