import { Icon } from "@chakra-ui/react";
import React from "react";

export const InboxIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>    
    <path 
      fill={props.color}
    d="M7.18 12.7a6.08 6.08 0 0 0-6.06 6.07v30.31a6.08 6.08 0 0 0 6.06 6.07h50.01a6.08 6.08 0 0 0 6.06-6.07v-30.3a6.08 6.08 0 0 0-6.06-6.07zm0 3.04h50.01c.44 0 .84.1 1.21.26L34.08 37.71a2.66 2.66 0 0 1-3.74 0L5.97 16c.37-.16.77-.26 1.2-.26Zm-3.01 2.72L21.6 34 4.48 50.46c-.2-.41-.33-.88-.33-1.38V18.77l.02-.3zm56.03 0 .02.31v30.31c0 .5-.13.97-.33 1.38L42.82 33.97l17.38-15.5ZM40.54 36.01l16.72 16.1H7.11l16.76-16.07 4.45 3.95a5.82 5.82 0 0 0 7.77 0l4.45-3.98z"/>
  </Icon>
);
