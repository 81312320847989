import {
  Divider,
  HStack,
  Icon,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { Suspense } from "react";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { trimGrave } from "../../flow/trim-grave";
import { useBalance } from "../../hooks/use-balance.hook";
import { usePlotSalesInfo } from "../../hooks/use-plot-sales-info.hook";
import { CutBushIcon } from "../../icons/CutBushIcon";

interface TrimGraveModalProps {
  graveId: number;
  graveOwnerAddress: string;
  lastTrimmed: string;
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
}

const TrimGraveModal: FunctionComponent<TrimGraveModalProps> = ({
  graveId,
  graveOwnerAddress,
  isOpen,
  onClose,
  lastTrimmed,
  onBack,
}: TrimGraveModalProps) => {
  const { balance } = useBalance();
  const { trimPrice } = usePlotSalesInfo();

  return (
    <ThreeStripedModal
      isOpen={isOpen}
      onClose={onClose}
      goBackCallback={onBack}
      title={"Trimming"}
      modalIcon={
        <CutBushIcon
          maxH="64px"
          maxW="64px"
          p={1}
          color={"var(--chakra-colors-primary-900)"}
        />
      }
      topContent={
        <VStack px={4} pb={4}>
          <Text fontSize={"sm"}>Last trimmed on:</Text>
          <Text>{lastTrimmed}</Text>
        </VStack>
      }
      midContent={
        <HStack height="60px" m={8} mb={2} spacing={4}>
          <Stat minW="150px" textAlign="right">
            <StatLabel>Trim Price</StatLabel>
            <StatNumber fontSize="xl">
              ${parseFloat(trimPrice).toFixed(2)}
            </StatNumber>
            <StatHelpText>FUSD</StatHelpText>
          </Stat>
          <Divider orientation="vertical" borderColor="primary.100" />
          <Stat minW="150px" textAlign="left">
            <StatLabel>Balance</StatLabel>
            <StatNumber fontSize="xl">{balance.fusd.toFixed(2)}</StatNumber>
            <StatHelpText>FUSD</StatHelpText>
          </Stat>
        </HStack>
      }
      bottomContent={
        <VStack p={4} px={8} spacing={4}>
          <HStack m={2}>
            <Icon />
            <Text fontSize="md" textAlign={"center"}>
              Weeds start to grow out again around 7 days after a trim.
            </Text>
          </HStack>

          <TriggerTransactionButton
            transactionRequestObject={{ graveId, graveOwnerAddress }}
            doTransaction={trimGrave}
            buttonText={"Trim grave"}
            onSuccessCallback={onClose}
            onSuccessMessage={
              "Your trimming order has been processed. The grave will be trimmed in a minute or so."
            }
            disabled={parseFloat(trimPrice) > balance.fusd}
            price={trimPrice}
          />
        </VStack>
      }
    />
  );
};

const WrappedTrimGraveModal = (props: TrimGraveModalProps) => {
  return (
    <Suspense fallback={<></>}>
      <TrimGraveModal {...props} />
    </Suspense>
  );
};

export default WrappedTrimGraveModal;
