import { Icon } from "@chakra-ui/react";
import React from "react";

export const HomeIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color || "var(--chakra-colors-primary-100)"}
      d="M60.7 28.2h-7v23a3.6 3.6 0 0 1-3.6 3.6h-7.3a3.6 3.6 0 0 1-3.6-3.7V40.3a7.3 7.3 0 1 0-14.5 0V51a3.6 3.6 0 0 1-3.7 3.7h-7.2A3.6 3.6 0 0 1 10 51v-23h-7a1.2 1.2 0 0 1-.6-2.2l8.8-5.1v-8.5a1.2 1.2 0 0 1 1.2-1.2h4.8a1.2 1.2 0 0 1 1.2 1.2v4.3L31.3 9c.4-.2.9-.2 1.2 0l28.8 16.8a1.2 1.2 0 0 1-.6 2.3z"
    />
  </Icon>
);
