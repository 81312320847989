import { IconButton } from "@chakra-ui/react";
import React from "react";

export const SelectIconButton = (
  icon: React.ReactElement,
  onClickHandler?: () => void,
  isSelected?: boolean,
  size?: number,
  disabled?: boolean
) => {
  const pixelSize = size ? size : 40;
  return (
    <IconButton
      disabled={disabled}
      borderWidth={isSelected ? "2px" : "1px"}
      onClick={onClickHandler}
      width={isSelected ? `${pixelSize + 6}px` : `${pixelSize}px`}
      height={isSelected ? `${pixelSize + 6}px` : `${pixelSize}px`}
      variant="ghost"
      bg={isSelected ? "primary.500" : "primary.900"}
      aria-label="Search database"
      icon={icon}
    />
  );
};
