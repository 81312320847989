import { Icon } from "@chakra-ui/react";
import React from "react";

export const AttentionIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M57.9 44a7.7 7.7 0 0 1 0 8c-1.4 2.4-4 3.9-6.8 3.9H12.9a7.8 7.8 0 0 1-6.8-12l19.1-32c1.4-2.4 4-3.8 6.8-3.8s5.3 1.4 6.8 3.9l19 32zm-4 5.6c.6-1 .6-2.2 0-3.3l-19-32c-.7-1-1.7-1.6-2.9-1.6s-2.2.6-2.8 1.6L10 46.3c-.6 1-.6 2.3 0 3.3.5 1 1.6 1.7 2.8 1.7H51c1.2 0 2.3-.6 2.9-1.7z"
    />
    <path
      opacity={props.fillcolor ? 1 : 0}
      fill={props.fillcolor}
      d="M54 46.3c.5 1 .5 2.3 0 3.3-.6 1-1.7 1.7-2.9 1.7H13a3.2 3.2 0 0 1-2.8-5l19-32c.7-1 1.7-1.6 2.9-1.6s2.2.6 2.9 1.6l19 32z"
    />
    <path
      fill={props.color}
      d="M34.9 44.3a2.8 2.8 0 1 1-5.7 0 2.8 2.8 0 0 1 5.7 0M31.7 22.2c-1.7.1-3 1.7-2.9 3.4L30 37.3c.1 1 1 1.8 2 2 1.1 0 2.2-.8 2.3-2l1-11.7v-.5c-.2-1.8-1.7-3.1-3.5-3z"
    />
  </Icon>
);
