import * as React from "react";
import { Box, HStack, IconButton, Tooltip } from "@chakra-ui/react";
import InfiniteViewer from "react-infinite-viewer";
import PlotCreator from "./components/PlotCreator";
import CentralPlot from "./components/CentralPlot";
import CemeteryPlotsCluster from "./clusters/cemeteryPlots/CemeteryPlotsCluster";
import { ScrollToCallbackAtom } from "./hooks/use-scroll-to.hook";
import { useRecoilState } from "recoil";
import { useCallback, useRef } from "react";
import AccountCluster from "./clusters/account/AccountCluster";
import { Coordinates } from "./global/interfaces";
import { AddPlotIcon } from "./icons/AddPlotIcon";
import TopLeftCoordinatesBox from "./components/TopLeftCoordinatesBox";

export const App = () => {
  const viewerRef = useRef<InfiniteViewer>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const updateTopLeftCoordinatesRef =
    useRef<(coordinates: Coordinates) => void>();

  const currentTopLeftCoordinatesRef = useRef<Coordinates>({ x: 0, y: 0 });

  const plotCreatorIconButtonRef = useRef<HTMLButtonElement>(null);
  const [, setScrollTo] = useRecoilState(ScrollToCallbackAtom);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let queryParamX = params.get("x");
  let queryParamY = params.get("y");
  let selectedPlotId = params.get("id");

  const updatePlotsFuncRef =
    React.useRef<(x1: number, y1: number, x2: number, y2: number) => void>(
      null
    );

  const showPlotCreatorFuncRef =
    React.useRef<(x: number, y: number) => void>(null);

  const scrollToCallback = useCallback(
    (x: number, y: number) => viewerRef.current?.scrollTo(x, y),
    []
  );

  React.useEffect(() => {
    const containerHeight = (viewerRef.current as any).infiniteViewer
      .containerHeight;
    const containerWidth = (viewerRef.current as any).infiniteViewer
      .containerWidth;

    setScrollTo(() => scrollToCallback);

    setTimeout(() => {
      if (queryParamX && queryParamY) {
        viewerRef.current!.scrollTo(
          parseInt(queryParamX) - containerWidth / 2 + 100,
          -parseInt(queryParamY) - containerHeight / 2 + 100
        );
      } else {
        viewerRef.current!.scrollTo(
          -containerWidth / 2 + 150,
          -containerHeight / 2 + 150
        );
      }
    }, 100);
  });

  return (
    <div className="container">
      <HStack
        spacing={2}
        position="absolute"
        top="10px"
        right="10px"
        zIndex={1}
        id="top-right-buttons"
      >
        <AccountCluster />

        <Tooltip label="Create new plot" placement="bottom">
          <IconButton
            ref={plotCreatorIconButtonRef}
            width={"64px"}
            height={"64px"}
            mt={"8px !important"}
            variant="grave"
            aria-label="Add Plot"
            fontSize="18px"
            icon={<AddPlotIcon maxH="50px" maxW="50px" />}
            onClick={() => {
              const {
                offsetY,
                containerHeight,
                scrollTop,
                scrollLeft,
                offsetX,
                containerWidth,
              } = (viewerRef.current as any).infiniteViewer;
              const y = scrollTop + offsetY + containerHeight * 0.5;
              const x = scrollLeft + offsetX + containerWidth * 0.5;
              if (showPlotCreatorFuncRef.current)
                showPlotCreatorFuncRef.current(x, y);
            }}
          />
        </Tooltip>
      </HStack>
      <TopLeftCoordinatesBox
        updateTopLeftCoordinatesRef={updateTopLeftCoordinatesRef}
        viewerRef={viewerRef}
      />
      <Box height="100vh">
        <InfiniteViewer
          className="infinite-viewer"
          ref={viewerRef}
          onScroll={(e) => {
            currentTopLeftCoordinatesRef.current = {
              x: e.scrollLeft,
              y: e.scrollTop,
            };
            if (
              updateTopLeftCoordinatesRef &&
              updateTopLeftCoordinatesRef.current
            ) {
              updateTopLeftCoordinatesRef.current({
                x:
                  e.scrollLeft +
                  (e as any).currentTarget.containerWidth / 2 -
                  150,
                y:
                  e.scrollTop +
                  (e as any).currentTarget.containerHeight / 2 -
                  150,
              });
            }

            if (updatePlotsFuncRef && updatePlotsFuncRef.current) {
              updatePlotsFuncRef.current(
                e.scrollLeft,
                e.scrollTop,
                e.scrollLeft + ((e as any).currentTarget.containerWidth / (e as any).currentTarget.options.zoom),
                e.scrollTop + ((e as any).currentTarget.containerHeight / (e as any).currentTarget.options.zoom)
              );
            }
          }}
        >
          <div
            id="all-container"
            style={{
              width: "300px",
              height: "300px",
              zIndex: 1000,
              borderColor: "var(--chakra-colors-primary-700)",
            }}
          >
            <CentralPlot />
            <CemeteryPlotsCluster
              selectedPlotId={selectedPlotId}
              updatePlotsFuncRef={updatePlotsFuncRef}
              topLeftCoordinatesRef={currentTopLeftCoordinatesRef}
            />
            <div className="target" ref={targetRef}></div>

            <PlotCreator
              showPlotCreatorFuncRef={showPlotCreatorFuncRef}
              viewerRef={viewerRef}
              targetRef={targetRef}
              closePlotCreator={() => {
                if (plotCreatorIconButtonRef.current)
                  plotCreatorIconButtonRef.current.hidden = false;
              }}
            />
          </div>
        </InfiniteViewer>
      </Box>
    </div>
  );
};
