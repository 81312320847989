import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { LightCandleRequestBody } from "../global/interfaces";
import { tx, TxOptions } from "./utils/tx";

const CODE = fcl.cdc`
    import Necryptolis from 0xNecryptolis
    import NonFungibleToken from 0xNonFungibleToken
    import FUSD from 0xFUSD
    
    transaction(address: Address, cemeteryPlotId: UInt64) {
      let mainFusdVault: &FUSD.Vault?
      let buyerAddress: Address
    
      prepare(acct: AuthAccount) {    
        self.mainFusdVault = acct.borrow<&FUSD.Vault>(from: /storage/fusdVault) ?? panic("No FUSD vault in acct storage")
        self.buyerAddress = acct.address
      }
    
      execute {   
        let candlePrice = Necryptolis.plotSalesInfo.candlePrice
        let paymentVault <- self.mainFusdVault!.withdraw(amount: candlePrice)        
    
        let account = getAccount(address)
    
        let collectionRef = account.getCapability(Necryptolis.CollectionPublicPath).borrow<&{Necryptolis.NecryptolisCollectionPublic}>()
            ?? panic("Could not borrow capability from public collection")
        collectionRef.lightCandle(cemeteryPlotId: cemeteryPlotId, buyerPayment: <- paymentVault, buyerAddress: self.buyerAddress)
      }
    }
`;

export const lightCandle = async (
  lightCandleRequest: LightCandleRequestBody,
  opts: TxOptions = {}
) => {
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(lightCandleRequest.graveOwnerAddress, t.Address),
        fcl.arg(lightCandleRequest.graveId, t.UInt64),
      ]),
      fcl.proposer(fcl.authz),
      fcl.payer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
    ],
    opts
  );
};
