import { Icon } from "@chakra-ui/react";
import React from "react";

export const FlovatarEmbossIcon = (props: any) => {
  const { mainColor, shadowColor, highlightColor, ...otherProps } = props;

  return (
    <Icon viewBox="0 0 64 64" width="64" height="64" {...otherProps}>
      <path
        fill={shadowColor}
        d="M54 34.2a15 15 0 0 0-2.5-4.3v-3.4c0-1-.2-2-.5-3-.2-.7-.2-.7-1-1.7 0 0-1.9-1.8-2.3-1.4l.4.6a14.5 14.5 0 0 1 1.3 5.1v3.5c.7.8 1.2 1.7 1.7 2.7.2.4-1.2 1.2-1 1.7 1.2.4 2.7 2.6 2.6 4.5 0 3.3-3 3.5-2.3 3.6-.7 3-.5 5.8-3.2 7.8a19.3 19.3 0 0 1-6.2 3c-2.6.6-5.2 1-7.9 1l-1.1-.1c2 .3 5.1.5 7.9 0 2.3-.6 5.8-1 9.4-3.5a13 13 0 0 0 5.2-7.6 4.9 4.9 0 0 0 1.9-4.1c0-2-1-3.8-2.5-4.4z"
      />
      <path
        fill={highlightColor}
        d="M31.8 43.7c-2.3-.7-4-2.7-4-5 0-2.8 2.7-5.1 5.9-5.1h.2c-.6-.2-1.2-.2-1.8-.2-3.2 0-5.8 2.3-5.8 5.1s2.4 5 5.5 5.2z"
      />
      <path
        fill={shadowColor}
        d="M32.6 43.7c2.2-.7 3.9-2.7 3.9-5 0-2.8-2.6-5.1-5.8-5.1h-.2c.5-.2 1.1-.2 1.7-.2 3.2 0 5.8 2.3 5.8 5.1s-2.4 5-5.4 5.2z"
      />
      <path
        fill={highlightColor}
        d="M51.3 42.1a4 4 0 0 1-2.5-3.8c0-2.2 1.7-4 3.8-4h.1l-1.1-.2c-2 0-3.8 1.8-3.8 4s1.6 4 3.6 4z"
      />
      <path
        fill={highlightColor}
        d="M21 40.6c-.4-2.4 6-22.1 5.8-22.5-.3-.4-1 .9-1.1 1.1 0 .1-1.8 2.8-3.2 5.4l-.4-.8C18 26.2 12.6 30 11.4 34.5v.3H11c-2.1 0-3.8 2-3.8 4.6C7.3 42 9 44 11.1 44h.5c1.2 3.3 4.2 5.2 7.8 7.2 1.9 1 8.2 3 14.1 2.7 2.3.5-11.1-13.5-12.5-13.3z"
      />
      <path
        fill={shadowColor}
        d="M27.3 13.4C36 10.1 44 9 45 10.8c1 1.8-6.7 6.2-15.3 9.6l-.4-1.2c6.9-2.6 13.3-5.8 12.6-7.3-.8-1.5-7.3-.9-14.4 1.8-7.1 2.7-9 3.1-.2-.3z"
      />
      <path
        fill={highlightColor}
        d="M24 14c17.3-4.4 8.2-2 .8.5-7.1 2.6-12.5 5.6-11.8 7 .6 1.2 5 1.5 10.1-.1l-1.1.5c-6.4 1.8-11.3 2.2-12 .8C9 21 12.4 18 24 14z"
      />
      <path
        fill={mainColor}
        d="M25.6 13.3c8.8-3.2 16.7-4.4 17.7-2.6 1 1.8-5.3 5.8-13.9 9l-.4-1c6.9-2.6 11.9-5.4 11.1-6.8-.7-1.5-6.8-.2-14 2.4-7 2.6-12.4 5.6-11.6 7 .6 1.2 4.6.6 9.8-1l-.9 1.4c-6.3 1.8-11.3 2.2-12 .8-1-1.8 5.4-6 14.2-9.2z"
      />
      <path
        fill={mainColor}
        d="M52.1 33.8a15 15 0 0 0-2.4-4.2V26a16.2 16.2 0 0 0-1.3-5.1c-.1-.4-.5-.9-.9-.5s-.6 1-.8 1.5l-1.5 2.3c1 .7 1.8 1.8 3 3.5-2.5-2.5-6-4.4-9.9-5.3a23.8 23.8 0 0 0-10-.3 9.5 9.5 0 0 0-1.5-4c-.3-.4-.5.4-.6.6l-2.8 5-2.2 4.3.5-3.5h-.2A16.8 16.8 0 0 0 13 35h-.2c-2.1 0-3.8 1.9-3.8 4.3s1.7 4.4 3.8 4.4h.5c1.2 3 3.8 5.6 7.4 7.5a32 32 0 0 0 20.6 1.7c2.4-.7 4.5-1.6 6.2-3a13 13 0 0 0 5.2-7.5 4.9 4.9 0 0 0 1.9-4.1c0-2-1-3.8-2.5-4.4zM32 43.3c-3.2 0-5.8-2.2-5.8-4.9s2.6-4.8 5.8-4.8c3.2 0 5.8 2.2 5.8 4.8s-2.6 4.9-5.8 4.9zm15.9 4.3a6 6 0 0 1-3.6 1.5c-3 .3-5.5-1.6-6-4.3.8 1.7 4 2.9 7.7 2.6l1.7-.3.3.2-.1.3zm3-5.7c-1.4 0-2.6-1.7-2.6-3.7s1.2-3.7 2.6-3.7c1.5 0 2.7 1.7 2.7 3.7s-1.2 3.7-2.7 3.7z"
      />
    </Icon>
  );
};
