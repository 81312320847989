import { Icon } from "@chakra-ui/react";
import React from "react";

export const SupportIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M11 36.9a7 7 0 1 0 14.1 0 7 7 0 0 0-14.1 0zm11.3 0a4.2 4.2 0 1 1-8.5 0 4.2 4.2 0 0 1 8.5 0zM10.3 58.6h15.5c1.5 0 2.6-1.4 2.3-2.8l-1.6-7.4a3.3 3.3 0 0 0-3.2-2.6H12.8c-1.5 0-2.9 1-3.2 2.6L8 55.8c-.3 1.4.8 2.8 2.3 2.8zm2-9.6c.1-.2.3-.3.5-.3h10.5c.2 0 .4.1.4.3l1.5 6.8H10.9l1.5-6.8zM54.5 7.1h-19c-3.8 0-6.8 3-6.8 6.7v22.8a.8.8 0 0 0 1.3.6l6.3-6.3c.2-.2.4-.2.6-.2h17.6c2.9 0 5.2-2.4 5.2-5.2V12.3c0-2.9-2.3-5.2-5.2-5.2zm2.9 18.4c0 1.6-1.3 2.8-2.9 2.8H37a3 3 0 0 0-2.2 1L31 32.9V14c0-2.5 2-4.5 4.4-4.5h19.1c1.6 0 2.9 1.3 2.9 2.9v13.2z"
    />
    <path
      fill={props.color}
      d="M43.7 22.7a2 2 0 0 0-1.4.5c-.3.3-.5.7-.5 1.2s.2.9.5 1.2c.4.3.8.5 1.4.5s1-.2 1.3-.5c.4-.3.5-.7.5-1.2s-.1-.9-.5-1.2a2 2 0 0 0-1.3-.5zM47 11.9a4 4 0 0 0-1.5-.7 7.8 7.8 0 0 0-3.7 0l-1.7.7V15c.6-.4 1-.8 1.7-1 .5-.2 1-.3 1.6-.3h.7l.5.4.3.5.1.5v.7l-.4.6-.5.6-.7.6-.6.6-.5.6a3 3 0 0 0-.2.8 3.7 3.7 0 0 0 0 2H45l-.2-.4v-1.1l.3-.6c0-.2.3-.3.4-.5l.7-.5.9-.9.7-.8a3.9 3.9 0 0 0 .5-2.1c0-.7 0-1.2-.3-1.7a3 3 0 0 0-1-1.1z"
    />
  </Icon>
);
