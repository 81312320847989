import { config } from "@onflow/fcl";

config()
  .put("env", process.env.REACT_APP_CHAIN_ENV || "testnet")
  .put(
    "accessNode.api",
    process.env.REACT_APP_ACCESS_NODE || "https://access-testnet.onflow.org"
  )
  .put(
    "discovery.wallet",
    process.env.REACT_APP_WALLET_DISCOVERY ||
      "https://fcl-discovery.onflow.org/testnet/authn"
  )
  .put("0xFungibleToken", process.env.REACT_APP_CONTRACT_FUNGIBLE_TOKEN || "")
  .put(
    "0xNonFungibleToken",
    process.env.REACT_APP_CONTRACT_NON_FUNGIBLE_TOKEN || ""
  )
  .put("0xNFTStorefront", process.env.REACT_APP_NFTSTOREFRONT || "")
  .put("0xFUSD", process.env.REACT_APP_CONTRACT_FUSD || "")
  .put("0xNecryptolis", process.env.REACT_APP_CONTRACT_NECRYPTOLIS || "")
  .put("0xFlovatar", process.env.REACT_APP_CONTRACT_FLOVATAR || "")
  .put("0xFlovatarMarketplace", process.env.REACT_APP_CONTRACT_FLOVATAR || "")
  .put("0xTopShot", process.env.REACT_APP_CONTRACT_NBATOPSHOT || "")
  .put(
    "0xMetadataViews",
    process.env.REACT_APP_CONTRACT_NON_FUNGIBLE_TOKEN || ""
  )
  .put("app.detail.title", process.env.REACT_APP_TITLE || "")
  .put("app.detail.icon", process.env.REACT_APP_ICON_URL || "");
