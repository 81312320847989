import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { Box, Center, HStack, VStack } from "@chakra-ui/layout";
import React, { FunctionComponent } from "react";
import { SelectIconButton } from "../../components/SelectIconButton";
import { GraveColor, GraveShape } from "../../global/interfaces";
import { CornerHolesGraveIcon } from "../../icons/CornerHolesGraveIcon";
import { HighBottomGraveIcon } from "../../icons/HighBottomGraveIcon";
import { TopOvalGraveIcon } from "../../icons/TopOvalGraveIcon";

interface GravestoneShapeTabProps {
  graveType: GraveShape | undefined;
  setGraveType: (type: GraveShape) => void;
  graveColor: GraveColor;
  setGraveColor: (color: GraveColor) => void;
}

const GravestoneShapeTab: FunctionComponent<GravestoneShapeTabProps> = ({
  graveType,
  setGraveType,
  graveColor,
  setGraveColor,
}: GravestoneShapeTabProps) => {
  return (
    <VStack>
      <FormControl id="shape">
        <FormHelperText textColor="primary.100" textAlign="center">
          Select shape
        </FormHelperText>
        <Center>
          <HStack my={2}>
            {SelectIconButton(
              <Box
                borderTopRadius="10px"
                width="24px"
                height="24px"
                bg="primary.100"
              />,
              () => setGraveType("normal"),
              graveType === "normal"
            )}
            {SelectIconButton(
              <TopOvalGraveIcon
                maxW="28px"
                maxH="28px"
                color="var(--chakra-colors-primary-100)"
              />,
              () => setGraveType("top_oval"),
              graveType === "top_oval"
            )}
            {SelectIconButton(
              <CornerHolesGraveIcon
                maxW="28px"
                maxH="28px"
                color="var(--chakra-colors-primary-100)"
              />,
              () => setGraveType("corner_holes"),
              graveType === "corner_holes"
            )}
            {SelectIconButton(
              <HighBottomGraveIcon
                maxW="28px"
                maxH="28px"
                color="var(--chakra-colors-primary-100)"
              />,
              () => setGraveType("high_bottom"),
              graveType === "high_bottom"
            )}
          </HStack>
        </Center>
      </FormControl>
      <FormControl id="shape">
        <FormHelperText textColor="primary.100" textAlign="center">
          Select color
        </FormHelperText>
        <VStack>
          <HStack my={0}>
            {SelectIconButton(
              <Box
                borderRadius="4px"
                width="24px"
                height="24px"
                bg="tealBright"
              />,
              () => setGraveColor("tealBright"),
              graveColor === "tealBright"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="stoneBright" />,
              () => setGraveColor("stoneBright"),
              graveColor === "stoneBright"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="lilacBright" />,
              () => setGraveColor("lilacBright"),
              graveColor === "lilacBright"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="salmonBright" />,
              () => setGraveColor("salmonBright"),
              graveColor === "salmonBright"
            )}

          </HStack>
          <HStack my={2}>
            {SelectIconButton(
              <Box
                borderRadius="4px"
                width="24px"
                height="24px"
                bg="primary.400"
              />,
              () => setGraveColor("teal"),
              graveColor === "teal"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="stone" />,
              () => setGraveColor("stone"),
              graveColor === "stone"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="lilac" />,
              () => setGraveColor("lilac"),
              graveColor === "lilac"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="salmon" />,
              () => setGraveColor("salmon"),
              graveColor === "salmon"
            )}

          </HStack>
          <HStack my={2}>
            {SelectIconButton(
              <Box
                borderRadius="4px"
                width="24px"
                height="24px"
                bg="tealDark"
              />,
              () => setGraveColor("tealDark"),
              graveColor === "tealDark"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="stoneDark" />,
              () => setGraveColor("stoneDark"),
              graveColor === "stoneDark"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="lilacDark" />,
              () => setGraveColor("lilacDark"),
              graveColor === "lilacDark"
            )}
            {SelectIconButton(
              <Box borderRadius="4px" width="24px" height="24px" bg="salmonDark" />,
              () => setGraveColor("salmonDark"),
              graveColor === "salmonDark"
            )}

          </HStack>
        </VStack>
      </FormControl>
    </VStack>
  );
};

export default GravestoneShapeTab;
