import React, { FunctionComponent } from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  CandleBoughtInfo,
  GraveColor,
  GraveDateType,
  GraveFont,
  GraveShape,
} from "../../global/interfaces";
import { TopOvalGrave } from "../../svgs/TopOvalGrave";
import { CornerHolesGrave } from "../../svgs/CornerHolesGrave";
import { getColorConfig, getFontFamily } from "../../global/utils";
import { NormalGrave } from "../../svgs/NormalGrave";
import GraveDateFromTo from "./GraveDateFromTo";
import CandlesCluster from "./CandlesCluster";
import P5Canvas from "../../components/P5/P5Canvas";
import { GraveBottomSvg } from "../../svgs/GraveBottom";
import { FlovatarEmbossIcon } from "../../icons/FlovatarEmbossIcon";
import { HighBottomGrave } from "../../svgs/HighBottomGrave";
import { NBATopShotEmbossIcon } from "../../icons/NBATopShotEmbossIcon";

interface GraveProps {
  title: string;
  fromDate: string;
  toDate: string;
  shape?: GraveShape;
  color?: GraveColor;
  font?: GraveFont;
  dateType?: GraveDateType;
  candles?: CandleBoughtInfo[];
  graveId?: number;
  showLiana?: boolean;
  lastTrimmed?: Date;
  nftBuriedType?: string;
}

export const Grave: FunctionComponent<GraveProps> = ({
  title,
  fromDate,
  toDate,
  shape,
  color,
  font,
  dateType,
  candles,
  graveId,
  showLiana,
  lastTrimmed,
  nftBuriedType,
}: GraveProps) => {
  const fontFamily = getFontFamily(font || "roman");
  const fontSize = font === "zapf" ? "24px" : "16px";
  const dateFontSize = font === "zapf" ? "20px" : "12px";
  const fontLineHeight = font === "zapf" ? 1 : "18px";

  const colorConfig = getColorConfig(color)

  return (
    <Box
      textShadow={`-1px 0px 0px ${colorConfig.highlightColor}88, 1px 0px 0px ${colorConfig.shadowColor}88`}
      color={colorConfig.shadowColor}
    >
      {nftBuriedType && (
        <GraveBottomSvg
          position="absolute"
          top="151px"
          left="-5px"
          frontColor={colorConfig.mainColor}
          backColor={colorConfig.embossMainColor}
        />
      )}
      <Box
        className="grave"
        maxWidth="150px"
        width="150px"
        height="160px"
        position="relative"
      >
        {shape === "top_oval" && (
          <TopOvalGrave frontColor={colorConfig.mainColor} backColor={colorConfig.shadowColor} />
        )}
        {shape === "corner_holes" && (
          <CornerHolesGrave frontColor={colorConfig.mainColor} backColor={colorConfig.shadowColor} />
        )}
        {shape === "high_bottom" && (
          <HighBottomGrave frontColor={colorConfig.mainColor} backColor={colorConfig.shadowColor} />
        )}
        {shape !== "top_oval" && shape !== "corner_holes" && shape !== "high_bottom" && (
          <NormalGrave frontColor={colorConfig.mainColor} backColor={colorConfig.shadowColor} />
        )}
        <Text
          position="absolute"
          px={4}
          pl={8}
          pt={9}
          fontWeight="bold"
          maxH={dateType === "empty" ? "145px" : "120px"}
          maxWidth="140px"
          width="140px"
          overflow={"hidden"}
          textAlign="center"
          fontSize={fontSize}
          lineHeight={fontLineHeight}
          fontFamily={fontFamily}
          whiteSpace={"pre-line"}
        >
          {title}
        </Text>
        <GraveDateFromTo
          position="absolute"
          fontSize={dateFontSize}
          fontWeight="bold"
          pt="124px"
          textAlign="center"
          lineHeight={1}
          fontFamily={fontFamily}
          dateType={dateType}
          fromDate={fromDate}
          toDate={toDate}
        />
        <CandlesCluster graveId={graveId} candles={candles} />
        {nftBuriedType && nftBuriedType.includes("Flovatar") && (
          <FlovatarEmbossIcon
            width="36px"
            height="36px"
            position="absolute"
            left="62px"
            top="4px"
            mainColor={colorConfig.embossMainColor}
            shadowColor={colorConfig.shadowColor}
            highlightColor={colorConfig.highlightColor}
          />
        )}
        {nftBuriedType && nftBuriedType.includes("TopShot") && (
          <NBATopShotEmbossIcon
            width="36px"
            height="36px"
            position="absolute"
            left="62px"
            top="4px"
            mainColor={colorConfig.embossMainColor}
            shadowColor={colorConfig.shadowColor}
            highlightColor={colorConfig.highlightColor}
            normalColor={colorConfig.mainColor}
          />
        )}
      </Box>
      {showLiana && (
        <P5Canvas
          lastTrimmed={lastTrimmed || new Date()}
          graveId={graveId || 0}
          divId={`grave-${graveId}`}
          width={160}
          height={160}
          graveColor={color || "teal"}
        />
      )}
    </Box>
  );
};
