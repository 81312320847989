import {
  HStack,
  Icon,
  Text,
  VStack,
  Image,
  Progress,
  Flex,
  Checkbox,
  Spinner,
} from "@chakra-ui/react";
import React, { Suspense, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { BuryIcon } from "../../icons/BuryIcon";
import NBATopShotImage from "../../assets/NBATopShot.png";
import { SelectIconButton } from "../../components/SelectIconButton";
import { NFTMetadata, NFTPlatform } from "../../global/interfaces";
import { fetchPlatformNfts } from "../../flow/fetch-platform-nfts";
import { AttentionIcon } from "../../icons/AttentionIcon";
import { buryNFT } from "../../flow/bury-nft";
import ExternalNFTBox from "./ExternalNFTBox";
import { useCurrentUser } from "../../hooks/use-current-user.hook";
import { FlovatarIcon } from "../../icons/FlovatarIcon";

interface BuryNFTModalProps {
  graveId: number;
  graveOwnerAddress: string;
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
}

const BuryNFTModal: FunctionComponent<BuryNFTModalProps> = ({
  graveId,
  graveOwnerAddress,
  isOpen,
  onClose,
  onBack,
}: BuryNFTModalProps) => {
  const [selectedPlatform, setSelectedPlatform] = useState<NFTPlatform | "">(
    ""
  );
  const { user } = useCurrentUser();
  const [selectedNftId, setSelectedNftId] = useState<number | undefined>();
  const [nftCollection, setNftCollection] = useState<{
    [key: string]: [NFTMetadata];
  }>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  useEffect(() => {
    if (selectedPlatform && !nftCollection) {
      setIsLoading(true);
      fetchPlatformNfts(selectedPlatform, user.addr || "")
        .then((response) => {
          if (typeof response == "string") {
            setError(response);
          } else {
            setNftCollection(response);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedPlatform, graveOwnerAddress, nftCollection, user]);

  useEffect(() => {
    setSelectedNftId(undefined);
  }, [selectedPlatform]);

  return (
    <ThreeStripedModal
      isOpen={isOpen}
      onClose={onClose}
      goBackCallback={onBack}
      title={"Bury an NFT"}
      modalIcon={
        <BuryIcon
          maxH="64px"
          maxW="64px"
          p={1}
          color={"var(--chakra-colors-primary-900)"}
        />
      }
      topContent={
        <VStack px={4} pb={4} spacing={2} height={"100%"}>
          <Text fontSize={"sm"}>Select NFT platform</Text>
          <HStack height={"100%"}>
            {SelectIconButton(
              <Image src={NBATopShotImage} width="64px" height="64px" />,
              () => setSelectedPlatform("NBA Top Shot"),
              selectedPlatform === "NBA Top Shot",
              80
            )}
            {SelectIconButton(
              <FlovatarIcon
                width="64px"
                height="64px"
                color={"var(--chakra-colors-primary-100)"}
              />,
              () => setSelectedPlatform("Flovatar"),
              selectedPlatform === "Flovatar",
              80
            )}
            {SelectIconButton(
              <Text>TBA</Text>,
              () => setSelectedPlatform("Versus"),
              selectedPlatform === "Versus",
              80, true
            )}

          </HStack>
          <Text fontSize={"md"} fontWeight={600}>
            {selectedPlatform}
          </Text>
          {!selectedPlatform && (
            <HStack>
              <AttentionIcon
                height="22px"
                width="22px"
                color={"var(--chakra-colors-primary-100)"}
              />
              <Text fontSize={"md"}>Please select</Text>
            </HStack>
          )}
        </VStack>
      }
      midContent={
        error ? (
          <Text p={4} m={4}>
            Temporary issues with getting NFTs. Please try later.{" "}
          </Text>
        ) : (
          <VStack mx={4} spacing={8} width="90%" minH="140px">
            <VStack spacing={2} minH="140px" width="90%">
              <Text pt={4} fontSize={"sm"}>
                Select NFT
              </Text>
              {isLoading && <Spinner p={4} m={4} />}
              {nftCollection && nftCollection[selectedPlatform] ? (
                <VStack
                  width="95%"
                  maxH={"160px"}
                  overflowY="auto"
                  overflowX={"hidden"}
                  p={1}
                >
                  {Object.values(nftCollection[selectedPlatform]).map(
                    (nftData: NFTMetadata) => {
                      return (
                        <ExternalNFTBox
                          key={nftData.id}
                          nftData={nftData}
                          isSelected={selectedNftId === nftData.id}
                          setIsSelected={setSelectedNftId}
                          platform={selectedPlatform}
                        />
                      );
                    }
                  )}
                </VStack>
              ) : !isLoading && nftCollection ? (
                <Text fontSize={"sm"}>
                  {`No ${selectedPlatform} NFTs found in your account.`}
                </Text>
              ) : !isLoading && !nftCollection ? (
                <Text fontSize={"sm"}>
                  Please select an NFT platform to see your NFTs
                </Text>
              ) : (
                <></>
              )}
            </VStack>

            <VStack width="100%">
              <Progress
                width="200px"
                bgColor="primary.800"
                borderRadius={"8px"}
                colorScheme="primary"
                value={selectedNftId ? 100 : selectedPlatform ? 50 : 2}
              />
              <Flex>
                <Icon mx={2} />
                <Text textAlign="center" fontSize="sm">
                  {!selectedPlatform
                    ? "Please select a platform from which you want to bury an NFT."
                    : !selectedNftId
                      ? "Please select an NFT of this type."
                      : `You've succesfully selected an NFT for burial.`}
                </Text>
              </Flex>
            </VStack>
          </VStack>
        )
      }
      bottomContent={
        <VStack p={4} px={8} spacing={4}>
          <HStack m={2}>
            <Checkbox
              spacing={"6px"}
              colorScheme="blackAlpha"
              borderColor="primary.900"
              textAlign="center"
              iconSize="3rem"
              iconColor="primary.100"
              isChecked={isCheckboxSelected}
              onChange={(e) => setIsCheckboxSelected(e.target.checked)}
            >
              I understand that once the NFT has been buried, there's no going
              back.
            </Checkbox>
          </HStack>

          <TriggerTransactionButton
            transactionRequestObject={{
              plotId: graveId,
              externalNftId: selectedNftId,
              externalNftPlatform: selectedPlatform,
            }}
            doTransaction={buryNFT}
            buttonText={"Bury this NFT"}
            onSuccessCallback={onClose}
            onSuccessMessage={`Your ${selectedPlatform} NFT is being buried now. This will be processed in a minute or so.`}
            disabled={!selectedNftId || !isCheckboxSelected}
          />
        </VStack>
      }
    />
  );
};

const WrappedBuryNFTModal = (props: BuryNFTModalProps) => {
  return (
    <Suspense fallback={<></>}>
      <BuryNFTModal {...props} />
    </Suspense>
  );
};

export default WrappedBuryNFTModal;
