import { Box } from "@chakra-ui/layout";
import React, { FunctionComponent, MutableRefObject, useState } from "react";
import { Grave } from "../grave/Grave";
import { CemeteryPlotData, Coordinates } from "../../global/interfaces";
import ForSaleSignPost from "./ForSaleSignPost";
import { dateDiffInDays, getPixelValue } from "../../global/utils";
import AdminCemeteryPlot from "./AdminCemeteryPlot";

interface Props {
  cemeteryPlot: CemeteryPlotData;
  topLeftCoordinatesRef: MutableRefObject<Coordinates>;
  isHighlightedBorder?: boolean;
  onCemeteryPlotInfoOpen: () => void;
  setCemeteryPlot: (plot: CemeteryPlotData) => void;
}

export const CemeteryPlot: FunctionComponent<Props> = ({
  cemeteryPlot,
  topLeftCoordinatesRef,
  isHighlightedBorder,
  onCemeteryPlotInfoOpen,
  setCemeteryPlot,
}: Props) => {
  const [currentCoordinates, setCurrentCoordinates] = useState<Coordinates>();

  const { left, top, width, height } = cemeteryPlot.plot;

  const onTouchStart = (_: any) => {
    setCurrentCoordinates(topLeftCoordinatesRef.current);
  };
  const onTouchEnd = (_: any) => {
    const xDiff =
      (currentCoordinates?.x ?? 0) - topLeftCoordinatesRef.current.x;
    const yDiff =
      (currentCoordinates?.y ?? 0) - topLeftCoordinatesRef.current.y;
    if (Math.abs(xDiff) < 7 || Math.abs(yDiff) < 7) {
      setCemeteryPlot(cemeteryPlot);
      onCemeteryPlotInfoOpen();
    }
  };

  if (isAdminsPlot(cemeteryPlot.owner) && cemeteryPlot.grave?.name) {
    return (
      <AdminCemeteryPlot
        plot={cemeteryPlot}
        isHighlightedBorder={isHighlightedBorder}
        setCemeteryPlot={setCemeteryPlot}
        onCemeteryPlotInfoOpen={onCemeteryPlotInfoOpen}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
      />
    );
  }

  return (
    <>
      <Box
        bg={"primary.700"}
        p={"5px"}
        position="absolute"
        transform={`translate(${left}px, ${top - 300}px)`}
      >
        <Box
          id={`cemetery-plot-${cemeteryPlot.id}`}
          className="fade-in"
          _hover={{
            bg: "primary.800",
            cursor: "pointer",
          }}
          width={`${width - 6}px`}
          height={`${height - 6}px`}
          bg={"primary.900"}
          zIndex={-100}
          border={isHighlightedBorder ? "2px" : ""}
          borderColor={isHighlightedBorder ? "primary.300" : "primary.600"}
          onClick={() => {
            setCemeteryPlot(cemeteryPlot);
            onCemeteryPlotInfoOpen();
          }}
          onTouchEnd={onTouchEnd}
          onTouchStart={onTouchStart}
        >
          {cemeteryPlot?.grave?.name && (
            <Box
              position="relative"
              left={cemeteryPlot?.grave?.xOffset}
              top={cemeteryPlot?.grave?.yOffset}
            >
              <Grave
                shape={cemeteryPlot?.grave?.shape}
                color={cemeteryPlot?.grave?.color}
                title={cemeteryPlot?.grave?.name}
                fromDate={cemeteryPlot?.grave?.fromDate}
                toDate={cemeteryPlot?.grave?.toDate}
                dateType={cemeteryPlot?.grave?.dateType}
                font={cemeteryPlot?.grave?.font}
                showLiana={
                  dateDiffInDays(cemeteryPlot.lastTrimDate, new Date()) > 5
                }
                candles={cemeteryPlot.candles}
                graveId={cemeteryPlot.id}
                lastTrimmed={cemeteryPlot.lastTrimDate}
                nftBuriedType={cemeteryPlot.buriedNft?.nftType}
              />
            </Box>
          )}

          {cemeteryPlot.listing && (
            <Box
              position="absolute"
              top={
                cemeteryPlot.grave?.name
                  ? `${getPixelValue(cemeteryPlot.grave?.yOffset) + 26}px`
                  : `${cemeteryPlot.plot.height / 2 - 100}px`
              }
              left={
                cemeteryPlot.grave?.name
                  ? `${getPixelValue(cemeteryPlot.grave?.xOffset) - 40}px`
                  : `${cemeteryPlot.plot.width / 2 - 60}px`
              }
            >
              <ForSaleSignPost
                id={cemeteryPlot.id}
                price={cemeteryPlot.listing.price}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
function isAdminsPlot(owner: string) {
  if (["0x1750fdf482e6eb4a", "0x79218e9eb3de75f3", "0x8322a77224d8be6d"].includes(owner)) {
    return true;
  }

  return false;
}
