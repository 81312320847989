import { Box } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { GetGradientId } from "../icons/GradientDefsFakeGrave";

interface TopOvalGraveProps {
  frontColor: string;
  backColor: string;
}

export const TopOvalGrave: FunctionComponent<TopOvalGraveProps> = ({
  frontColor,
  backColor,
}: TopOvalGraveProps) => (
  <Box maxWidth="150px" position="absolute">
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="160">
      <g fill={`url(#${GetGradientId(frontColor)})`}>
        <path d="M29 153c-3 0-5-2-6-5L3 40c-1-2 0-3 1-4 1-2 3-2 4-2h21C31 15 49 1 70 1c20 0 38 14 40 33h21c1 0 3 0 4 2 1 1 2 2 1 4l-20 108c-1 3-3 5-6 5H29z" />
        <path d="M8 147h123v11H8v-11z" />
      </g>
      <path
        fill={frontColor}
        d="M38 153c-3 0-5-2-6-5L12 40c-1-2 0-3 1-4 1-2 3-2 4-2h21C40 15 58 1 79 1s38 14 40 33h21c1 0 3 0 4 2 1 1 2 2 1 4l-20 108c-1 3-3 5-6 5H38z"
      />
      <path fill={frontColor} d="M17 147h123v11H17v-11z" />
      <path
        fill="none"
        stroke={backColor}
        strokeWidth=".5"
        strokeMiterlimit="10"
        d="M8 158h132"
      />
    </svg>
  </Box>
);

export function GradientDefs() {
  return (
    <defs>
      <linearGradient id="primaryGradient">
        <stop offset="5%" stopColor="#123635" />
        <stop offset="95%" stopColor="#178781" />
      </linearGradient>
      <linearGradient id="lilacGradient">
        <stop offset="5%" stopColor="#123635" />
        <stop offset="95%" stopColor="#178781" />
      </linearGradient>
      <linearGradient id="salmonGradient">
        <stop offset="5%" stopColor="#f23635" />
        <stop offset="95%" stopColor="#178781" />
      </linearGradient>
    </defs>
  );
}
