import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { tx, TxOptions } from "./utils/tx";
import { CreateGraveRequestBody } from "../global/interfaces";

const CODE = fcl.cdc`
    import Necryptolis from 0xNecryptolis
    
    transaction(plotId: UInt64, name: String, fromDate: String, toDate: String, metadata: {String:String} ) {    
        let necryptolisProvider: Capability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>
        let graveStoneCreator: &Necryptolis.GravestoneManager{Necryptolis.GravestoneCreator}
    
        prepare(account: AuthAccount) {
            // We need a provider capability, but one is not provided by default so we create one if needed.
            let necryptolisProviderPrivatePath = /private/necryptolisCollectionProvider
    
            account.unlink(necryptolisProviderPrivatePath)
            if !account.getCapability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath)!.check() {
                account.link<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath, target: Necryptolis.CollectionStoragePath)
            }
    
            self.necryptolisProvider = account.getCapability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath)!
            
            assert(self.necryptolisProvider.borrow() != nil, message: "Missing or mis-typed Necryptolis Collection provider")

            if account.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath) == nil {
                account.save(<-Necryptolis.createGravestoneManager(), to: Necryptolis.GravestoneManagerStoragePath)
              }
    
            self.graveStoneCreator = account.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath)
                ?? panic("Missing or mis-typed GravestoneManager")
        }
    
        execute {
            self.graveStoneCreator.createGravestone(
                necryptolisProviderCapability: self.necryptolisProvider, 
                nftID: plotId, 
                graveData: Necryptolis.GraveData(name: name, fromDate: fromDate, toDate: toDate, metadata: metadata)
            )
        }
    }
`;

export function createGravestone(
  createGraveRequestBody: CreateGraveRequestBody,
  opts: TxOptions = {}
) {
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(createGraveRequestBody.plotId, t.UInt64),
        fcl.arg(createGraveRequestBody.title, t.String),
        fcl.arg(createGraveRequestBody.fromDate, t.String),
        fcl.arg(createGraveRequestBody.toDate, t.String),
        fcl.arg(
          Object.keys(createGraveRequestBody.metadata).map((k: string) => {
            return { key: k, value: createGraveRequestBody.metadata[k] };
          }),
          t.Dictionary(
            Object.keys(createGraveRequestBody.metadata).map((k: string) => {
              return { key: t.String, value: t.String };
            })
          )
        ),
      ]),
      fcl.proposer(fcl.authz),
      fcl.payer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
    ],
    opts
  );
}
