import { StatusResponse } from "../global/interfaces";

export const getStatus = async (): Promise<StatusResponse> => {
  let response = null;

  try {
    response = await fetch(`${process.env.REACT_APP_API}/api/v1/status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const retval = (await response.json()) as StatusResponse;

    return retval;
  } catch (error) {
    console.log("error getting status");
    return {
      status: "bad",
      message: "API not working.",
      timestamp: Date.now(),
    };
  }
};
