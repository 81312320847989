import { atomFamily, selectorFamily, useRecoilState } from "recoil";
import { TransactionState } from "../global/constants";
import { fetchBalance } from "../flow/fetch-balance";
import { useCurrentUser } from "./use-current-user.hook";

export const valueAtom = atomFamily({
  key: "balance::state",
  default: selectorFamily({
    key: "balance::default",
    get: (address: string | undefined) => async () => fetchBalance(address),
  }),
});

export const statusAtom = atomFamily({
  key: "flow-balance::status",
  default: TransactionState.Idle,
});

export function useBalance() {
  const { user, fclMethods, isLoggedIn } = useCurrentUser();
  const [balance, setBalance] = useRecoilState(valueAtom(user.addr));
  const [status, setStatus] = useRecoilState(statusAtom(user.addr));

  return {
    balance,
    status,
    async refresh() {
      setStatus(TransactionState.Processing);
      await fetchBalance(user.addr).then(setBalance);
      setStatus(TransactionState.Idle);
    },
    logIn: fclMethods.logIn,
    isLoggedIn,
  };
}
