import { Icon } from "@chakra-ui/react";
import React from "react";

export const NBATopShotEmbossIcon = (props: any) => {
    const { mainColor, shadowColor, highlightColor, normalColor, ...otherProps } = props;
    
    return (
        <Icon viewBox="0 0 64 64" width="64" height="64" {...otherProps}>            
            <g fill={mainColor}>
                <ellipse cx="33.72" cy="31.58" rx="25.54" ry="25.49" stroke={highlightColor} strokeWidth="2.5" />
                <path d="M33.3 6.44s11.05-.23 17.88 8.86c6.84 9.09 6.05 12.03 6.1 19.84.04 7.81-11.95 18.06-11.95 18.06s-8 4.45-16.1 3.43" stroke={shadowColor} strokeWidth="3.5" />
                <path d="m24.96 8.44 23.9 42.87" stroke={highlightColor} strokeWidth="2.4" />
                <path d="M38.7 6.42s-3.06 9.3-9.25 11.54" stroke={highlightColor}  strokeWidth="1.04" />
                <path d="M11.1 43.83s12.18-26.27 44.25-23.66" stroke={highlightColor}  strokeWidth="1.96" />
                <path d="M32.13 56.34s-5.75-15.07 1.4-22.37" stroke={highlightColor}  strokeWidth="1.4" />
            </g>
            <path d="M58.8 31.47a26.34 26.34 0 0 1-26.34 26.34A26.34 26.34 0 0 1 6.13 31.47 26.34 26.34 0 0 1 32.46 5.14 26.34 26.34 0 0 1 58.8 31.46zm-3.14-.14a35.53 35.53 0 0 0-10.44-3.28c-3.8-.5-7.3-.15-10.48 1.07l-3.73-6.46c7.02-3 14.96-4.2 23.75-3.53a25.16 25.16 0 0 0-1.6-2.46 54.91 54.91 0 0 0-16.88 1.7c-2.22.6-4.37 1.35-6.44 2.25l-2.17-3.76c5.25-2.89 7.42-6.62 8.31-9.3.1-.32.2-.62.27-.9-.8-.12-1.6-.19-2.4-.22-.77 2.55-2.7 5.82-7.36 8.37L22.85 8.5c-.73.32-1.44.67-2.13 1.05l3.63 6.3a18.66 18.66 0 0 1-11.82.9c-.49.67-.95 1.37-1.37 2.08a22.32 22.32 0 0 0 5.93.84c2.5 0 5.4-.45 8.45-1.75l2.15 3.72a45.3 45.3 0 0 0-9.07 5.97A47.45 47.45 0 0 0 8.8 39.05c.3.98.66 1.94 1.08 2.87a45.1 45.1 0 0 1 10.36-12.59 43.4 43.4 0 0 1 8.63-5.66l3.73 6.46c-5.46 3.12-7.8 9.54-6.8 18.67.3 2.67.83 5.22 1.4 7.37.84.2 1.7.35 2.58.46-1.98-7.05-4.16-19.77 4-24.45l12.1 21.02c.68-.4 1.34-.85 1.98-1.32L35.93 31.2c6.54-2.34 13.7-.13 18.67 2.24 1.14.54 2.22 1.12 3.22 1.7.12-.86.2-1.72.23-2.6a49.7 49.7 0 0 0-2.39-1.2" fill="none" stroke={normalColor} strokeWidth="3.54" />
        </Icon>
    );
};
