import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { Rect } from "../global/interfaces";

const CODE = fcl.cdc`
    import Necryptolis from 0xNecryptolis

    pub fun main(left: Int32, top: Int32, width: UInt16, height: UInt16): UFix64 {    
        return Necryptolis.getPlotPrice(width: width, height: height, left: left, top: top, )
    }
`;

export async function fetchPlotPrice(rect: Rect): Promise<string> {
  try {
    const price = (await fcl
      .send([
        fcl.script(CODE),
        fcl.args([
          fcl.arg(parseInt(rect.left.toFixed(0)), t.Int32),
          fcl.arg(parseInt(rect.top.toFixed(0)), t.Int32),
          fcl.arg(parseInt(rect.width.toFixed(0)), t.UInt16),
          fcl.arg(parseInt(rect.height.toFixed(0)), t.UInt16),
        ]),
      ])
      .then(fcl.decode)) as any;

    return price;
  } catch (error) {
    console.log("error fetching price", error);
    return "Blockchain ERROR";
  }
}
