import { Icon } from "@chakra-ui/react";
import React from "react";

export const ZoomOutIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M53.1 11.9c-7.9-8-20.8-8-28.7 0-7.3 7.2-9.4 18.7-3.3 26.6L8.4 50.4a3.8 3.8 0 0 0-.1 5.5 3.8 3.8 0 0 0 5.6 0l12.5-12.2c8 6.1 19.4 4.3 26.7-3 8-8 8-21 0-28.8zm-3.7 24.9a14.8 14.8 0 0 1-21 0 14.6 14.6 0 1 1 20.9-20.6c5.6 5.8 5.5 14.2 0 20.6z"
    />
    <path
      fill={props.color}
      d="M45 23.6H32.6c-1.6 0-2.8 1.4-2.8 3.2 0 2 1.2 3.3 2.8 3.3H45c1.5 0 2.7-1.4 2.7-3.3 0-1.8-1.2-3.2-2.7-3.2z"
    />
  </Icon>
);
