import { Box } from "@chakra-ui/layout";
import React from "react";
import { FunctionComponent } from "react";

interface CandleProps {
  mlInPixels: number;
  mtInPixels: number;
  randomSeed: number;
  dateBought: Date;
}

const Candle: FunctionComponent<CandleProps> = ({
  mlInPixels,
  mtInPixels,
  randomSeed,
  dateBought,
}) => {
  const flameTwirlLength = (randomSeed % 5) + 5;
  const glowLength = (randomSeed % 3) + 2;
  const hoursPassed = Math.round(
    (new Date().getTime() - new Date(dateBought).getTime()) / (1000 * 60 * 60)
  );
  const candleLengthInPixels = 30 - hoursPassed < 0 ? 0 : 30 - hoursPassed;

  return (
    <Box
      ml={`${mlInPixels}px`}
      mt={`${mtInPixels + (30 - candleLengthInPixels)}px`}
      position="absolute"
    >
      {/* the flame */}
      {candleLengthInPixels > 0 && (
        <Box
          ml={"5px"}
          borderRadius="8px 8px 8px 8px / 20px 20px 8px 8px"
          bg="#FF8811"
          height="15px"
          width="6px"
          animation={`flame-twirl ${flameTwirlLength}s ease infinite, glow ${glowLength}s ease infinite`}
        ></Box>
      )}
      {candleLengthInPixels > 0 && (
        <Box
          position="absolute"
          ml={"6px"}
          borderRadius="8px 8px 8px 8px / 20px 20px 8px 8px"
          bg="flame"
          height="8px"
          width="4px"
          top="7px"
          animation={`flame-twirl ${
            flameTwirlLength + 2
          }s ease infinite, glow ${glowLength}s ease infinite`}
        ></Box>
      )}
      {/* the stick */}
      <Box
        height={`${candleLengthInPixels + 2}px`}
        width="10px"
        borderTopRadius="4px"
        ml={"3px"}
        // Make this a gradient so it seems more oval
        bg="linear-gradient(90deg, rgba(41,66,87,1) 7%, rgba(58,112,118,1) 35%);"
        zIndex="1000"
      ></Box>
      {/* the bottom */}
      <Box width="16px" height="4px" bg="primary.700"></Box>
    </Box>
  );
};

export default Candle;
