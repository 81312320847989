import { Icon } from "@chakra-ui/react";
import React from "react";

export const GraveBasicIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M17.6 50.2a.7.7 0 0 1-.6-.5l-5.7-31.6c-.3-1.8.4-3.5 1.9-4.5l10-6.9h17.3l10.2 6.9c1.4 1 2.1 2.7 1.8 4.5L47 49.3c0 .3-.4.6-.7.5a.7.7 0 0 1-.6-.8l5.6-31.2a3 3 0 0 0-1.3-3L40.1 8H23.7L14 14.7a3 3 0 0 0-1.3 3.1l5.7 31.7c0 .3-.2.7-.6.7h-.2z"
    />
    <path
      fill={props.color}
      d="M2.6 55.4a.7.7 0 0 1-.6-.3c-.2-.4 0-.8.3-1a70 70 0 0 1 59.4-.8c.3.1.5.5.3.9-.1.3-.5.4-.9.3a68.8 68.8 0 0 0-58.2.9h-.3z"
    />
    <path
      fill={props.color}
      d="M13 58.7c-.2 0-.5-.2-.6-.5-.1-.3 0-.7.4-.8a66.4 66.4 0 0 1 38-.7c.4.2.6.5.5.9 0 .3-.5.6-.8.5a64.3 64.3 0 0 0-37.3.6h-.1z"
    />
  </Icon>
);
