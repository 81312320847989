import { Icon } from "@chakra-ui/react";
import React from "react";

export const ExternalLinkIcon = (props: any) => (
  <Icon viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path
      fill={props.color}
      d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z"
    />
  </Icon>
);
