import { Icon } from "@chakra-ui/react";
import React from "react";

export const SizeIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      stroke={props.color}
      strokeMiterlimit="10"
      d="M31.7 4.8c-.1 0-.3 0-.4.2l-5.5 4.5c-.2.1-.3.3-.3.5s.1.4.3.5l5.5 4.5a.7.7 0 0 0 1 0c.2-.2.2-.3.2-.5s-.1-.4-.3-.5l-4.1-3.4h24.1L48 14l-.2.5.1.5a.7.7 0 0 0 1 0l5.6-4.5c.2-.1.2-.3.2-.5s0-.4-.2-.5L49 5a.7.7 0 0 0-.5-.2c-.2 0-.5.2-.6.5 0 .2 0 .5.2.7l4.2 3.4H28.1L32.2 6c.3-.2.4-.5.3-.8a.7.7 0 0 0-.8-.4zm-4 14.2c-1 0-1.6.5-2.1 1-.5.6-.8 1.4-.8 2.3v4.5l.2.5.5.1.5-.1c.2-.2.2-.3.2-.5v-4.5c0-.6.2-1.1.5-1.5.3-.3.6-.5 1-.5H54c.2 0 .6.2.9.5.2.4.4.9.4 1.5v33.6c0 .5-.1 1-.4 1.4-.3.3-.7.5-1 .5H33.3c-.2 0-.4 0-.5.2-.2.1-.2.3-.2.4 0 .2 0 .4.2.5l.5.2H54c.8 0 1.5-.5 2-1 .5-.6.7-1.4.7-2.2V22.3c0-.9-.2-1.7-.7-2.2-.5-.6-1.2-1-2-1H27.6zm-16.9.7c-.2 0-.3 0-.4.2l-4.9 5.2-.2.5.2.4.6.2c.2 0 .4-.1.5-.3l3.6-3.8v32.7l-3.6-3.9a.7.7 0 0 0-.6-.2c-.3 0-.5.1-.6.4-.1.2 0 .5.1.7l4.9 5.1c.1.2.3.2.5.2s.4 0 .5-.2l5-5.1.1-.5c0-.2 0-.4-.2-.5a.7.7 0 0 0-.5-.1c-.2 0-.4 0-.5.2l-3.7 3.9V22l3.7 3.8c0 .2.3.3.5.3.1 0 .3 0 .5-.2l.2-.4c0-.2 0-.4-.2-.5l-4.9-5.2a.7.7 0 0 0-.6-.2zM25.4 30c-.3 0-.6.3-.6.7V32l.2.4.5.2c.2 0 .4 0 .5-.2.2-.1.2-.3.2-.4v-1.3c0-.2 0-.4-.2-.5a.7.7 0 0 0-.6-.2zm0 5.2c-.3 0-.6.3-.6.6v20c0 .9.3 1.7.8 2.3.5.5 1.2 1 2 1H29c.2 0 .4 0 .5-.2l.2-.5c0-.1 0-.3-.2-.4a.7.7 0 0 0-.5-.2h-1.4c-.3 0-.6-.2-.9-.5-.3-.4-.5-.9-.5-1.4v-20c0-.3 0-.4-.2-.6a.7.7 0 0 0-.6-.1z"
    />
  </Icon>
);
