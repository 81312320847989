import { send, decode, script, args, arg, cdc } from "@onflow/fcl";
import { Address } from "@onflow/types";
import * as fcl from "@onflow/fcl";
import { Balance } from "../global/interfaces";

const GET_PLOT_IDS_CODE = cdc`
    import Necryptolis from 0xNecryptolis
    import NonFungibleToken from 0xNonFungibleToken

    pub fun main(address: Address): [UInt64] {
      let account = getAccount(address)

      let collectionRef = account.getCapability(Necryptolis.CollectionPublicPath).borrow<&{Necryptolis.NecryptolisCollectionPublic}>()
          ?? panic("Could not borrow capability from public collection")
      
      return collectionRef.getIDs()
    }
`;

const GET_FUSD_CODE = cdc`
  import FungibleToken from 0xFungibleToken
  import FUSD from 0xFUSD

  pub fun main(address: Address): UFix64 {
    let account = getAccount(address)
    
    let balance = getAccount(address)
      .getCapability<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance)
      .check()

    if(!balance){
        return 0.0
    }

    let vaultRef = account
      .getCapability(/public/fusdBalance)
      .borrow<&FUSD.Vault{FungibleToken.Balance}>()
      ?? panic("Could not borrow Balance capability")
    
    return vaultRef.balance
  }
`;

export async function fetchBalance(
  address: string | undefined
): Promise<Balance> {
  if (!address)
    return Promise.resolve({
      fusd: 0,
      flow: 0,
      plotIds: [],
      address: address || "",
    });
  try {
    const fusd = (await send([
      script(GET_FUSD_CODE),
      args([arg(address, Address)]),
    ]).then(decode)) as string;
    const flow = await fcl.account(address).then((d: any) => d.balance);
    const plotIds = (await send([
      script(GET_PLOT_IDS_CODE),
      args([arg(address, Address)]),
    ]).then(decode)) as string[];

    return { fusd: parseFloat(fusd), flow: flow / 100000000, plotIds, address };
  } catch (err) {
    console.log("errror on get balance", err);
    return { fusd: 0, flow: 0, plotIds: [], address };
  }
}
