import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { tx, TxOptions } from "./utils/tx";
import { ListNFTRequestBody } from "../global/interfaces";

const CODE = fcl.cdc`
import Necryptolis from 0xNecryptolis
import NonFungibleToken from 0xNonFungibleToken
import FUSD from 0xFUSD
import FungibleToken from 0xFungibleToken
import NFTStorefront from 0xNFTStorefront

transaction(saleItemID: UInt64, saleItemPrice: UFix64) {

    let fusdReceiver: Capability<&FUSD.Vault{FungibleToken.Receiver}>
    let necryptolisProvider: Capability<&Necryptolis.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefront.Storefront

    prepare(account: AuthAccount) {
        // We need a provider capability, but one is not provided by default so we create one if needed.
        let necryptolisCollectionProviderPrivatePath = /private/necryptolisCollectionProvider

        self.fusdReceiver = account.getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)
        
        assert(self.fusdReceiver.borrow() != nil, message: "Missing or mis-typed FUSD receiver")

        account.unlink(necryptolisCollectionProviderPrivatePath)
        if !account.getCapability<&Necryptolis.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(necryptolisCollectionProviderPrivatePath)!.check() {
            account.link<&Necryptolis.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(necryptolisCollectionProviderPrivatePath, target: Necryptolis.CollectionStoragePath)
        }

        self.necryptolisProvider = account.getCapability<&Necryptolis.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(necryptolisCollectionProviderPrivatePath)!
        
        assert(self.necryptolisProvider.borrow() != nil, message: "Missing or mis-typed the Necryptolis.Collection provider")

        self.storefront = account.borrow<&NFTStorefront.Storefront>(from: NFTStorefront.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        let saleCut = NFTStorefront.SaleCut(
            receiver: self.fusdReceiver,
            amount: saleItemPrice
        )
        self.storefront.createListing(
            nftProviderCapability: self.necryptolisProvider,
            nftType: Type<@Necryptolis.NFT>(),
            nftID: saleItemID,
            salePaymentVaultType: Type<@FUSD.Vault>(),
            saleCuts: [saleCut]
        )
    }
}
`;

export function listNFT(
  listNFTRequestBody: ListNFTRequestBody,
  opts: TxOptions = {}
) {
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(listNFTRequestBody.nftId, t.UInt64),
        fcl.arg(String(listNFTRequestBody.price.toFixed(8)), t.UFix64),
      ]),
      fcl.proposer(fcl.authz),
      fcl.payer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
    ],
    opts
  );
}
