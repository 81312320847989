import * as fcl from "@onflow/fcl";

const CODE = fcl.cdc`
    import Necryptolis from 0xNecryptolis

    pub fun main(): Necryptolis.PlotSalesInfo {    
      return Necryptolis.plotSalesInfo
    }
`;

export interface PlotSalesInfo {
  squarePixelPrice: string;
  candlePrice: string;
  trimPrice: string;
  maxPlotHeight: number;
  maxPlotWidth: number;
  minPlotHeight: number;
  minPlotWidth: number;
}

export async function fetchPlotSalesInfo(): Promise<PlotSalesInfo> {
  try {
    const price = (await fcl.send([fcl.script(CODE)]).then(fcl.decode)) as any;

    return price;
  } catch (error) {
    console.log("error fetchPlotSalesInfo", error);
    return { candlePrice: "1.0", maxPlotHeight: 400, maxPlotWidth: 400, minPlotHeight: 200, minPlotWidth: 200, squarePixelPrice: "0.001", trimPrice: "1.0" }
  }
}
