import { Box } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { GetGradientId } from "../icons/GradientDefsFakeGrave";

interface CornerHolesGraveProps {
  frontColor: string;
  backColor: string;
}

export const CornerHolesGrave: FunctionComponent<CornerHolesGraveProps> = ({
  frontColor,
  backColor,
}: CornerHolesGraveProps) => (
  <Box maxWidth="150px" position="absolute">
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="160">
      <g fill={`url(#${GetGradientId(frontColor)})`}>
        <path d="M138.7 26.5 125.2 145c0 1.6-1.6 3-3.5 3H20.6c-2 0-3.5-1.4-3.5-3L3.5 26.5C17.6 26.5 29 16.8 29 4.8h84.2c0 12 11.5 21.7 25.5 21.7z" />
        <path d="M138.7 153.4c0 2-2 3.8-4.4 3.8H8c-2.4 0-4.4-1.7-4.4-3.8v-5c0-2 2-3.7 4.4-3.7h126.4c2.5 0 4.4 1.7 4.4 3.7v5z" />
      </g>
      <g fill={frontColor}>
        <path d="M146.8 26.5 133.3 145c0 1.6-1.6 3-3.5 3H28.7c-2 0-3.6-1.4-3.6-3L11.6 26.5c14 0 25.5-9.7 25.5-21.7h84.2c0 12 11.4 21.7 25.5 21.7z" />
        <path d="M146.8 153.4c0 2-2 3.8-4.4 3.8H16c-2.4 0-4.4-1.7-4.4-3.8v-5c0-2 2-3.7 4.4-3.7h126.4c2.4 0 4.4 1.7 4.4 3.7v5z" />
      </g>
      <path
        stroke={backColor}
        strokeWidth=".5"
        strokeMiterlimit="10"
        d="M8.3 156.9h134.6"
      />
    </svg>
  </Box>
);
