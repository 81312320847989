import { Icon } from "@chakra-ui/react";
import React from "react";

export const ShareIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M16.37 45.93c4.73 0 8.97-2.46 11.34-6.2l12.12 6.4c-.4.98-.59 2.17-.59 3.35a9.35 9.35 0 1 0 1.67-5.32l-12.12-6.4a13.03 13.03 0 0 0 0-10.46l12.12-6.4a9.36 9.36 0 1 0-1.67-5.32c0 1.18.2 2.26.59 3.35l-12.12 6.4a13.5 13.5 0 0 0-24.84 7.2 13.5 13.5 0 0 0 13.5 13.4zm32.23-3.65c3.94 0 7.1 3.16 7.1 7.1 0 3.94-3.16 7.1-7.1 7.1a7.07 7.07 0 0 1-7.1-7.1c0-3.84 3.16-7.1 7.1-7.1zm0-33.8c3.94 0 7.1 3.15 7.1 7.1 0 3.94-3.16 7.1-7.1 7.1a7.07 7.07 0 0 1-7.1-7.1c0-3.95 3.16-7.1 7.1-7.1zM16.37 21.39c6.11 0 11.14 5.03 11.14 11.14s-5.03 11.14-11.14 11.14A11.19 11.19 0 0 1 5.24 32.53c0-6.11 4.92-11.14 11.13-11.14z"
    />
  </Icon>
);
