import React, { useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import DimensionsIcon from "../../assets/dimensions_icon.svg";
import AccountBalance from "../account/AccountBalance";
import PlotPriceStat from "./PlotPriceStat";
import { Rect } from "../../global/interfaces";
import { useBalance } from "../../hooks/use-balance.hook";
import { fetchPlotPrice } from "../../flow/fetch-plot-price";
import CrossLayout from "../../components/CrossLayout";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { AddPlotIcon } from "../../icons/AddPlotIcon";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { mintPlot } from "../../flow/mint-plot";
import { useCurrentUser } from "../../hooks/use-current-user.hook";
import { SizeIcon } from "../../icons/SizeIcon";
import { LocationIcon } from "../../icons/LocationIcon";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

interface Props {
  disabled: boolean;
  rect: Rect;
}

export const AddPlotCluster = (props: Props) => {
  const { disabled, rect } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, isLoggedIn } = useCurrentUser();
  const [isMintButtonDisabled, setIsMintButtonDisabled] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { balance } = useBalance();
  const [plotPrice, setPlotPrice] = useState<string | undefined>(undefined);
  const appInsights = useAppInsightsContext();

  const addPlotOpened = () => {
    appInsights.trackTrace({
      message: `Add plot opened. ${JSON.stringify(rect)}`,
    });
    onOpen();
  };

  useEffect(() => {
    if (rect && isOpen) {
      const fetchPrice = async () => {
        const price = await fetchPlotPrice(rect);
        setPlotPrice(price);
      };

      fetchPrice();
    }
  }, [rect, isOpen]);

  useEffect(() => {
    if (!isLoggedIn) {
      setErrorMessage("Please log in to mint new cemetery plots.");
    } else if (balance.flow < 0.0001) {
      setIsMintButtonDisabled(true);
      setErrorMessage("Not enough Flow for transaction.");
    } else if (plotPrice && parseFloat(plotPrice) > balance.fusd) {
      setIsMintButtonDisabled(true);
      setErrorMessage("Not enough FUSD for transaction.");
    } else {
      setErrorMessage("");
      setIsMintButtonDisabled(false);
    }
  }, [balance, plotPrice, isLoggedIn]);

  return (
    <>
      <IconButton
        className="moveable-button"
        variant="grave"
        p={1}
        disabled={disabled}
        aria-label="Add Plot"
        _hover={{ background: "primary.600" }}
        fontSize="18px"
        icon={<AddPlotIcon maxH="50px" maxW="50px" />}
        onClick={addPlotOpened}
        onTouchStart={addPlotOpened}
      />
      <ThreeStripedModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Create Cemetery Plot"}
        midContent={<AccountBalance />}
        modalIcon={
          <AddPlotIcon
            maxH="64px"
            maxW="64px"
            p={1}
            color={"var(--chakra-colors-primary-900)"}
          />
        }
        bottomContent={
          <>
            <CrossLayout
              topLeftElement={
                <Stat m={4} textAlign="right">
                  <StatLabel>
                    <Flex>
                      <Spacer />
                      <SizeIcon
                        height="40px"
                        width="40px"
                        color={"var(--chakra-colors-primary-500)"}
                        src={DimensionsIcon}
                      />
                    </Flex>
                  </StatLabel>
                  <StatNumber>
                    ({`${rect.width.toFixed(0)} x ${rect.height.toFixed(0)}`})
                  </StatNumber>
                </Stat>
              }
              bottomRightElement={
                <Stat m={4}>
                  <StatLabel>Transaction price</StatLabel>
                  <StatNumber>{`0.0001`}</StatNumber>
                  <StatHelpText>in Flow</StatHelpText>
                </Stat>
              }
              bottomLeftElement={<PlotPriceStat price={plotPrice} />}
              topRightElement={
                <Stat m={4}>
                  <StatLabel>
                    <Flex>
                      <LocationIcon
                        height="36px"
                        width="36px"
                        color={"var(--chakra-colors-primary-500)"}
                      />
                    </Flex>
                  </StatLabel>
                  <StatNumber>
                    ({`${rect.left.toFixed(0)}, ${-rect.top.toFixed(0)}`})
                  </StatNumber>
                  <StatHelpText></StatHelpText>
                </Stat>
              }
            />
            <TriggerTransactionButton
              transactionRequestObject={{ rect, recipientAddress: user.addr! }}
              disabled={isMintButtonDisabled}
              doTransaction={mintPlot}
              onSuccessCallback={() => onClose()}
              onSuccessMessage={
                "You've minted a new Necryptolis NFT! Refresh your page in a minute or so to access it."
              }
              buttonText={"Mint cemetery plot"}
              helperText={errorMessage}
              price={plotPrice}
            />
          </>
        }
      />
    </>
  );
};
