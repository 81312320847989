import { Box, VStack } from "@chakra-ui/layout";
import {
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { FunctionComponent } from "react";
import { BackIcon } from "../icons/BackIcon";

interface ThreeStripedModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  topContent?: React.ReactNode;
  midContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  modalIcon: React.ReactNode;
  goBackCallback?: () => void;
}

const ThreeStripedModal: FunctionComponent<ThreeStripedModalProps> = (
  props: ThreeStripedModalProps
) => {
  const {
    isOpen,
    onClose,
    title,
    topContent,
    midContent,
    bottomContent,
    modalIcon,
    goBackCallback,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader margin="8px" textAlign="center">
          {goBackCallback && (
            <IconButton
              variant={"ghost"}
              position={"absolute"}
              left={8}
              h={6}
              maxW={6}
              aria-label="Go Back"
              minW={8}
              border="0"
              onClick={goBackCallback}
              icon={
                <BackIcon
                  color="var(--chakra-colors-primary-100)"
                  maxH="20px"
                  maxW="20px"
                />
              }
            ></IconButton>
          )}
          {title}
        </ModalHeader>
        <ModalCloseButton margin="12px" />
        <ModalBody p={0}>
          {topContent}
          <VStack
            backgroundColor="primary.900"
            color="primary.100"
            borderTopRadius="50px"
            paddingBottom="100px"
          >
            {midContent}
          </VStack>
          <VStack
            backgroundColor="primary.100"
            color="primary.900"
            borderTopRadius="50px"
            borderBottomRadius="50px"
            top="-50px"
            position="relative"
            marginBottom="-50px"
            paddingBottom={4}
          >
            <Box
              bg="primary.100"
              boxShadow="md"
              borderRadius="24px"
              height="80px"
              width="80px"
              position="relative"
              top="-30px"
              marginBottom="-40px"
            >
              <Center height="100%">{modalIcon}</Center>
            </Box>
            {bottomContent}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ThreeStripedModal;
