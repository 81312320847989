import { Icon } from "@chakra-ui/react";
import React from "react";

export const RelocateIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M59 27.6 38.5 7.2c-.4-.3-.8-.4-1.2-.2-.5.2-.7.6-.7 1v9.8a28.9 28.9 0 0 0-26 38.6c.1.4.6.7 1 .7.5 0 .9-.3 1-.7a26.7 26.7 0 0 1 24-17.6v10a1.1 1.1 0 0 0 1.9.8l20.3-20.4a1.1 1.1 0 0 0 0-1.6z"
    />
  </Icon>
);
