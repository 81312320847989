import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import * as fcl from "@onflow/fcl";

interface UserInfo {
  addr?: string;
  loggedIn?: boolean;
  cid?: string;
}

export const currentUser = atom({
  key: "CURRENT_USER",
  default: { addr: undefined, loggedIn: undefined, cid: undefined } as UserInfo,
});

const fclMethods = {
  logIn: fcl.logIn,
  logOut: fcl.unauthenticate,
  signUp: fcl.signUp,
  changeUser: fcl.reauthenticate,
};

export function useCurrentUser() {
  const [user, setUser] = useRecoilState(currentUser);

  useEffect(() => fcl.currentUser().subscribe(setUser), [setUser]);

  return {
    user,
    isLoggedIn: user.addr !== null && user.addr !== undefined,
    fclMethods,
  };
}
