import { UserDataResponse } from "../global/interfaces";

export const getUserdata = async (
  address: string | undefined
): Promise<UserDataResponse> => {
  let response = null;
  try {
    //http://localhost:7071/api/v1/users/0x5a354692c038f586
    response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/users/${address}`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const retval = (await response.json()) as UserDataResponse;

    return retval;
  } catch (error) {
    console.log("error getting user data");
    return { address: "", candles: [], nfts: [], dateCreated: "" };
  }
};
