import React, { FunctionComponent } from "react";
import Sketch from "@milosponj/react-q5xjs";
import { Stem } from "./Stem";
import {
  directionChangeChance,
  flowerFrequency,
  flowerMinimumHeight,
  flowerSize,
  GetLianaSettings,
  hexToColor,
  minimumStemWidth,
  specialFlowerFrequency,
  splitFrequency,
  stemDeathChance,
  stemWidth,
} from "./LianaSettings";
import { Flower, randomFlowerSize } from "./Flower";
import { GraveColor } from "../../global/interfaces";
import { dateDiffInDays } from "../../global/utils";

interface CanvasProps {
  graveId: number;
  lastTrimmed: Date;
  divId: string;
  width: number;
  height: number;
  graveColor: GraveColor;
}

export const P5Canvas: FunctionComponent<CanvasProps> = (props) => {
  const { divId, height, width, graveColor, graveId, lastTrimmed } = props;

  const { flowerColor, stemColor, specialFlowerColor, stemNarrowingRate } =
    GetLianaSettings(
      graveId,
      graveColor,
      dateDiffInDays(lastTrimmed, new Date())
    );
  const margin = height * 0.1;
  const stems: Stem[] = [];
  const flowers: Flower[] = [];

  const setup = (p5: any, parent: Element) => {
    const x = p5.createCanvas(width, height);
    document.getElementById("divId")?.appendChild(x);

    p5.randomSeed(graveId + (lastTrimmed.getTime() % 1000));
    createInitialStems(p5);
    p5.noLoop();
  };
  const draw = (p5: any) => {
    for (let index = 0; index < 70; index++) {
      stems.forEach((stem) => {
        // add a flower
        const r = p5.random();
        if (r <= flowerFrequency && stem.y <= height - flowerMinimumHeight) {
          let flowerX = stem.x + p5.random(-flowerSize, flowerSize) / 3;
          let flowerY = stem.y + p5.random(-flowerSize, flowerSize) / 3;
          const randomColor =
            r < specialFlowerFrequency && flowers.length > 25
              ? specialFlowerColor
              : flowerColor;
          flowers.push(
            new Flower(
              flowerX,
              flowerY,
              randomFlowerSize(p5),
              hexToColor(randomColor, p5),
              p5
            )
          );
        }

        // split the stem
        if (p5.random() <= splitFrequency) {
          stem.stemWidth = (stem.stemWidth / 5) * 4;
          stems.push(
            new Stem(
              stem.x,
              stem.y,
              stem.stemWidth,
              p5,
              stemColor,
              stemNarrowingRate
            )
          );
          stems[stems.length - 1].direction = -stem.direction; // make the stems grow in different directions
        }
        if (p5.random() <= directionChangeChance) {
          stem.direction *= -1;
        }
        if (p5.random() <= stemDeathChance * (height / stem.y)) {
          stems.pop();
          return;
        }
        if (
          stem.y < 30 ||
          stem.x > width - margin ||
          stem.x < margin ||
          stem.stemWidth < minimumStemWidth
        ) {
          stems.pop();
          return;
        }
        stem.grow(p5);
      });
    }
    // Update all flowers
    flowers.forEach((flower) => {
      flower.draw(p5);
    });
  };

  const createInitialStems = (p5: any) => {
    stems.push(
      new Stem(
        width / 2 - 2,
        height + 50,
        stemWidth,
        p5,
        stemColor,
        stemNarrowingRate
      )
    );
    stems.push(
      new Stem(
        width / 2 + 2,
        height + 50,
        stemWidth,
        p5,
        stemColor,
        stemNarrowingRate
      )
    );
    stems[0].direction = -1;
    stems[1].direction = -1;
  };

  return <Sketch setup={setup} draw={draw} id={divId} />;
};

export default P5Canvas;
