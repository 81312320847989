import { RenderProps, useToast } from "@chakra-ui/toast";
import { ReactNode } from "react";

export type Status = "error" | "success";

export function useCustomToast() {
  const toast = useToast();

  const displayToast = (
    err: Error | string,
    status: Status = "error",
    seeTxObject?: (props: RenderProps) => ReactNode
  ) => {
    const { title, description } = getDescriptionAndTitle(err, status);
    toast({
      position: "top",
      title,
      description,
      status,
      duration: 60000,
      isClosable: true,
      render: seeTxObject,
    });
  };

  return {
    displayToast,
  };
}

function getDescriptionAndTitle(
  err: Error | string,
  status: Status
): {
  title: string;
  description: string;
} {
  if (typeof err === "string") {
    return {
      title: status === "success" ? "Success" : "Flow blockchain error",
      description: err.split("-->")[0],
    };
  }
  if (err && err.message) {
    if (err.message.includes("Failed")) {
      return { title: "Failed stuffs", description: "Describe the failure" };
    } else if (err.message.includes("User rejected signature")) {
      return { title: "Aborted", description: "Transaction aborted." };
    } else if (err.message.includes("Response closed without headers")) {
      return {
        title: "Blockchain error",
        description: "There is a problem with accessing blockchain.",
      };
    } else {
      return { title: "Unknown error.", description: err.message };
    }
  } else {
    console.log(err);
    return {
      title: "Blockchain connection error",
      description: "Please try again at later date.",
    };
  }
}
