import { Icon } from "@chakra-ui/react";
import React from "react";

export const DiscordIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M52.2 18.9a26 26 0 0 0-12.4-5.1h-.2l-.2.7v.1l.1.3C44.8 16 48.2 18.6 51 21c-5-2.5-9.9-5-18.6-5s-13.6 2.5-18.6 5c2.9-2.5 6.1-5 11.3-6.2h.1c.2 0 .2-.2.2-.3l-.3-.7-.2-.2h-.2a26 26 0 0 0-12.4 5.2c-4.5 8.3-7 17.7-7 27.2 4 4.3 9.6 6.9 15.5 7.2.2 0 .5 0 .6-.2l1.5-2.1v-.5c-4-1.5-7.6-3.9-10.4-7.1 4 3.1 10 6.2 19.8 6.2s15.9-3.1 19.8-6.2a24.8 24.8 0 0 1-10.3 7c-.2 0-.2.4 0 .6l1.4 2 .6.3c1.8 0 10-.9 15.6-7.2l.2-.5A58.3 58.3 0 0 0 52.2 19z"
    />
    <ellipse fill={props.bgColor} cx="40.3" cy="36" rx="4.3" ry="5" />
    <ellipse fill={props.bgColor} cx="24.1" cy="36" rx="4.3" ry="5" />
  </Icon>
);
