import {
  Box,
  Center,
  Divider,
  Text,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spacer,
  Input,
  IconButton,
} from "@chakra-ui/react";
import React, { Suspense } from "react";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { CemeteryPlotData } from "../../global/interfaces";
import { toCustomDate, toNftMetadata } from "../../global/utils";
import { useBalance } from "../../hooks/use-balance.hook";
import { GraveBasicIcon } from "../../icons/GraveBasicIcon";
import { ShareIcon } from "../../icons/ShareIcon";
import { TwitterIcon } from "../../icons/TwitterIcon";
import { Grave } from "../grave/Grave";
import ExternalNFTBox from "./ExternalNFTBox";
import GravestonePanel from "./GravestonePanel";
import ListingInteractionPanel from "./ListingInteractionPanel";

interface CemeteryPlotInfoProps {
  cemeteryPlot: CemeteryPlotData;
  isPlotInfoOpen: boolean;
  onPlotInfoClose: () => void;
  onCreateGravestoneOpen: () => void;
  onLightCandleOpen: () => void;
  onTrimGraveOpen: () => void;
  onMarketPlotOpen: () => void;
  onBuryNFTOpen: () => void;
}

const CemeteryPlotInfo: FunctionComponent<CemeteryPlotInfoProps> = (
  props: CemeteryPlotInfoProps
) => {
  const {
    cemeteryPlot,
    isPlotInfoOpen,
    onPlotInfoClose,
    onCreateGravestoneOpen,
    onLightCandleOpen,
    onTrimGraveOpen,
    onMarketPlotOpen,
    onBuryNFTOpen,
  } = props;

  const {
    isOpen: isSharePlotOpened,
    onOpen: onSharePlotOpen,
    onClose: onSharePlotClose,
  } = useDisclosure();

  const { width, height, top, left } = cemeteryPlot.plot;
  const { balance } = useBalance();
  const isPlotInUsersCollection = balance.plotIds.includes(
    `${cemeteryPlot.id}`
  );
  const plotURL = `${window.location.origin}?x=${
    cemeteryPlot.plot.left
  }&y=${-cemeteryPlot.plot.top}&id=${cemeteryPlot.id}`;

  return (
    <>
      <ThreeStripedModal
        isOpen={isPlotInfoOpen}
        onClose={onPlotInfoClose}
        modalIcon={
          <GraveBasicIcon
            maxH="64px"
            maxW="64px"
            p={1}
            color={"var(--chakra-colors-primary-900)"}
          />
        }
        topContent={
          <HStack px={8} pb={4}>
            <VStack>
              <Center
                boxShadow="rgb(20 30 20 / 99%) 0px 5px 8px"
                width="80px"
                bg="primary.300"
                height="80px"
                color="primary.900"
                borderTopLeftRadius="32px"
                borderTopRightRadius="32px"
              >
                {cemeteryPlot.id}
              </Center>
              <Button
                variant={"ghost"}
                onClick={onSharePlotOpen}
                px={1}
                leftIcon={
                  <ShareIcon
                    maxW="24px"
                    maxH="24px"
                    color="var(--chakra-colors-primary-100)"
                  />
                }
              >
                Share
              </Button>
            </VStack>
            <Box m={2}>
              <Stat m={2}>
                <StatLabel>Owner</StatLabel>
                <StatNumber fontSize="md">
                  {isPlotInUsersCollection ? "Mine" : cemeteryPlot.owner}
                </StatNumber>
              </Stat>
              <Stat m={2}>
                <StatLabel>Minted on:</StatLabel>
                <StatNumber fontSize="md">
                  {/* TODO: add a real date here */}
                  {toCustomDate(cemeteryPlot.dateCreated)}
                </StatNumber>
              </Stat>
            </Box>
          </HStack>
        }
        midContent={
          <VStack w={"100%"}>
            <HStack height="50px" mt={6} spacing={4} width="100%">
              <Stat minW="140px" textAlign="right" width="100%">
                <StatLabel>Dimension</StatLabel>
                <StatNumber>
                  {width} x {height}
                </StatNumber>
              </Stat>
              <Divider orientation="vertical" borderColor="primary.100" />
              <Stat minW="140px" textAlign="left" width="100%">
                <StatLabel>Location</StatLabel>
                <StatNumber
                  fontSize={left > 99999 || top > 99999 ? "lg" : "2xl"}
                >
                  ({left}, {-top})
                </StatNumber>
              </Stat>
            </HStack>
            {cemeteryPlot.buriedNft && (
              <VStack py={4}>
                <Divider borderColor="primary.100" />
                <Text fontSize={"md"}>Here forever lies:</Text>
                <ExternalNFTBox
                  nftData={toNftMetadata(cemeteryPlot.buriedNft)}
                />
              </VStack>
            )}
          </VStack>
        }
        bottomContent={
          <>
            <GravestonePanel
              listing={cemeteryPlot.listing}
              buriedNft={cemeteryPlot.buriedNft}
              gravestone={cemeteryPlot.grave}
              isUserOwner={isPlotInUsersCollection}
              closeCemeteryPlotModal={onPlotInfoClose}
              openAddGravestoneModal={onCreateGravestoneOpen}
              openLightCandleModal={onLightCandleOpen}
              openTrimGraveModal={onTrimGraveOpen}
              openMarketPlotModal={onMarketPlotOpen}
              openBuryNftModal={onBuryNFTOpen}
            />
            {cemeteryPlot.listing && (
              <ListingInteractionPanel
                balance={balance}
                listing={cemeteryPlot.listing}
                isUserOwner={isPlotInUsersCollection}
              ></ListingInteractionPanel>
            )}
          </>
        }
      />
      <Modal isOpen={isSharePlotOpened} onClose={onSharePlotClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader margin="8px" textAlign="center">
            Share Necryptolis NFT
          </ModalHeader>
          <ModalCloseButton margin="12px" />
          <ModalBody>
            <HStack mx={4} spacing={0}>
              {cemeteryPlot.grave?.name ? (
                <Grave
                  {...cemeteryPlot.grave}
                  title={cemeteryPlot.grave.name}
                />
              ) : (
                <Box border="1px dashed" borderColor="primary.600" h="100%">
                  <Center fontSize="sm" height="100%" minH={"120px"} m={2}>
                    <Text textAlign="center" color="primary.400">
                      No gravestone yet.
                    </Text>
                  </Center>
                </Box>
              )}

              <VStack h={"100%"} display={["none", "flex"]} pl={4}>
                <Box>
                  <Text mt={2} fontSize="sm" color="primary.400">
                    Minted on:
                  </Text>
                  <Text color="primary.100" fontSize="sm">
                    {toCustomDate(cemeteryPlot?.grave?.dateCreated)}
                  </Text>
                </Box>
                <Box>
                  <Text mt={2} fontSize="sm" color="primary.400">
                    Dimensions:
                  </Text>
                  <Text color="primary.100" fontSize="sm">
                    {cemeteryPlot.plot.width} x {cemeteryPlot.plot.height}
                  </Text>
                </Box>
              </VStack>
              <Spacer />
              <Divider
                pr={2}
                orientation="vertical"
                borderColor="primary.400"
              />
              <VStack mt={3} alignItems={"flex-start"}>
                <Box>
                  <Text mt={2} fontSize="sm" color="primary.400">
                    Nft ID:
                  </Text>
                  <Text color="primary.100" fontSize="sm">
                    {cemeteryPlot.id}
                  </Text>
                </Box>

                <Box>
                  <Text mt={2} fontSize="sm" color="primary.400">
                    Position:
                  </Text>
                  <Text minWidth={"100px"} color="primary.100" fontSize="sm">
                    ( {cemeteryPlot.plot.left}, {-cemeteryPlot.plot.top} )
                  </Text>
                </Box>
              </VStack>
            </HStack>
            <HStack p={4}>
              <Input
                variant="outline"
                bg="primary.100"
                color="primary.800"
                defaultValue={plotURL}
              />
              <Button
                variant="ghost"
                onClick={() => navigator.clipboard.writeText(plotURL)}
              >
                Copy
              </Button>
              <IconButton
                variant="ghost"
                aria-label="Tweet"
                onClick={() => {
                  window.open(
                    `http://twitter.com/intent/tweet?text=Necryptolis plot with dimensions of ${width}x${height}.%0aPosition: (${left},${-top})%0a&url=${encodeURIComponent(
                      plotURL
                    )}&hashtags=necryptolis,nft`
                  );
                }}
                icon={
                  <TwitterIcon
                    height="32px"
                    maxW={"32px"}
                    color={"var(--chakra-colors-primary-100)"}
                  />
                }
              ></IconButton>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const WrappedCemeteryPlotInfo = (props: CemeteryPlotInfoProps) => {
  return (
    <Suspense fallback={<></>}>
      <CemeteryPlotInfo {...props} />
    </Suspense>
  );
};

export default WrappedCemeteryPlotInfo;
