import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { FunctionComponent } from "react";
import { PlotDataResponse } from "../../global/interfaces";
import { toCustomDate } from "../../global/utils";
import { Grave } from "../grave/Grave";

interface NFTBoxProps {
  data: PlotDataResponse;
}

const NFTBox: FunctionComponent<NFTBoxProps> = ({ data }: NFTBoxProps) => {
  const appInsights = useAppInsightsContext();
  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="center"
      onClick={() => {
        appInsights.trackTrace({
          message: `Clicking on NFT BOX, ${JSON.stringify({
            id: data.id,
            rect: data.plotData,
            owner: data.owner,
          })}`,
        });
        window.location.href =
          window.location.href.split("?")[0] +
          `?x=${data.plotData.left}&y=${-data.plotData.top}&id=${data.id}`;
      }}
      p={"2px"}
    >
      <Flex
        mx="0"
        boxShadow="lg"
        bg={"primary.100"}
        rounded="lg"
        overflow="hidden"
        _hover={{
          cursor: "pointer",
          boxShadow: "md",
        }}
        w="95%"
        p={2}
      >
        <Box w={1 / 3}>
          {data.graveData?.name ? (
            <Grave
              fromDate={""}
              toDate={""}
              {...data.graveData.metadata}
              title={data.graveData.name}
            />
          ) : (
            <Box border="1px dashed" borderColor="primary.600" h="100%">
              <Center fontSize="sm" height="100%">
                <Text textAlign="center" color="primary.400">
                  No gravestone yet.
                </Text>
              </Center>
            </Box>
          )}
        </Box>

        <HStack w={2 / 3} p={{ base: 4, md: 4 }} ml={4} mr={0} spacing={0}>
          <VStack display={["none", "flex"]} minW={"100px"}>
            <Box>
              <Text mt={2} fontSize="sm" color="primary.600">
                Minted on:
              </Text>
              <Text color="primary.900" fontSize="sm">
                {toCustomDate(data.dateCreated)}
              </Text>
            </Box>

            <Box>
              <Text mt={2} fontSize="sm" color="primary.600">
                Dimension:
              </Text>
              <Text color="primary.900" fontSize="sm">
                {data.plotData.width} x {data.plotData.height}
              </Text>
            </Box>
          </VStack>
          <Spacer />
          <Divider pr={2} orientation="vertical" borderColor="primary.600" />
          <VStack mt={3} alignItems={"flex-start"}>
            <Box>
              <Text mt={2} fontSize="sm" color="primary.600">
                Nft ID:
              </Text>
              <Text color="primary.900" fontSize="sm">
                {data.id}
              </Text>
            </Box>

            <Box>
              <Text mt={2} fontSize="sm" color="primary.600">
                Position:
              </Text>
              <Text minWidth={"100px"} color="primary.900" fontSize="sm">
                ( {data.plotData.left}, {-data.plotData.top} )
              </Text>
            </Box>
          </VStack>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default NFTBox;
