import { VStack, Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import React, { Suspense } from "react";
import { FunctionComponent } from "react";
import { PlotDataResponse } from "../../global/interfaces";
import { useUserData } from "../../hooks/use-user-data.hook";
import AccountNFTBox from "./NFTBox";

interface AccountNFTsTabProps {
  address: string | undefined;
}

const AccountNFTsTab: FunctionComponent<AccountNFTsTabProps> = ({
  address,
}) => {
  const { userData } = useUserData(address);

  return (
    <Box py={5}>
      <VStack maxH={"460px"} overflowY="auto">
        {userData.nfts.length ? (
          userData.nfts.map((nftData: PlotDataResponse) => {
            return (
              <AccountNFTBox key={`nftBox-${nftData.id}`} data={nftData} />
            );
          })
        ) : (
          <Text px={8} textAlign="center" fontSize={"medium"}>
            Still waiting on your first Necryptolis NFT.
          </Text>
        )}
      </VStack>
    </Box>
  );
};

const WrappedAccountNFTsTab = (props: AccountNFTsTabProps) => {
  return (
    <Suspense
      fallback={
        <VStack pt={"20px !important"}>
          <Spinner />
        </VStack>
      }
    >
      <AccountNFTsTab address={props.address} />
    </Suspense>
  );
};

export default WrappedAccountNFTsTab;
