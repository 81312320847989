import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
if (typeof window !== "undefined" && process.env.REACT_APP_ENV !== "local") {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_IKEY,
      extensions: [reactPlugin],
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      distributedTracingMode: DistributedTracingModes.W3C,
    },
  });
  appInsights.loadAppInsights(); // To initiate session for the user
  appInsights.trackPageView(); // To track every page views
}

export { reactPlugin };
