import { Icon } from "@chakra-ui/react";
import React from "react";

export const NecryptolisCircleLogo = (props: any) => (
  <Icon viewBox="0 0 62 62" width="64" height="64" {...props}>
    <circle
      fill="none"
      stroke={props.color || "var(--chakra-colors-primary-100)"}
      strokeWidth="2"
      strokeMiterlimit="10"
      cx="32"
      cy="33.98"
      r="26.38"
    />
    <path
      fill="none"
      stroke={props.color || "var(--chakra-colors-primary-100)"}
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M30.72 40.97c-.1-.65-.08-1.34.12-2.04l3.52-12.67a4.95 4.95 0 0 1 5.66-3.6l4.03.86c2.43.53 3.84 2.98 3.14 5.5l-3.53 12.67c-.14.51-.35.98-.63 1.4"
    />
    <path
      fill="none"
      stroke={props.color || "var(--chakra-colors-primary-100)"}
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M7.25 44.51s24.48-7.38 49.5 1.45"
    />
    <path
      fill="none"
      stroke={props.color || "var(--chakra-colors-primary-100)"}
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M26.8 41.33s-5.04-18.06-2.86-20.51"
    />
    <path
      fill={props.color || "var(--chakra-colors-primary-100)"}
      d="m27.26 22.23-.16-.05-.44-.1a19.12 19.12 0 0 0-6.97-.27c-.47.1-.96.15-1.41.3-.46.13-.93.25-1.34.43-.43.16-.85.32-1.21.54-.38.2-.75.36-1.04.59l-.85.58-.59.53-.51.46.42-.55.5-.64.74-.75c.26-.29.6-.53.95-.81.34-.3.75-.55 1.17-.8.41-.28.87-.5 1.34-.72.46-.25.96-.4 1.46-.6 1-.36 2.03-.63 3.01-.8a22.05 22.05 0 0 1 5.46-.3l-.53 2.96z"
    />
  </Icon>
);
