import { Icon } from "@chakra-ui/react";
import React from "react";

export const FlovatarIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M23.6 7.9c12-4.4 22.6-6 23.9-3.5 1.2 2.4-7.1 7.8-18.7 12.1l-.6-1.5c9.3-3.4 16-7.1 15-9-1-2-9.2-.3-18.8 3.2-9.5 3.5-16.8 7.5-15.7 9.5.8 1.5 6.2.7 13.2-1.5l-1.2 2c-8.5 2.4-15.2 3-16.2 1-1.3-2.4 7.3-8 19.1-12.3z"
    />
    <path
      fill={props.color}
      d="M60 34.5a20.1 20.1 0 0 0-3.3-5.7v-4.6a21.7 21.7 0 0 0-1.7-7c-.2-.4-.7-1-1.2-.5s-.8 1.2-1.2 1.9a29 29 0 0 1-2 3c1.3 1.1 2.5 2.5 4.1 4.8a28 28 0 0 0-13.3-7c-2.5-.7-5-1-7.5-1h-.1c-2 0-3.9.2-5.8.6-.5-3-1.9-5-2.2-5.5-.4-.5-.6.5-.7.8L21.3 21l-3 6L19 22A22.7 22.7 0 0 0 7.3 36H7c-2.9 0-5.2 2.6-5.2 5.8 0 3.2 2.3 5.9 5.2 5.9h.6a20 20 0 0 0 10 10c2.6 1.3 5.3 2.3 8 2.8a43.2 43.2 0 0 0 19.8-.4 26 26 0 0 0 8.3-3.9c3.7-2.7 6-6.1 7-10.3 1.5-1 2.6-3 2.6-5.5 0-2.7-1.4-5-3.3-5.9zM32.8 47.3c-4.3 0-7.8-3-7.8-6.5 0-3.6 3.5-6.5 7.8-6.5s7.8 2.9 7.8 6.5-3.5 6.5-7.8 6.5zM54.3 53a8 8 0 0 1-4.8 2c-4 .4-7.5-2.2-8.2-5.8 1.2 2.4 5.5 3.9 10.4 3.5.8 0 1.5-.2 2.3-.3.2 0 .3 0 .4.3l-.1.3zm4-7.7c-1.9 0-3.5-2.2-3.5-5 0-2.7 1.6-4.9 3.6-4.9s3.5 2.2 3.5 5-1.5 4.9-3.5 4.9z"
    />
  </Icon>
);
