import React, { Suspense } from "react";
import {
  IconButton,
  useDisclosure,
  Spinner,
  VStack,
  Image,
  Text,
  Button,
  Icon,
  HStack,
  Link
} from "@chakra-ui/react";
import Logo from "../../assets/necryptolis logo.svg";
import AboutModal from "./AboutModal";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { NecryptolisCircleLogo } from "../../icons/NecryptolisCircleLogo";
import { SupportIcon } from "../../icons/SupportIcon";
import LatestTransactions from "./LatestTransactions";
import { TwitterIcon } from "../../icons/TwitterIcon";
import { DiscordIcon } from "../../icons/DiscordIcon";

const NecryptolisInfoCluster = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAboutOpen,
    onOpen: onAboutOpen,
    onClose: onAboutClose,
  } = useDisclosure();
  return (
    <>
      <IconButton

        variant="grave"
        m={"4px !important"}
        my={"8px !important"}        
        height="64px"
        minWidth={"66px"}
        aria-label="Necryptolis"
        borderColor={"primary.100"}
        icon={<NecryptolisCircleLogo maxH="56px" maxW="56px" p={1} />}
        onClick={() => {
          onOpen();
        }}
        onTouchEnd={() => {
          onOpen();
        }}
      />
      <ThreeStripedModal
        isOpen={isOpen}
        onClose={onClose}
        modalIcon={
          <NecryptolisCircleLogo
            maxH="64px"
            maxW="64px"
            p={1}
            color={"var(--chakra-colors-primary-800)"}
          />
        }
        topContent={
          <VStack>
            <Image
              px={20}
              pb={2}
              width="100%"
              alt="Necryptolis logo"
              src={Logo}
            />
            <Text pb={4} color={"primary.100"} fontWeight={200}>
              Internet's own graveyard.
            </Text>
          </VStack>
        }
        midContent={
          <HStack py={4}>
            <VStack pt={4} align={"flex-end"}>
              <Link href="https://support.necryptolis.com" isExternal>
                <Button
                  leftIcon={
                    <SupportIcon
                      maxH="28px"
                      maxW="32px"
                      color={"var(--chakra-colors-primary-100)"}
                    />
                  }
                  variant="ghost"
                >
                  Support
                </Button>
              </Link>
              <Link href="https://discord.gg/BSMhEPxbnm" isExternal>
                <Button
                  leftIcon={
                    <DiscordIcon
                      maxH="32px"
                      maxW="32px"
                      color={"var(--chakra-colors-primary-100)"}
                    />
                  }
                  variant="ghost"
                >
                  Join
                </Button>
              </Link>
            </VStack>

            <VStack pt={4} align={"flex-start"}>
              <Link href="https://twitter.com/Necryptolis" isExternal>
                <Button
                  leftIcon={
                    <TwitterIcon
                      maxH="28px"
                      maxW="32px"
                      color={"var(--chakra-colors-primary-100)"}
                    />
                  }
                  variant="ghost"
                >
                  Follow
                </Button>
              </Link>
              <Button
                leftIcon={<Icon />}
                variant="ghost"
                onClick={() => {
                  onAboutOpen();
                  onClose();
                }}
              >
                About
              </Button>
            </VStack>
          </HStack>
        }
        bottomContent={<LatestTransactions />}
      />

      <AboutModal
        isOpen={isAboutOpen}
        onClose={onAboutClose}
        onBack={() => {
          onOpen();
          onAboutClose();
        }}
      />
    </>
  );
};

const WrappedNecryptolisInfoCluster = () => {
  return (
    <Suspense
      fallback={
        <IconButton
          variant="grave"
          m={4}
          height="64px"
          width="64px"
          aria-label="Necryptolis"
          _hover={{ background: "primary.600" }}
          icon={<Spinner />}
        />
      }
    >
      <NecryptolisInfoCluster />
    </Suspense>
  );
};

export default WrappedNecryptolisInfoCluster;
