import {
  Button,
  Divider,
  HStack,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, { Suspense } from "react";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { setupAccount } from "../../flow/setup-account";
import { TransactionState } from "../../global/constants";
import { useBalance } from "../../hooks/use-balance.hook";
import { useCurrentUserInitalized } from "../../hooks/use-initialized.hook";
import { AttentionIcon } from "../../icons/AttentionIcon";

const AccountBalance = () => {
  const { balance, status, logIn, isLoggedIn } = useBalance();
  const { isUserInitalized, refresh } = useCurrentUserInitalized();

  if (!isLoggedIn) {
    return (
      <VStack p={4}>
        <Button
          mt={4}
          onClick={logIn}
          variant="ghost"
          leftIcon={<AttentionIcon color="salmon" width={6} height={6} />}
        >
          Login
        </Button>
        <HStack>
          <Text fontSize={"md"}>Please log in to proceed.</Text>
        </HStack>
      </VStack>
    );
  }

  return isUserInitalized ? (
    <HStack height="50px" mt={6} spacing={4}>
      <Stat minW="100px" textAlign="right">
        <StatLabel>FUSD</StatLabel>
        {status === TransactionState.Idle ? (
          <StatNumber whiteSpace={"nowrap"}>
            ${balance.fusd.toFixed(2)}
          </StatNumber>
        ) : (
          <Spinner />
        )}
      </Stat>
      <Divider orientation="vertical" borderColor="primary.100" />
      <Stat minW="100px" textAlign="center">
        <StatLabel>Flow</StatLabel>
        {status === TransactionState.Idle ? (
          <StatNumber whiteSpace={"nowrap"}>
            {balance.flow.toFixed(2)}
          </StatNumber>
        ) : (
          <Spinner />
        )}
      </Stat>
      <Divider orientation="vertical" borderColor="primary.100" />
      <Stat minW="80px">
        <StatLabel>NFTs</StatLabel>
        {status === TransactionState.Idle ? (
          <StatNumber id="nfts-count">{balance.plotIds.length}</StatNumber>
        ) : (
          <Spinner />
        )}
      </Stat>
    </HStack>
  ) : (
    <VStack mt={5} px={8}>
      <TriggerTransactionButton
        transactionRequestObject={undefined}
        disabled={false}
        doTransaction={setupAccount}
        buttonText={"Initialize account"}
        helperText={
          "By initializing you are allowing Necryptolis NFTs to your account. It's free and you only do it once."
        }
        onSuccessMessage={
          "Account initialized. Now you can have Necryptolis NFTs in your collection."
        }
        onSuccessCallback={refresh}
      />
    </VStack>
  );
};

const WrappedAccountBalance = () => {
  return (
    <Suspense
      fallback={
        <HStack height="50px" mt={6} spacing={4}>
          <Stat>
            <StatLabel>FUSD</StatLabel>
            <Spinner size="sm" />
          </Stat>
          <Divider orientation="vertical" borderColor="primary.100" />
          <Stat>
            <StatLabel>Flow</StatLabel>
            <Spinner size="sm" />
          </Stat>
        </HStack>
      }
    >
      <AccountBalance />
    </Suspense>
  );
};

export default WrappedAccountBalance;
