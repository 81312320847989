import { Icon } from "@chakra-ui/react";
import React from "react";

export const CandleIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      stroke={props.color}
      strokeWidth=".8"
      strokeMiterlimit="10"
      d="M47.9 58.3h-5.3v-22c2.6-1.2 1.4-2.8 1.7-14.4 0-.8-.4-1.5-1-2-.6-.7-1.5-1-2.4-1l-17.7.1c-1 0-1.8.3-2.4.9-.6.6-1 1.3-1 2.1v15.5c0 .7.3 1.4.8 2 .5.5 1.2.9 2 1v17.8H18c-.4 0-.8.3-.8.6s.4.7.8.7h29.9c.4 0 .7-.3.7-.7s-.3-.6-.7-.6zM21.3 37.5V22c0-1 .8-1.7 1.9-1.7h17.7c.5 0 1 .1 1.4.4.3.4.5.8.5 1.2-.2 12.3.4 12.2-.5 13-.5.4-1.2.6-1.8.3-.7-.2-1-.8-1-1.4V29c-.1-1-.7-2-1.7-2.4-1-.5-2.3-.5-3.3 0a3 3 0 0 0-1.7 2.4c-.2 1 .5 3.3-1.2 3.3h-3.8c-.7 0-1.4.2-1.9.7-.4.4-.7 1-.7 1.6-.1 2.6.4 3.4-.6 4.3a2 2 0 0 1-1.4.5 2 2 0 0 1-1.3-.5c-.4-.4-.6-.8-.6-1.3zm2.7 3c.7-.1 1.4-.5 2-1 .4-.6.7-1.3.7-2v-3c0-.6.5-1 1-1h3.9c.7 0 1.3-.3 1.8-.7.5-.4.8-1 .8-1.7V29c.1-.8 1-1.5 2-1.5.9 0 1.7.7 1.8 1.5v5c0 .7.3 1.4.9 2 .6.4 1.4.7 2.2.8v21.6H24V40.5zm12.8-24.4c1-.9 1.7-2.1 1.8-3.4a5 5 0 0 0-1.1-3.6c-3-3.2-3.1-4.7-5-4.7-1.8 0-2 1.6-4.9 4.7-1.2 1.5-1.5 3.4-.7 5.1s2.4 3 4.4 3.4c2 .4 4-.2 5.5-1.5zm-8-6.2c3.2-3.9 3-4.2 3.7-4.2.7 0 .5.3 3.8 4.2.6.8 1 1.7.8 2.7a4 4 0 0 1-1.3 2.6c-4 3.5-10.3-1.3-7-5.3z"
    />
  </Icon>
);
