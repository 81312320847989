import { Checkbox, HStack, Link, VStack, Text, Image } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { NFTMetadata, NFTPlatform } from "../../global/interfaces";
import { AttentionIcon } from "../../icons/AttentionIcon";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";

interface ExternalNFTBoxProps {
  nftData: NFTMetadata;
  isSelected?: boolean;
  setIsSelected?: (isSelected: number | undefined) => void;
  platform?: NFTPlatform | "";
}

const ExternalNFTBox: FunctionComponent<ExternalNFTBoxProps> = ({
  nftData,
  isSelected,
  setIsSelected,
  platform,
}) => {
  return (
    <HStack
      key={nftData.id}
      _hover={{ background: "primary.700" }}
      onClick={() => {
        setIsSelected && setIsSelected(nftData.id);
      }}
      width="100%"
      borderWidth={isSelected ? "3px" : "1px"}
      borderRadius="8px"
      borderColor={"primary.100"}
      bg={isSelected ? "primary.600" : "primary.900"}
    >
      {nftData.image ? (
        <Image
          borderLeftRadius={"8px"}
          src={nftData.image}
          width="90px"
          height="90px"
        />
      ) : (
        <VStack minH={"90px"} maxW={"90px"}>
          <AttentionIcon
            mt={2}
            height="22px"
            width="22px"
            color={"var(--chakra-colors-primary-100)"}
          />
          <Text fontSize={"sm"} px={1} align="center">
            Image unavailable
          </Text>
        </VStack>
      )}

      <VStack align={"left"} overflow="hidden" spacing={0}>
        <HStack>
          {setIsSelected && (
            <Checkbox
              pl={1}
              colorScheme="blackAlpha"
              borderColor="primary.600"
              textAlign="center"
              iconSize={8}
              iconColor="primary.100"
              isChecked={isSelected}
            />
          )}
          <Text p={1} fontSize={"md"}>
            {nftData.name}
          </Text>
        </HStack>
        <HStack>
          <Text color="primary.500" fontWeight={"semibold"} mx={1}>#</Text>
          <Text p={1} fontSize={"md"}>
            {nftData.rarity}
          </Text>
        </HStack>
        {nftData.url && (
          <Link
            px={1}
            color="primary.100"
            fontSize={"sm"}
            href={nftData.url}
            isExternal
          >
            <HStack>
              <ExternalLinkIcon
                color={"var(--chakra-colors-primary-500)"}
                width="16px"
                height="16px"
              />
              <Text overflowX={"hidden"} p={1} fontSize={"md"}>
                See on {platform || nftData.platform || "platform."}
              </Text>
            </HStack>
          </Link>
        )}
      </VStack>
    </HStack>
  );
};

export default ExternalNFTBox;
