import {
  BuriedNft,
  CemeteryPlotData,
  GraveColor,
  GraveColorConfig,
  GraveDateType,
  GraveFont,
  GraveShape,
  NFTMetadata,
  PlotDataResponse,
  Rect,
  ScreenCoordinates,
} from "./interfaces";

export const toCustomDate = (date: string | Date | undefined) => {
  if (!date) {
    return "";
  }
  if (typeof date == "string")
    return new Date(date).toDateString().substring(3);

  return date.toDateString().substring(3);
};

export const areRectsColliding = (rectA: Rect, rectB: Rect): boolean => {
  if (
    rectA.left <= rectB.left + rectB.width &&
    rectA.left + rectA.width > rectB.left + 1 &&
    rectA.top < rectB.top + rectB.height &&
    rectA.top + rectA.height > rectB.top + 1
  ) {
    return true;
  }

  return false;
};

export const serializeCoordinates = (
  coordinates: ScreenCoordinates
): string => {
  return [
    coordinates.topLeft.x,
    coordinates.topLeft.y,
    coordinates.bottomRight.x,
    coordinates.bottomRight.y,
  ].join(",");
};

export const expandCoordinates = (key: string): ScreenCoordinates => {
  const coordinatesList = key.split(",");
  return {
    topLeft: {
      x: parseInt(coordinatesList[0]),
      y: parseInt(coordinatesList[1]),
    },
    bottomRight: {
      x: parseInt(coordinatesList[2]),
      y: parseInt(coordinatesList[3]),
    },
  };
};

export const toNftMetadata = (buriedNft: BuriedNft): NFTMetadata => {
  if (buriedNft.nftType.includes("Flovatar")) {
    return {
      id: buriedNft.nftID,
      image: `https://flovatar.com/api/image/${buriedNft.nftID}`,
      name: `Flovatar #${buriedNft.nftID}`,
      url: `https://flovatar.com/flovatars/${buriedNft.nftID}`,
      platform: "Flovatar",
    };
  }
  return {
    id: buriedNft.nftID,
    image: "",
    name: `Unknown NFT ${buriedNft.nftID}`,
    url: "",
  };
};

export const toCemeteryPlotData = (
  response: PlotDataResponse
): CemeteryPlotData => {
  return {
    id: response.id,
    owner: response.owner,
    dateCreated: new Date(response.dateCreated),
    grave: {
      fromDate: response.graveData.fromDate,
      toDate: response.graveData.toDate,
      metadata: response.graveData.metadata,
      dateCreated: new Date(
        response.dateGravestoneCreated || response.dateCreated
      ),
      name: response.graveData.name,
      font: response.graveData.metadata?.font
        ? (response.graveData.metadata.font as GraveFont)
        : "roman",
      shape: response.graveData.metadata?.shape
        ? (response.graveData.metadata.shape as GraveShape)
        : "normal",
      color: response.graveData.metadata?.color
        ? (response.graveData.metadata.color as GraveColor)
        : "teal",
      dateType: response.graveData.metadata?.dateType
        ? (response.graveData.metadata.dateType as GraveDateType)
        : "year",
      xOffset: response.graveData.metadata?.xOffset
        ? response.graveData.metadata.xOffset
        : "0px",
      yOffset: response.graveData.metadata?.yOffset
        ? response.graveData.metadata.yOffset
        : "0px",
    },
    plot: response.plotData,
    candles: response.candles.map((c) => {
      return { address: c.address, dateBought: new Date(c.dateBought) };
    }),
    lastTrimDate: response.lastTrimDate
      ? new Date(response.lastTrimDate)
      : new Date(),
    listing: response.listing,
    buriedNft: response.buriedNft,
  };
};

export const getFontFamily = (font: GraveFont): string => {
  switch (font) {
    case "optima":
      return "Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif";
    case "zapf":
      return "Tangerine";
    case "goth":
      return "Pirata One";
    default:
      return "Baskerville, Baskerville Old Face, Garamond, Times New Roman, serif";
  }
};

export const getColorConfig = (
  color: GraveColor = "teal"
): GraveColorConfig => {
  const mainColor = `var(--chakra-colors-${color})`;

  let embossMainColor = "var(--chakra-colors-primary-500)";
  let shadowColor = "var(--chakra-colors-primary-700)";
  let highlightColor = "var(--chakra-colors-primary-200)";
  if (color === "salmonDark") {
    embossMainColor = "#4e2e35";
    shadowColor = "#221212";
    highlightColor = "#cb9999";
  } else if (color.toString().includes("salmon")) {
    embossMainColor = "#5e3e45";
    shadowColor = "#4e2e35";
    highlightColor = "#d0c4c6";
  } else if (color.toString().includes("tealDark")) {
    embossMainColor = "#223c43";
    shadowColor = "#0a0507";
    highlightColor = "#b5aeb1";
  } else if (color.toString().includes("teal")) {
    embossMainColor = "#30565f";
    shadowColor = "#1d3540";
    highlightColor = "#a8bbbf";
  } else if (color.toString().includes("stoneDark")) {
    embossMainColor = "#373537";
    shadowColor = "#2a2527";
    highlightColor = "#b5aeb1";
  } else if (color.toString().includes("stone")) {
    embossMainColor = "#797376";
    shadowColor = "#40373b";
    highlightColor = "#e1dee0";
  } else if (color.toString().includes("lilacDark")) {
    embossMainColor = "#402f4b";
    shadowColor = "#2d1b2d";
    highlightColor = "#be9bbe";
  } else if (color.toString().includes("lilac")) {
    embossMainColor = "#675567";
    shadowColor = "#2b1825";
    highlightColor = "#dedbde";
  }

  return {
    mainColor,
    embossMainColor,
    shadowColor,
    highlightColor,
  };
};

export const getPixelValue = (offset?: string): number => {
  if (!offset) return 0;
  try {
    const match = offset.match(/(\d+)px/);
    if (match?.length) {
      const number = match[0];
      return parseInt(number);
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};
