import { atomFamily, selectorFamily, useRecoilState } from "recoil";
import { TransactionState } from "../global/constants";
import { useCurrentUser } from "./use-current-user.hook";
import { fetchIsUserInitalized } from "../flow/fetch-is-user-initalized";
import { useBalance } from "./use-balance.hook";
import { useEffect } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const valueAtom = atomFamily({
  key: "initialized::state",
  default: selectorFamily({
    key: "initialized::default",
    get: (address: string | undefined) => async () =>
      fetchIsUserInitalized(address),
  }),
});

const statusAtom = atomFamily({
  key: "current-user-initalized::status",
  default: TransactionState.Idle,
});

export function useCurrentUserInitalized() {
  const { user } = useCurrentUser();
  const [isUserInitalized, setIsUserInitalized] = useRecoilState(
    valueAtom(user.addr)
  );
  const { refresh: refreshBalance } = useBalance();
  const [status, setStatus] = useRecoilState(statusAtom(user.addr));
  const appInsights = useAppInsightsContext();
  useEffect(() => {
    appInsights.trackTrace({
      message: `Current user, ${JSON.stringify({ ...user, isUserInitalized })}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserInitalized]);

  return {
    isUserInitalized,
    status,
    async refresh() {
      setStatus(TransactionState.Processing);
      await fetchIsUserInitalized(user.addr).then(setIsUserInitalized);
      await refreshBalance();
      setStatus(TransactionState.Idle);
    },
  };
}
