import { Icon } from "@chakra-ui/react";
import React from "react";

export const CutBushIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="m38.9 30.1 1 1.6 8.3-13c1.3-2.2 1.7-4.8 1.2-7.3l-1.2-4.5c-.7-2.6-2.5-2.8-3-2.8-.6 0-1.8.3-2.8 1.9l-9.5 14.8 1 1.6L44 7c.4-.7.9-1 1.3-1 .3 0 .6.2.8.4L34.8 23.8l1 1.6 11-17 .9 3.4c.4 2 0 4.2-1 6L39 30zM17 46.9a4.8 4.8 0 1 0 0 9.7 4.8 4.8 0 0 0 0-9.7zm0 8a3 3 0 1 1 0-6.2 3 3 0 0 1 0 6.1z"
    />
    <path
      fill={props.color}
      d="M47 43c-1.2 0-2.5.2-3.7.8l-.1-.2c-.2-.4-.4-.9-.4-1.4-.1-1.6-.7-4-1.8-5.8L21.6 6c-1-1.6-2.2-1.9-2.8-1.9-.5 0-2.3.2-3 2.8l-1.2 4.5c-.5 2.5-.1 5 1.1 7.3L25 33.2l-2 3.2a13.7 13.7 0 0 0-1.8 5.8 3 3 0 0 1-.5 1.6 8.7 8.7 0 0 0-11 3.1A8.9 8.9 0 0 0 12 59.1a8.7 8.7 0 0 0 9.7 0c1-.7 2.5-2.8 3-3.6L32 44.2l7.2 11.3c.5.8 2 2.9 3 3.5a8.7 8.7 0 0 0 9.7 0A8.9 8.9 0 0 0 54.4 47a8.7 8.7 0 0 0-7.3-4zM23.4 54.4c-.1.4-2 2.8-2.5 3a7 7 0 0 1-10.7-4.4 7 7 0 0 1 5.4-8.3 7 7 0 0 1 5.3 1l.3.3c.5-.5.9-1 1.2-1.6.4-.7.6-1.4.7-2.2a12 12 0 0 1 1.5-5L26 35l4.9 7.7-7.7 12zM51 57.6a7 7 0 0 1-7.7 0c-.5-.3-2.4-2.7-2.5-3L17.2 17.6a8.5 8.5 0 0 1-.9-5.9l.9-3.1 11.4 18a.9.9 0 0 0 1.5-1L17.9 6.5c.2-.3.5-.6.9-.6s.9.3 1.3 1l19.4 30.4a12 12 0 0 1 1.5 5c0 .8.3 1.5.7 2.2.3.6.7 1.1 1.2 1.6l.3-.3a7 7 0 0 1 9.7 2 7.1 7.1 0 0 1-2 9.8z"
    />
    <path
      fill={props.color}
      d="M47 46.9a4.8 4.8 0 1 0 0 9.7 4.8 4.8 0 0 0 0-9.7zm0 8a3 3 0 0 1-2.2-5.4 3 3 0 1 1 2.2 5.3zM32 30.6a2.6 2.6 0 1 0 0 5.3 2.6 2.6 0 0 0 0-5.3zm0 3.5a.9.9 0 1 1 0-1.8.9.9 0 0 1 0 1.8z"
    />
  </Icon>
);
