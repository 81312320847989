import { Box } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { GetGradientId } from "../icons/GradientDefsFakeGrave";

interface NormalGraveProps {
  frontColor: string;
  backColor: string;
}

export const NormalGrave: FunctionComponent<NormalGraveProps> = ({
  frontColor,
  backColor,
}: NormalGraveProps) => (
  <Box maxWidth="150px" position="absolute">
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="160">
      <path
        fill={`url(#${GetGradientId(frontColor)})`}
        d="M138.1 155.7V80s6.5-73.9-66.6-75.7C-1.7 6 4.8 80 4.8 80v75.7"
      />
      <path
        fill={frontColor}
        d="M147.5 155.7V80S154.1 6.1 81 4.3C7.7 6 14.3 80 14.3 80v75.7"
      />
      <path
        fill="none"
        stroke={backColor}
        strokeWidth=".5"
        strokeMiterlimit="10"
        d="M4.8 155.7h142.7"
      />
    </svg>
  </Box>
);
