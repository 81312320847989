import { Icon } from "@chakra-ui/react";
import React from "react";

export const ZoomInIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M53.5 11.6c-8-7.9-20.8-7.9-28.7 0C17.5 19 15 30.1 21.1 38L7.8 50.4a3.8 3.8 0 0 0-.1 5.5 3.8 3.8 0 0 0 5.6-.1L27 44c7.9 6.1 19.1 3.7 26.4-3.6 7.9-8 7.9-20.8 0-28.8zm-3.8 25a14.8 14.8 0 0 1-21 0 14.8 14.8 0 0 1 0-21 14.8 14.8 0 0 1 21 0 15 15 0 0 1 0 21z"
    />
    <path
      fill={props.color}
      d="M45.4 23.3h-3.6v-3.6c0-1.6-1.2-2.6-2.7-2.6-1.5 0-2.8 1.2-2.8 2.6v3.6h-3.5a2.7 2.7 0 0 0-2.7 2.7c0 1.6 1.2 2.8 2.7 2.8h3.5v3.6c0 1.4 1.3 2.7 2.9 2.7 1.4 0 2.6-1.3 2.6-2.7v-3.6h3.6c1.6 0 2.7-1.3 2.7-2.8 0-1.5-1.1-2.7-2.7-2.7z"
    />
  </Icon>
);
