import { Icon } from "@chakra-ui/react";
import React from "react";

export const TwitterIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="M59.7 16c-.4-.3-1-.3-1.3 0-.4.3-1 .6-1.7.8 2-2.3 2-3.8 2-4.2a1.1 1.1 0 0 0-1.8-.8c-2.4 1.7-4.7 2-6 2.1-2.3-2-5-3.2-8-3.2a11.8 11.8 0 0 0-11.6 13.4c-11-.2-20-10.7-20-10.8-.2-.3-.6-.4-1-.4-.3 0-.6.2-.8.6a11.7 11.7 0 0 0 .7 12.9l-.5-.3A1.1 1.1 0 0 0 8 27c0 5.1 2.4 8.1 4.7 9.8-.3 0-.5.2-.7.4-.2.3-.3.7-.2 1 1.6 5.4 5.5 7.3 8.1 8-5.2 3.9-12.8 2.6-13 2.6a1.1 1.1 0 0 0-.8 2A28.2 28.2 0 0 0 23.1 56c3.7 0 6.3-.6 6.5-.6a31.6 31.6 0 0 0 25.1-31.9c4.6-4.2 5.3-5.9 5.4-6.3.2-.4 0-1-.4-1.2zm-6.8 6.2c-.3.2-.4.6-.4 1 0 0 .4 6-2.3 12.7a28.2 28.2 0 0 1-21 17.3c-.2 0-9.8 2-18.2-2 3.6-.1 8.6-1.2 12.1-5.1a1.2 1.2 0 0 0-.8-2 9 9 0 0 1-7.8-4.9c1 0 2.1 0 3-.4a1.1 1.1 0 0 0-.2-2.1c-.2 0-6.1-1.4-7-7.8.9.4 2 .7 3.1.5a1.1 1.1 0 0 0 .6-1.9c-.3-.2-5.8-5.2-3.3-11.5 3 3.1 11.6 10.9 22 10.3a1.1 1.1 0 0 0 1-1.4l-.3-2.4c0-5.3 4.2-9.6 9.4-9.6 2.6 0 5 1 6.8 2.9.2.2.4.3.7.3 1 0 2.8 0 4.7-.8-.6.7-1.4 1.5-2.5 2.4a1.1 1.1 0 0 0 .8 2l2.6-.4-3 3z"
    />
  </Icon>
);
