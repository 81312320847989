import { Flex, VStack, Spacer, Text, Link, Box } from "@chakra-ui/react";
import Avatar from "boring-avatars";
import React, { FunctionComponent } from "react";
import { Transaction } from "../../global/interfaces";
import { AddGraveIcon } from "../../icons/AddGraveIcon";
import { AddPlotIcon } from "../../icons/AddPlotIcon";
import { BuryIcon } from "../../icons/BuryIcon";
import { CandleIcon } from "../../icons/CandleIcon";
import { CutBushIcon } from "../../icons/CutBushIcon";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";
import { LocationIcon } from "../../icons/LocationIcon";
import { MarketIcon } from "../../icons/MarketIcon";
import { SizeIcon } from "../../icons/SizeIcon";

interface TransactionBoxProps {
  transaction: Transaction;
}

const TransactionBox: FunctionComponent<TransactionBoxProps> = ({
  transaction,
}) => {
  const transactionTypeFlex = (transaction: Transaction) => {
    const { transactionType } = transaction;
    const iconSize = "40px";
    return (
      <Flex width={"100%"} align="center" minH={"80px"} p={4}>
        <Text fontSize={"md"}>
          {transactionType === "trimmed"
            ? "Plot trimmed."
            : transactionType === "candleLit"
            ? "Candle lit."
            : transactionType === "plotBought"
            ? "NFT bought."
            : transactionType === "gravestoneCreated"
            ? "Gravestone created."
            : transactionType === "plotListed"
            ? `Plot listed on market for $${transaction.price?.toFixed(2)}`
            : transactionType === "plotMinted"
            ? "New Necryptolis NFT created."
            : transactionType === "nftBuried"
            ? "NFT was buried."
            : "Unknown transaction."}
        </Text>
        <Spacer />
        {transactionType === "candleLit" && (
          <CandleIcon maxH={iconSize} maxW={iconSize} />
        )}
        {transactionType === "plotMinted" && (
          <AddPlotIcon
            maxH={iconSize}
            maxW={iconSize}
            color={"var(--chakra-colors-primary-900)"}
          />
        )}
        {transactionType === "gravestoneCreated" && (
          <AddGraveIcon
            maxH={iconSize}
            maxW={iconSize}
            color={"var(--chakra-colors-primary-900)"}
          />
        )}
        {transactionType === "plotBought" && (
          <MarketIcon
            maxH={iconSize}
            maxW={iconSize}
            color={"var(--chakra-colors-primary-900)"}
          />
        )}
        {transactionType === "plotListed" && (
          <MarketIcon
            maxH={iconSize}
            maxW={iconSize}
            color={"var(--chakra-colors-primary-900)"}
          />
        )}
        {transactionType === "trimmed" && (
          <CutBushIcon
            maxH={iconSize}
            maxW={iconSize}
            color={"var(--chakra-colors-primary-900)"}
          />
        )}
        {transactionType === "nftBuried" && (
          <BuryIcon
            maxH={iconSize}
            maxW={iconSize}
            color={"var(--chakra-colors-primary-900)"}
          />
        )}
      </Flex>
    );
  };

  return (
    <Flex w="full" alignItems="center" justifyContent="center" p={"2px"}>
      <VStack
        boxShadow="lg"
        bg={"primary.100"}
        rounded="lg"
        spacing={0}
        overflow="hidden"
        onClick={() => {
          window.location.href =
            window.location.href.split("?")[0] +
            `?x=${transaction.plotInfo.left}&y=${-transaction.plotInfo
              .top}&id=${transaction.plotInfo.id}`;
        }}
        _hover={{
          cursor: "pointer",
          boxShadow: "md",
        }}
        w="95%"
      >
        {transactionTypeFlex(transaction)}
        <Flex width={"100%"} align="center" px={4}>
          {transaction.initator && (
            <Box boxShadow="rgb(20 30 20 / 69%) 1px 3px 4px">
              <Avatar
                size={"30px"}
                name={transaction.initator}
                variant="beam"
                square
                colors={["#99c9b3", "#579c9a", "#3a7076", "#25343b"]}
              />
            </Box>
          )}
          <Text ml={1} fontSize={"sm"} color="primary.400">
            {transaction.initator}
          </Text>
          <Text
            fontSize={"sm"}
            textAlign="right"
            width={"100%"}
            color="primary.400"
          >
            {`${new Date(transaction.createdOn).toLocaleTimeString(
              "en-US"
            )}, ${new Date(transaction.createdOn)
              .toDateString()
              .substring(4, 10)}`}
          </Text>
        </Flex>

        <Flex width={"100%"} align="center" px={4} py={2}>
          <LocationIcon
            maxH="30px"
            maxW="30px"
            p={1}
            color={"var(--chakra-colors-primary-500)"}
          />
          <Text fontSize={"sm"} textAlign="right" color="primary.400">
            {`(${transaction.plotInfo.left}, ${transaction.plotInfo.top})`}
          </Text>
          <Spacer />
          <SizeIcon
            maxH="30px"
            maxW="30px"
            p={1}
            color={"var(--chakra-colors-primary-500)"}
          />
          <Text fontSize={"sm"} textAlign="right" color="primary.400">
            {`${transaction.plotInfo.width}px x ${transaction.plotInfo.height}px`}
          </Text>
          <Spacer />
          <Link
            px={1}
            color="primary.500"
            fontSize={"sm"}
            textDecoration="underline"
            href={`https://flow-view-source.com/${process.env["REACT_APP_CHAIN_ENV"]}/tx/${transaction.transactionId}`}
            isExternal
          >
            <ExternalLinkIcon
              color={"var(--chakra-colors-primary-500)"}
              width="20px"
              height="20px"
              mx="2px"
            />
          </Link>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default TransactionBox;
