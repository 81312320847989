import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { tx, TxOptions } from "./utils/tx";
import { BuryNFTRequestBody } from "../global/interfaces";

const BURY_FLOVATAR_CODE = fcl.cdc`
import Necryptolis from 0xNecryptolis
import NonFungibleToken from 0xNonFungibleToken
import Flovatar from 0xFlovatar

transaction(plotId: UInt64, nftId: UInt64) {    
    let necryptolisProvider: Capability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>
    let flovatarProvider: Capability<&Flovatar.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>

    let burialProvider: &Necryptolis.GravestoneManager{Necryptolis.BurialProvider}
    let plotID: UInt64

    prepare(account: AuthAccount) {
    self.plotID = plotId
        // We need a provider capability, but one is not provided by default so we create one if needed.
        let necryptolisProviderPrivatePath = /private/necryptolisCollectionProvider    
        let flovatarProviderPrivatePath = /private/flovatarCollectionProvider     

        if !account.getCapability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath)!.check() {
            account.link<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath, target: Necryptolis.CollectionStoragePath)
        }

        if !account.getCapability<&Flovatar.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flovatarProviderPrivatePath)!.check() {
            account.link<&Flovatar.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flovatarProviderPrivatePath, target: Flovatar.CollectionStoragePath)
        }

        self.necryptolisProvider = account.getCapability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath)!
        self.flovatarProvider = account.getCapability<&Flovatar.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flovatarProviderPrivatePath)!
        
        assert(self.necryptolisProvider.borrow() != nil, message: "Missing or mis-typed Necryptolis Collection provider")
        assert(self.flovatarProvider.borrow() != nil, message: "Missing or mis-typed Flovatar Collection provider")

        if account.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath) == nil {
          account.save(<-Necryptolis.createGravestoneManager(), to: Necryptolis.GravestoneManagerStoragePath)
        }

        self.burialProvider = account.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath)
            ?? panic("Missing or mis-typed GravestoneManager.")
    }

    execute {
        self.burialProvider.buryNFT(necryptolisProviderCapability: self.necryptolisProvider, plotID: self.plotID, nftProviderCapability: self.flovatarProvider, nftType: Type<@Flovatar.NFT>(), nftID: nftId)
    }
}
`;

const BURY_NBA_TOP_SHOT_CODE = fcl.cdc`
      import Necryptolis from 0xNecryptolis
      import NonFungibleToken from 0xNonFungibleToken
      import TopShot from 0xTopShot

      transaction(plotId: UInt64, nftId: UInt64) {    
          let necryptolisProvider: Capability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>
          let provider: Capability<&TopShot.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>

          let burialProvider: &Necryptolis.GravestoneManager{Necryptolis.BurialProvider}
          let plotID: UInt64

          prepare(account: AuthAccount) {
            self.plotID = plotId
            // We need a provider capability, but one is not provided by default so we create one if needed.
            let necryptolisProviderPrivatePath = /private/necryptolisCollectionProvider    
            let providerPrivatePath = /private/flovatarCollectionProvider   
            let momentsColectionStoragePath = /storage/MomentCollection  
    
            account.unlink(necryptolisProviderPrivatePath)
            account.link<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath, target: Necryptolis.CollectionStoragePath)
    
            if !account.getCapability<&TopShot.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(providerPrivatePath).check() {
                account.link<&TopShot.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(providerPrivatePath, target: momentsColectionStoragePath)
            }
    
            self.necryptolisProvider = account.getCapability<&Necryptolis.Collection{Necryptolis.NecryptolisCollectionPublic}>(necryptolisProviderPrivatePath)
            self.provider = account.getCapability<&TopShot.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(providerPrivatePath)
            
            assert(self.provider.borrow() != nil, message: "Missing or mis-typed given Collection provider")
            assert(self.necryptolisProvider.borrow() != nil, message: "Missing or mis-typed Necryptolis Collection provider")
    
            if account.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath) == nil {
              account.save(<-Necryptolis.createGravestoneManager(), to: Necryptolis.GravestoneManagerStoragePath)
            }
    
            self.burialProvider = account.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath)
                ?? panic("Missing or mis-typed GravestoneManager.")
        }

        execute {
          self.burialProvider.buryNFT(necryptolisProviderCapability: self.necryptolisProvider, plotID: self.plotID, nftProviderCapability: self.provider, nftType: Type<@TopShot.NFT>(), nftID: nftId)
        }
    }
`;

export function buryNFT(
  buryNFTRequestBody: BuryNFTRequestBody,
  opts: TxOptions = {}
) {
  
  if (buryNFTRequestBody.externalNftPlatform === "Flovatar") {
    return tx(
      [
        fcl.transaction(BURY_FLOVATAR_CODE),
        fcl.args([
          fcl.arg(buryNFTRequestBody.plotId, t.UInt64),
          fcl.arg(buryNFTRequestBody.externalNftId, t.UInt64),
        ]),
        fcl.proposer(fcl.authz),
        fcl.payer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(1000),
      ],
      opts
    );
  }
  if (buryNFTRequestBody.externalNftPlatform === "NBA Top Shot") {
    return tx(
      [
        fcl.transaction(BURY_NBA_TOP_SHOT_CODE),
        fcl.args([
          fcl.arg(buryNFTRequestBody.plotId, t.UInt64),
          fcl.arg(buryNFTRequestBody.externalNftId, t.UInt64),
        ]),
        fcl.proposer(fcl.authz),
        fcl.payer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(1000),
      ],
      opts
    );
  }
}
