import { Box, Flex, HStack, VStack } from "@chakra-ui/layout";
import { Button, IconButton, Text } from "@chakra-ui/react";
import Avatar from "boring-avatars";
import React from "react";
import { useMessages } from "../../hooks/use-messages.hook";
import AddressGraveIcon from "../../icons/AddressGraveIcon";
import { InboxIcon } from "../../icons/InboxIcon";
import InboxCountBadge from "./InboxCountBadge";

interface Props {
  logout: () => any;
  address?: string;
  closeModal: () => any;
  onInboxOpen: () => any;
}

const handleClick = (
  logoutCallback: () => any,
  closeModalCallback: () => any
) => {
  logoutCallback();
  closeModalCallback();
};

export const AccountInfoHeader = (props: Props) => {
  const { logout, address, closeModal, onInboxOpen } = props;
  const { messages } = useMessages(address);
  const unreadMessagesCount = messages.filter(m => !m.isRead).length
  return (
    <Flex px={4} pb={4} grow="inherit">
      <VStack>
        <Box ml={4} boxShadow="rgb(20 30 20 / 99%) 0px 5px 8px">
          <Avatar
            size={"100px"}
            name={address}
            variant="beam"
            square
            colors={["#99c9b3", "#579c9a", "#3a7076", "#25343b"]}
          />
        </Box>
      </VStack>
      <VStack spacing={4} mx={3} pr={8}>
        <Flex>
          <AddressGraveIcon address={address} />
          <Flex mt={2}>
            <Text fontWeight="light" fontSize={"md"} pt={1}>
              {address?.substring(2)}
            </Text>
          </Flex>
        </Flex>
        <HStack width="100%" px={2}>
          <Button
            variant="ghost"
            onClick={() => handleClick(logout, closeModal)}
            fontWeight="normal"
          >
            Logout
          </Button>
          <IconButton
            variant="ghost"
            onClick={() => onInboxOpen()}
            aria-label={"Inbox"}
            borderColor={unreadMessagesCount > 0 ? "var(--chakra-colors-salmonBright)" : "var(--chakra-colors-primary-100)"}
            bg={unreadMessagesCount > 0 ? "primary.600" : "primary.800"}
            px={"12px !important"}
            icon={<>
              <InboxIcon
                height="32px"
                maxW={"32px"}
                color={unreadMessagesCount > 0 ? "var(--chakra-colors-salmonBright)" : "var(--chakra-colors-primary-100)"}
                animation={unreadMessagesCount > 0 ? "shake 3.5s ease-in-out infinite;" : ""}
              />
              <InboxCountBadge address={address} topPx={"0px"} leftPx={"32px"} />
            </>}
          >
          </IconButton>
        </HStack>
      </VStack>
    </Flex>
  );
};
