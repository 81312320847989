import * as fcl from "@onflow/fcl";

const noop = async () => {};

export interface TxOptions {
  onStart?: () => any;
  onSubmission?: (param: any) => any;
  onUpdate?: () => any;
  onSuccess?: (param: any) => any;
  onError?: (param: any) => any;
  onComplete?: () => any;
}

export async function tx(mods = [] as any, opts = {} as TxOptions) {
  const onStart = opts.onStart || noop;
  const onSubmission = opts.onSubmission || noop;
  const onUpdate = opts.onUpdate || noop;
  const onSuccess = opts.onSuccess || noop;
  const onError = opts.onError || noop;
  const onComplete = opts.onComplete || noop;

  try {
    onStart();
    const txId: any = await fcl.send(mods).then(fcl.decode);

    onSubmission(txId);
    var unsub = await fcl.tx(txId).subscribe(onUpdate);
    var txStatus = await fcl.tx(txId).onceSealed();
    unsub();
    console.info(
      `%cTX[${txId}]: ${fvsTx(await fcl.config().get("env"), txId)}`,
      "color:green;font-weight:bold;font-family:monospace;"
    );
    await onSuccess(txStatus);
    return txStatus;
  } catch (error) {
    // console.error(`Error on transaction tx.`, error);
    onError(error);
  } finally {
    await onComplete();
  }
}

function fvsTx(env: string, txId: string) {
  return `https://flow-view-source.com/${env}/tx/${txId}`;
}
