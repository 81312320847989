import { Icon } from "@chakra-ui/react";
import React from "react";

export const TransactionIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <g>
      <path
        fill={props.color}
        d="M47.309,36.261c-0.974,0-1.936-0.234-2.81-0.688c-1.436-0.729-2.542-2.019-3.085-3.592H26.179
		c-0.561,0-1.098-0.233-1.495-0.65c-0.396-0.416-0.619-0.98-0.619-1.568c0-3.53,1.336-6.915,3.713-9.411s5.602-3.898,8.965-3.898
		h4.226c0.046-1.646,0.672-3.216,1.757-4.406c1.084-1.189,2.551-1.915,4.115-2.036c1.564-0.12,3.116,0.373,4.354,1.384l7.902,6.455
		c1.547,1.261,2.452,3.201,2.452,5.257c0,2.056-0.905,3.997-2.452,5.257l-7.902,6.456C50.082,35.729,48.719,36.233,47.309,36.261
		L47.309,36.261z M28.63,27.543h14.452c0.561,0,1.099,0.234,1.494,0.65c0.396,0.416,0.62,0.98,0.62,1.568
		c-0.006,0.382,0.093,0.759,0.284,1.084c0.19,0.327,0.466,0.588,0.793,0.757c0.375,0.184,0.79,0.255,1.201,0.204
		s0.8-0.221,1.124-0.492l7.923-6.455c0.516-0.42,0.817-1.067,0.817-1.752c0-0.686-0.302-1.333-0.817-1.753l-7.923-6.454
		c-0.42-0.339-0.943-0.5-1.47-0.453c-0.525,0.047-1.017,0.3-1.375,0.708c-0.356,0.408-0.557,0.942-0.558,1.497v2.019
		c0,0.587-0.224,1.152-0.62,1.567c-0.396,0.417-0.934,0.651-1.494,0.651h-6.338c-1.877-0.004-3.701,0.648-5.185,1.853
		c-1.484,1.205-2.544,2.894-3.014,4.801H28.63z"
      />
      <path
        fill={props.color}
        d="M17.177,58.444c-1.41-0.006-2.778-0.506-3.888-1.421l-7.903-6.455c-1.539-1.262-2.438-3.195-2.438-5.246
		c0-2.049,0.899-3.985,2.438-5.245l7.903-6.457c0.942-0.795,2.082-1.289,3.283-1.426c1.202-0.139,2.417,0.089,3.5,0.651
		c1.446,0.726,2.556,2.027,3.085,3.614h15.15l0,0c0.56,0,1.098,0.235,1.494,0.65c0.396,0.416,0.619,0.979,0.619,1.569
		c0,3.529-1.336,6.916-3.714,9.41c-2.377,2.496-5.602,3.899-8.964,3.899h-4.227c-0.036,1.21-0.386,2.386-1.012,3.401
		c-0.625,1.017-1.502,1.835-2.538,2.367C19.101,58.211,18.146,58.446,17.177,58.444L17.177,58.444z M17.177,36.593
		c-0.509,0.004-1.001,0.192-1.395,0.532l-7.797,6.456c-0.508,0.42-0.804,1.061-0.804,1.741c0,0.68,0.296,1.32,0.804,1.741
		l7.902,6.454l0,0c0.417,0.337,0.938,0.499,1.463,0.454c0.524-0.045,1.013-0.294,1.372-0.698c0.358-0.402,0.561-0.932,0.567-1.486
		V49.77c0-0.588,0.223-1.152,0.619-1.568s0.934-0.649,1.494-0.649h6.339c1.873-0.001,3.692-0.656,5.171-1.861
		c1.48-1.204,2.537-2.892,3.006-4.795H21.403c-0.56,0-1.098-0.232-1.494-0.648c-0.396-0.416-0.619-0.98-0.619-1.569
		c0.016-0.384-0.078-0.766-0.27-1.093c-0.192-0.329-0.473-0.588-0.808-0.748c-0.288-0.155-0.606-0.239-0.93-0.244H17.177z"
      />
    </g>
  </Icon>
);
