import { Text, Center, VStack, HStack, Divider } from "@chakra-ui/layout";
import {
  IconButton,
  Stat,
  StatHelpText,
  StatLabel,
  Box,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { FunctionComponent } from "react";
import { BuriedNft, GraveData, NftListing } from "../../global/interfaces";
import { getFontFamily } from "../../global/utils";
import { AddGraveIcon } from "../../icons/AddGraveIcon";
import { BuryIcon } from "../../icons/BuryIcon";
import { CandleIcon } from "../../icons/CandleIcon";
import { CutBushIcon } from "../../icons/CutBushIcon";
import { MarketIcon } from "../../icons/MarketIcon";

interface GraveDisplayProps {
  gravestone?: GraveData;
  isUserOwner: boolean;
  buriedNft?: BuriedNft;
  listing?: NftListing;
  handleLightCandleClick: () => void;
  handleTrimGraveClick: () => void;
  handleBuryNFTClick: () => void;
  handleOpenMarketClick: () => void;
}

const GraveDisplay: FunctionComponent<GraveDisplayProps> = ({
  gravestone,
  isUserOwner,
  buriedNft,
  listing,
  handleLightCandleClick,
  handleTrimGraveClick,
  handleBuryNFTClick,
  handleOpenMarketClick,
}: GraveDisplayProps) => {
  if (!gravestone || !gravestone.name) {
    return (
      <Box py={4}>
        <Center px={4} border="1px dashed" borderColor="primary.600" h="200px">
          <Text>No gravestone yet.</Text>
        </Center>
      </Box>
    );
  }

  const dateCreatedSplit = gravestone.dateCreated.toDateString().split(" ");
  return (
    <VStack m={4} px={8} spacing={8} pt={"20px !important"}>
      <Text px={8} textAlign="center" fontWeight={600} fontSize={"x-large"}>
        {gravestone.name}
      </Text>

      <HStack height="70px" m={2} mb={6} spacing={4}>
        <Stat minW="120px" height="100%" textAlign="right">
          <StatHelpText fontWeight={600}>From - to:</StatHelpText>
          <StatLabel>{gravestone?.fromDate}</StatLabel>
          <StatHelpText>{gravestone?.toDate}</StatHelpText>
        </Stat>
        <Divider orientation="vertical" borderColor="primary.900" />
        <Stat height="100%" minW="120px" textAlign="left">
          <StatLabel fontWeight={900} textAlign="center">
            Created On:
          </StatLabel>
          <StatHelpText
            pt={2}
            textAlign="center"
            fontSize={"md"}
          >{`${dateCreatedSplit[2]} ${dateCreatedSplit[1]} ${dateCreatedSplit[3]}`}</StatHelpText>
        </Stat>
        <Divider orientation="vertical" borderColor="primary.900" />
        <Stat minW="120px" height="100%" textAlign="left">
          <StatLabel fontWeight={600}>Font:</StatLabel>
          <StatHelpText
            fontSize="2xl"
            fontFamily={getFontFamily(gravestone.font)}
          >
            {gravestone.font}
          </StatHelpText>
        </Stat>
      </HStack>
      <HStack spacing={6} pb={4}>
        <Tooltip label="Light a candle">
          <IconButton
            aria-label="Light a candle"
            variant="grave"
            height="70px"
            width="70px"
            onClick={handleLightCandleClick}
            icon={
              <CandleIcon
                height="50px"
                maxW={"50px"}
                color={"var(--chakra-colors-primary-100)"}
              />
            }
          ></IconButton>
        </Tooltip>
        <Tooltip label="Trim this grave">
          <IconButton
            aria-label="Trim this grave"
            variant="grave"
            height="70px"
            width="70px"
            onClick={handleTrimGraveClick}
            icon={
              <CutBushIcon
                height="50px"
                maxW={"50px"}
                color={"var(--chakra-colors-primary-100)"}
              />
            }
          ></IconButton>
        </Tooltip>
        {isUserOwner && !buriedNft && (
          <Tooltip label="Bury an NFT">
            <IconButton
              aria-label="Bury an NFT"
              variant="grave"
              height="70px"
              width="70px"
              onClick={handleBuryNFTClick}
              icon={
                <BuryIcon
                  height="50px"
                  maxW={"50px"}
                  color={"var(--chakra-colors-primary-100)"}
                />
              }
            ></IconButton>
          </Tooltip>
        )}
        {isUserOwner && !listing && (
          <Tooltip label="List on market">
            <IconButton
              aria-label="Add grave"
              variant="grave"
              icon={
                <MarketIcon
                  maxH="50px"
                  maxW="50px"
                  color="var(--chakra-colors-primary-100)"
                />
              }
              height="70px"
              width={"70px"}
              onClick={handleOpenMarketClick}
            ></IconButton>
          </Tooltip>
        )}
      </HStack>
    </VStack>
  );
};

interface GravestonePanelProps {
  isUserOwner: boolean;
  gravestone?: GraveData;
  buriedNft?: BuriedNft;
  listing?: NftListing;
  closeCemeteryPlotModal: () => void;
  openAddGravestoneModal: () => void;
  openLightCandleModal: () => void;
  openTrimGraveModal: () => void;
  openMarketPlotModal: () => void;
  openBuryNftModal: () => void;
}

const GravestonePanel: FunctionComponent<GravestonePanelProps> = ({
  gravestone,
  isUserOwner,
  buriedNft,
  listing,
  closeCemeteryPlotModal,
  openAddGravestoneModal,
  openLightCandleModal,
  openTrimGraveModal,
  openMarketPlotModal,
  openBuryNftModal,
}: GravestonePanelProps) => {
  const handleCreateGravestoneClick = () => {
    closeCemeteryPlotModal();
    openAddGravestoneModal();
  };
  const handleMarketClick = () => {
    closeCemeteryPlotModal();
    openMarketPlotModal();
  };
  const handleLightCandleClick = () => {
    closeCemeteryPlotModal();
    openLightCandleModal();
  };
  const handleTrimGraveClick = () => {
    closeCemeteryPlotModal();
    openTrimGraveModal();
  };
  const handleBuryNFTClick = () => {
    closeCemeteryPlotModal();
    openBuryNftModal();
  };

  if (!isUserOwner || gravestone?.name) {
    return (
      <GraveDisplay
        isUserOwner={isUserOwner}
        buriedNft={buriedNft}
        gravestone={gravestone}
        listing={listing}
        handleLightCandleClick={handleLightCandleClick}
        handleTrimGraveClick={handleTrimGraveClick}
        handleBuryNFTClick={handleBuryNFTClick}
        handleOpenMarketClick={handleMarketClick}
      />
    );
  }
  if (listing) {
    return <></>;
  }

  return (
    <VStack my={"30px !important"}>
      <HStack px={8} pb={4}>
        <Icon />
        <Text fontSize={"md"} align="center">
          You can use your cemetery plot to create a gravestone on it or you can
          put it on the market for someone else to buy it.
        </Text>
      </HStack>
      <HStack spacing={16}>
        <Tooltip label="Add a gravestone">
          <IconButton
            aria-label="Add grave"
            variant="grave"
            icon={
              <AddGraveIcon
                maxH="52px"
                maxW="52px"
                color="var(--chakra-colors-primary-100)"
              />
            }
            height="70px"
            width={"80px"}
            onClick={handleCreateGravestoneClick}
          ></IconButton>
        </Tooltip>
        <Tooltip label="List on market">
          <IconButton
            aria-label="Add grave"
            variant="grave"
            icon={
              <MarketIcon
                maxH="50px"
                maxW="50px"
                color="var(--chakra-colors-primary-100)"
              />
            }
            height="70px"
            width={"80px"}
            onClick={handleMarketClick}
          ></IconButton>
        </Tooltip>
      </HStack>
    </VStack>
  );
};

export default GravestonePanel;
