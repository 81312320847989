import { HStack, VStack, Text, Image } from "@chakra-ui/react";
import React, { Suspense } from "react";
import FlowImage from "../../assets/flow_logo.png";
import PlantsImage from "../../assets/plants.png";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import NecryptolisImage from "../../assets/necryptolis logo.svg";
import { NecryptolisCircleLogo } from "../../icons/NecryptolisCircleLogo";

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
}

const AboutModal: FunctionComponent<AboutModalProps> = ({
  onBack,
  isOpen,
  onClose,
}: AboutModalProps) => {
  return (
    <ThreeStripedModal
      goBackCallback={onBack}
      isOpen={isOpen}
      onClose={onClose}
      modalIcon={
        <NecryptolisCircleLogo
          maxH="64px"
          maxW="64px"
          p={1}
          color={"var(--chakra-colors-primary-900)"}
        />
      }
      title={"About Necryptolis"}
      topContent={
        <VStack px={6} pb={6}>
          <HStack
            width="100%"
            border="1px solid var(--chakra-colors-primary-100)"
          >
            <Image
              minWidth="80px"
              borderRight="1px solid var(--chakra-colors-primary-100)"
              height="100%"
              p={2}
              src={FlowImage}
            />

            <Text fontSize={"md"}>
              Built on Flow blockhain. Performance-optimized, reliable and
              proven network.
            </Text>
          </HStack>
          <HStack
            width="100%"
            border="1px solid var(--chakra-colors-primary-100)"
          >
            <Image
              minWidth="80px"
              borderRight="1px solid var(--chakra-colors-primary-100)"
              height="100%"
              p={2}
              src={PlantsImage}
            />

            <Text fontSize={"md"}>
              An eco-friendly platform, where minting takes less energy than a
              Google search.
            </Text>
          </HStack>
        </VStack>
      }
      midContent={
        <VStack>
          <Image
            pt={8}
            width="50%"
            alt="Necryptolis logo"
            src={NecryptolisImage}
          />
          <Text fontSize={"md"} p={6} pb={0} textAlign="center">
            NFT platform designed to embrace and explore the nature of
            blockchain technology and NFTs. A digital graveyard where one can
            create and trade parcels, design gravestones, devise meaningful
            epitaphs, bury other NFTs and more.
          </Text>
        </VStack>
      }
      bottomContent={
        <VStack p={4} spacing={4}>
          <Text pt={4} fontSize={"sm"}>
            © 2022 BRANE Labs
          </Text>
        </VStack>
      }
    />
  );
};

const WrappedAboutModal = (props: AboutModalProps) => {
  return (
    <Suspense fallback={<></>}>
      <AboutModal {...props} />
    </Suspense>
  );
};

export default WrappedAboutModal;
