import { useDisclosure } from "@chakra-ui/react";
import React, { FunctionComponent, MutableRefObject, Suspense, useState } from "react";
import { CemeteryPlotData, Coordinates } from "../../global/interfaces";
import { serializeCoordinates } from "../../global/utils";
import { usePlotDatas } from "../../hooks/use-plot-datas.hook";
import { useScrollDebounce } from "../../hooks/use-scroll-debounce.hook";
import { GradientDefsFakeGrave } from "../../icons/GradientDefsFakeGrave";
import BuryNFTModal from "./BuryNFTModal";
import { CemeteryPlot } from "./CemeteryPlot";
import CemeteryPlotInfo from "./CemeteryPlotInfo";
import CreateGravestoneModal from "./CreateGravestoneModal";
import LightCandleModal from "./LightCandleModal";
import MarketPlotModal from "./MarketPlotModal";
import TrimGraveModal from "./TrimGraveModal";

interface CemeteryPlotsClusterProps {
  updatePlotsFuncRef: any;
  topLeftCoordinatesRef: MutableRefObject<Coordinates>;
  selectedPlotId: string | null;
}

const CemeteryPlotsCluster: FunctionComponent<CemeteryPlotsClusterProps> = ({
  updatePlotsFuncRef,
  topLeftCoordinatesRef,
  selectedPlotId,
}: CemeteryPlotsClusterProps) => {
  const [screenCoordinates, setScreenCoordinates] = useScrollDebounce();
  const [setCurrentScreenCoordinates, plots] = usePlotDatas();
  const [cemeteryPlot, setCemeteryPlot] = useState<CemeteryPlotData>({
    id: 0,
    candles: [],
    grave: undefined,
    owner: "",
    plot: { height: 0, left: 0, top: 0, width: 0 },
    dateCreated: new Date(),
    lastTrimDate: new Date(),
  });

  React.useEffect(() => {
    updatePlotsFuncRef.current = updatePlots;
  });

  React.useEffect(() => {
    if (screenCoordinates) {
      setCurrentScreenCoordinates(serializeCoordinates(screenCoordinates));
    }
  }, [screenCoordinates, setCurrentScreenCoordinates]);

  const updatePlots = (x1: number, y1: number, x2: number, y2: number) => {
    setScreenCoordinates({
      topLeft: { x: x1, y: y1 },
      bottomRight: { x: x2, y: y2 },
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCreateGravestoneOpened,
    onOpen: onCreateGravestoneOpen,
    onClose: onCreateGravestoneClose,
  } = useDisclosure();
  const {
    isOpen: isMarketPlotOpened,
    onOpen: onMarketPlotOpen,
    onClose: onMarketPlotClose,
  } = useDisclosure();
  const {
    isOpen: isLightCandleOpened,
    onOpen: onLightCandleOpen,
    onClose: onLightCandleClose,
  } = useDisclosure();

  const {
    isOpen: isTrimGraveOpened,
    onOpen: onTrimGraveOpen,
    onClose: onTrimGraveClose,
  } = useDisclosure();

  const {
    isOpen: isBuryNFTOpened,
    onOpen: onBuryNFTOpen,
    onClose: onBuryNFTClose,
  } = useDisclosure();
  const handleBackToCemeteryPlotInfo = () => {
    onTrimGraveClose();
    onLightCandleClose();
    onBuryNFTClose();
    onOpen();
  };

  return (
    <>
      <CemeteryPlotInfo
        isPlotInfoOpen={isOpen}
        onPlotInfoClose={onClose}
        cemeteryPlot={cemeteryPlot}
        onCreateGravestoneOpen={onCreateGravestoneOpen}
        onMarketPlotOpen={onMarketPlotOpen}
        onLightCandleOpen={onLightCandleOpen}
        onTrimGraveOpen={onTrimGraveOpen}
        onBuryNFTOpen={onBuryNFTOpen}
      />

      <CreateGravestoneModal
        plotInfo={cemeteryPlot.plot}
        plotId={cemeteryPlot.id}
        isOpen={isCreateGravestoneOpened}
        onClose={onCreateGravestoneClose}
        onBack={handleBackToCemeteryPlotInfo}
      />

      <MarketPlotModal
        plotInfo={cemeteryPlot.plot}
        plotId={cemeteryPlot.id}
        isOpen={isMarketPlotOpened}
        onClose={onMarketPlotClose}
        onBack={handleBackToCemeteryPlotInfo}
      />

      <LightCandleModal
        isOpen={isLightCandleOpened}
        onClose={onLightCandleClose}
        graveId={cemeteryPlot.id}
        graveOwnerAddress={cemeteryPlot.owner}
        candles={cemeteryPlot.candles}
        onBack={handleBackToCemeteryPlotInfo}
      />
      <TrimGraveModal
        isOpen={isTrimGraveOpened}
        onClose={onTrimGraveClose}
        graveId={cemeteryPlot.id}
        graveOwnerAddress={cemeteryPlot.owner}
        lastTrimmed={cemeteryPlot.lastTrimDate.toDateString().substring(4)}
        onBack={handleBackToCemeteryPlotInfo}
      />
      <BuryNFTModal
        isOpen={isBuryNFTOpened}
        onClose={onBuryNFTClose}
        graveId={cemeteryPlot.id}
        graveOwnerAddress={cemeteryPlot.owner}
        onBack={handleBackToCemeteryPlotInfo}
      />
      <GradientDefsFakeGrave />
      {plots
        .filter((p) => p.plot.left !== 0 || p.plot.top !== 0)
        .map((p) => (
          <CemeteryPlot
            isHighlightedBorder={selectedPlotId === p.id.toString()}
            key={p.id}
            cemeteryPlot={p}
            topLeftCoordinatesRef={topLeftCoordinatesRef}
            setCemeteryPlot={setCemeteryPlot}
            onCemeteryPlotInfoOpen={onOpen} />
        ))}
    </>
  );
};

const WrappedCemeteryPlotsCluster = ({
  updatePlotsFuncRef,
  topLeftCoordinatesRef,
  selectedPlotId,
}: CemeteryPlotsClusterProps) => {
  return (
    <Suspense fallback={<></>}>
      <CemeteryPlotsCluster
        selectedPlotId={selectedPlotId}
        updatePlotsFuncRef={updatePlotsFuncRef}
        topLeftCoordinatesRef={topLeftCoordinatesRef}
      />
    </Suspense>
  );
};

export default WrappedCemeteryPlotsCluster;
