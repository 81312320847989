import {
  Checkbox,
  Divider,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
} from "@chakra-ui/react";
import React, { Suspense, useState } from "react";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { createGravestone } from "../../flow/create-gravestone";
import { CreateGraveRequestBody, Rect } from "../../global/interfaces";
import { AddGraveIcon } from "../../icons/AddGraveIcon";
import CreateGravestoneForm from "./CreateGravestoneForm";

interface CreateGravestoneModalProps {
  isOpen: boolean;
  onClose: () => void;
  plotInfo: Rect;
  plotId: number;
  onBack: () => void;
}

const CreateGravestoneModal: FunctionComponent<CreateGravestoneModalProps> = ({
  isOpen,
  onClose,
  onBack,
  plotInfo,
  plotId,
}: CreateGravestoneModalProps) => {
  const [createGravestoneRequestBody, setCreateGravestoneRequestBody] =
    useState<CreateGraveRequestBody>();
  const [metadata, setMetadata] =
    useState<{ [key: string]: string | undefined }>();
  const [checkboxSelected, setCheckboxSelected] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  return (
    <ThreeStripedModal
      goBackCallback={onBack}
      isOpen={isOpen}
      onClose={onClose}
      modalIcon={
        <AddGraveIcon
          maxH="64px"
          maxW="64px"
          p={1}
          color={"var(--chakra-colors-primary-900)"}
        />
      }
      title={"Create Gravestone"}
      topContent={
        <HStack height="50px" m={2} mb={6} spacing={4}>
          <Stat minW="120px" textAlign="right">
            <StatLabel>Dimension</StatLabel>
            <StatNumber>
              {plotInfo?.width} x {plotInfo?.height}
            </StatNumber>
          </Stat>
          <Divider orientation="vertical" borderColor="primary.100" />
          <Stat minW="120px" textAlign="left">
            <StatLabel>Location</StatLabel>

            <StatNumber>
              ({plotInfo?.left}, {-plotInfo?.top})
            </StatNumber>
          </Stat>
        </HStack>
      }
      midContent={
        <CreateGravestoneForm
          setCreateGravestoneRequestBody={setCreateGravestoneRequestBody}
          setMetadata={setMetadata}
          setIsFormValid={setIsFormValid}
          plotHeight={plotInfo.height}
          plotWidth={plotInfo.width}
        />
      }
      bottomContent={
        <VStack p={4} spacing={4}>
          <Checkbox
            spacing={"6px"}
            colorScheme="blackAlpha"
            borderColor="primary.900"
            textAlign="center"
            iconSize="3rem"
            iconColor="primary.100"
            isChecked={checkboxSelected}
            onChange={(e) => setCheckboxSelected(e.target.checked)}
          >
            I understand that once the gravestone has been created it cannot be
            changed
          </Checkbox>
          <TriggerTransactionButton
            transactionRequestObject={
              {
                ...createGravestoneRequestBody,
                plotId,
                metadata,
              } as CreateGraveRequestBody
            }
            disabled={!checkboxSelected || !isFormValid}
            doTransaction={createGravestone}
            buttonText={"Create Gravestone"}
            onSuccessCallback={onClose}
            onSuccessMessage={
              "Your gravestone is being minted. Check in a few minutes."
            }
          />
        </VStack>
      }
    />
  );
};

const WrappedCreateGravestoneModal = (props: CreateGravestoneModalProps) => {
  return (
    <Suspense fallback={<></>}>
      <CreateGravestoneModal {...props} />
    </Suspense>
  );
};

export default WrappedCreateGravestoneModal;
