import {
  flowerColorDeviation,
  flowerSize,
  flowerSizeDeviation,
  upperLimit,
} from "./LianaSettings";

class Flower {
  x: number;
  y: number;
  size: number;
  colors: [any];
  rotation: number;

  constructor(x: number, y: number, size: number, baseColor: any, p5: any) {
    this.x = x;
    this.y = y;
    this.size = size;
    this.colors = [baseColor];
    for (let i = 0; i < 3; i++) {
      this.colors.push(
        p5.color(
          p5.red(baseColor) - 40,
          p5.green(baseColor) - 40,
          p5.blue(baseColor) - 40
        )
      );
      this.colors.push(
        p5.color(
          p5.red(baseColor) - 80,
          p5.green(baseColor) - 80,
          p5.blue(baseColor) - 80
        )
      );
    }
    this.rotation = p5.random(0, 360);
  }

  draw(p5: any) {
    // Draw the flower
    let size = upperLimit((this.size * 0.5) / 0.5, this.size);

    p5.noStroke();
    p5.push();

    p5.translate(this.x, this.y);
    p5.rotate(this.rotation * (Math.PI / 180));
    for (let i = 0; i < this.colors.length; i++) {
      p5.fill(this.colors[i]);
      drawPentagon(0, 0, size - size * 0.05 * i, p5);
      p5.rotate(20 * (Math.PI / 180));
    }
    p5.pop();
  }
}

const drawPentagon = (x: number, y: number, d: number, p5: any) => {
  p5.beginShape();
  p5.vertex(x, y - 0.5 * d);
  p5.vertex(x + 0.476 * d, y - 0.155 * d);
  p5.vertex(x + 0.294 * d, y + 0.405 * d);
  p5.vertex(x - 0.294 * d, y + 0.405 * d);
  p5.vertex(x - 0.476 * d, y - 0.155 * d);
  p5.endShape(p5.CLOSE);
};

export const randomFlowerSize = (p5: any) => {
  return (
    flowerSize +
    flowerSize * p5.random(-flowerSizeDeviation, flowerSizeDeviation)
  );
};

export const randomFlowerColor = (p5: any, flowerColor: string) => {
  let colorDeviation = p5.random(-flowerColorDeviation, flowerColorDeviation);

  return p5.color(
    p5.red(flowerColor) + colorDeviation,
    p5.green(flowerColor) + colorDeviation,
    p5.blue(flowerColor) + colorDeviation
  );
};

export { Flower };
