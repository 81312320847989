import { chakra } from "@chakra-ui/react";
import React, { FunctionComponent, Suspense } from "react";
import { useMessages } from "../../hooks/use-messages.hook";

interface InboxCountBadgeProps {
    address?: string,
    isAnimated?: boolean,
    leftPx?: string,
    topPx?: string,
}

const InboxCountBadge: FunctionComponent<InboxCountBadgeProps> = ({ isAnimated, address, topPx, leftPx }) => {
    const { messages } = useMessages(address);
    const unreadMessagesCount = messages.filter(m => !m.isRead).length
    return (address && unreadMessagesCount ? <chakra.span
        pos="absolute"
        top={topPx ? topPx : "10px"}
        left={leftPx ? leftPx : "-15px"}
        zIndex={3000}
        px={2}
        py={1}
        fontSize="small"
        fontWeight="bold"
        lineHeight="none"
        borderWidth={"1px"}
        borderColor="salmonDark"
        color="primary.800"
        transform="translate(50%,-50%)"
        bg="salmonBright"
        rounded="full"
        animation={isAnimated ? "shine 1.5s ease-in-out infinite;" : ""}
    >
        {unreadMessagesCount < 10 ? unreadMessagesCount.toString() : "9+"}
    </chakra.span> : <></>);
}

const WrappedInboxCountBadge = (props: InboxCountBadgeProps) => {
    return (
        <Suspense
            fallback={
                <></>
            }
        >
            <InboxCountBadge {...props} />
        </Suspense>
    );
};

export default WrappedInboxCountBadge;