import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { Text, Center, HStack, VStack } from "@chakra-ui/layout";
import { FormLabel, Textarea } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { SelectIconButton } from "../../components/SelectIconButton";
import { GraveFont } from "../../global/interfaces";
import { getFontFamily } from "../../global/utils";

interface GravestoneTitleTabProps {
  title: string;
  setGraveTitle: (title: string) => void;
  font: GraveFont;
  setGraveFont: (font: GraveFont) => void;
}

const GravestoneTitleTab: FunctionComponent<GravestoneTitleTabProps> = ({
  title,
  setGraveTitle,
  font,
  setGraveFont,
}: GravestoneTitleTabProps) => {
  return (
    <VStack>
      <FormControl id="shape">
        <FormHelperText textColor="primary.100" textAlign="center">
          Select font
        </FormHelperText>
        <Center>
          <HStack my={2}>
            {SelectIconButton(
              <Text fontFamily={getFontFamily("roman")}>Ro</Text>,
              () => setGraveFont("roman"),
              font === "roman"
            )}
            {SelectIconButton(
              <Text fontFamily={getFontFamily("optima")}>Op</Text>,
              () => setGraveFont("optima"),
              font === "optima"
            )}
            {SelectIconButton(
              <Text fontFamily={getFontFamily("zapf")}>Za</Text>,
              () => setGraveFont("zapf"),
              font === "zapf"
            )}
            {SelectIconButton(
              <Text fontFamily={getFontFamily("goth")}>Go</Text>,
              () => setGraveFont("goth"),
              font === "goth"
            )}
          </HStack>
        </Center>
      </FormControl>
      <FormControl id="title" pb={4}>
        <FormLabel textAlign="center" mb={0}>
          Title
        </FormLabel>
        <Textarea
          borderColor="primary.100"
          colorScheme="primary"
          placeholder="Type title here..."
          focusBorderColor="primary.300"
          rows={3}
          onChange={(e) => setGraveTitle(e.target.value)}
        />
      </FormControl>
    </VStack>
  );
};

export default GravestoneTitleTab;
