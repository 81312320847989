import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { Suspense } from "react";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { lightCandle } from "../../flow/light-candle";
import { CandleBoughtInfo } from "../../global/interfaces";
import { useBalance } from "../../hooks/use-balance.hook";
import { usePlotSalesInfo } from "../../hooks/use-plot-sales-info.hook";
import { CandleIcon } from "../../icons/CandleIcon";

interface LightCandleModalProps {
  graveId: number;
  graveOwnerAddress: string;
  isOpen: boolean;
  onClose: () => void;
  candles: CandleBoughtInfo[];
  onBack?: () => void;
}

const LightCandleModal: FunctionComponent<LightCandleModalProps> = ({
  graveId,
  graveOwnerAddress,
  isOpen,
  onClose,
  candles,
  onBack,
}: LightCandleModalProps) => {
  const { balance } = useBalance();
  const { candlePrice } = usePlotSalesInfo();

  return (
    <ThreeStripedModal
      isOpen={isOpen}
      onClose={onClose}
      goBackCallback={onBack}
      title={"Candles"}
      modalIcon={
        <CandleIcon
          maxH="64px"
          maxW="64px"
          p={1}
          color={"var(--chakra-colors-primary-900)"}
        />
      }
      topContent={
        <Box px={4} pb={4}>
          <Grid
            templateColumns="repeat(8, 1fr)"
            gap={2}
            pb={2}
            borderBottom="salmon"
            borderBottomWidth="2px"
            borderColor="primary.100"
          >
            <GridItem
              colSpan={4}
              h="10"
              alignContent="center"
              textAlign="center"
            >
              <Center>
                <Box
                  backgroundColor="primary.800"
                  boxShadow="rgb(20 30 20 / 99%) 0px 3px 5px"
                  borderWidth="1px"
                  background="primary.700"
                  borderColor="primary.100"
                  minWidth="32px"
                  maxW="32px"
                  height="28px"
                  borderTopRadius="12px"
                  fontSize={"md"}
                  fontWeight={600}
                  m={2}
                >
                  0x
                </Box>
                Buyer
              </Center>
            </GridItem>
            <GridItem colSpan={4} h="10">
              <Center height="100%" pt={1}>
                <CandleIcon
                  maxW="40px"
                  maxH="40px"
                  color="var(--chakra-colors-primary-100)"
                ></CandleIcon>
                Date
              </Center>
            </GridItem>
          </Grid>
          {candles && candles.length > 0 ? (
            <VStack maxH={"105px"} overflow={"scroll"} overflowX={"hidden"}>
              {candles?.map((c, i) => (
                <SimpleGrid key={i} w={"100%"} columns={2}>
                  <GridItem h="6" fontSize="sm" textAlign="center">
                    {c.address}
                  </GridItem>
                  <GridItem h="6" fontSize="sm" textAlign="center">
                    {c.dateBought.toLocaleString()}
                  </GridItem>
                </SimpleGrid>
              ))}
            </VStack>
          ) : (
            <Center
              borderTopWidth="1px"
              borderColor="primary.100"
              fontSize="md"
              pt={2}
            >
              No candles were lit yet.
            </Center>
          )}
        </Box>
      }
      midContent={
        <HStack height="60px" m={8} mb={2} spacing={4}>
          <Stat minW="120px" textAlign="right">
            <StatLabel>Candle Price</StatLabel>
            <StatNumber fontSize="xl">
              ${parseFloat(candlePrice).toFixed(2)}
            </StatNumber>
            <StatHelpText>FUSD</StatHelpText>
          </Stat>
          <Divider orientation="vertical" borderColor="primary.100" />
          <Stat minW="80px" textAlign="center">
            <StatLabel>Balance</StatLabel>
            <StatNumber fontSize="xl">{balance.fusd.toFixed(2)}</StatNumber>
            <StatHelpText>FUSD</StatHelpText>
          </Stat>
          <Divider orientation="vertical" borderColor="primary.100" />
          <Stat minW="120px" textAlign="left">
            <StatLabel>Burnout</StatLabel>
            <StatNumber fontSize="xl">Tomorrow</StatNumber>
            <StatHelpText>{new Date().toLocaleTimeString()}</StatHelpText>
          </Stat>
        </HStack>
      }
      bottomContent={
        <VStack p={4} spacing={4}>
          <HStack m={2}>
            <Icon />
            <Text fontSize="md">
              {" "}
              Candles burn out completely after 24 hours
            </Text>
          </HStack>

          <TriggerTransactionButton
            transactionRequestObject={{ graveId, graveOwnerAddress }}
            doTransaction={lightCandle}
            buttonText={"Light a candle"}
            onSuccessCallback={onClose}
            onSuccessMessage={"Candle should be lit in a minute or so."}
            disabled={parseFloat(candlePrice) > balance.fusd}
            price={candlePrice}
          />
        </VStack>
      }
    />
  );
};

const WrappedLightCandleModal = (props: LightCandleModalProps) => {
  return (
    <Suspense fallback={<></>}>
      <LightCandleModal {...props} />
    </Suspense>
  );
};

export default WrappedLightCandleModal;
