import { atom, selector, useRecoilState } from "recoil";
import { getStatus } from "../api/get-status.api";
import { TransactionState } from "../global/constants";

const statusState = atom({
  key: "status",
  default: selector({
    key: "status/default",
    get: async ({ get }) => {
      const response = await getStatus();
      return response;
    },
  }),
});

export const statusAtom = atom({
  key: "status::status",
  default: TransactionState.Idle,
});

export function useNecryptolisStatus() {
  const [necryptolisStatus, setNecryptolisStatus] = useRecoilState(statusState);
  const [status, setStatus] = useRecoilState(statusAtom);

  return {
    necryptolisStatus,
    status,
    async refresh() {
      setStatus(TransactionState.Processing);
      await getStatus().then((res) => setNecryptolisStatus(res));
      setStatus(TransactionState.Idle);
    },
  };
}
