import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { tx, TxOptions } from "./utils/tx";
import { MintPlotRequestBody } from "../global/interfaces";

const CODE = fcl.cdc`
    import Necryptolis from 0xNecryptolis
    import FUSD from 0xFUSD

    transaction(left: Int32, top: Int32, width: UInt16, height: UInt16, recipientAddr: Address) {
        let plotMinterRef: &Necryptolis.PlotMinter?
        let mainFusdVault: &FUSD.Vault?

        prepare(acct: AuthAccount) {
            self.plotMinterRef = acct.borrow<&Necryptolis.PlotMinter>(from: Necryptolis.PlotMinterStoragePath) ?? panic("No PlotMinter in acct storage")
            self.mainFusdVault = acct.borrow<&FUSD.Vault>(from: /storage/fusdVault) ?? panic("No FUSD vault in acct storage")
        }

        execute {   
                let plotPrice = Necryptolis.getPlotPrice(width: width, height: height, left: left, top: top)
                let paymentVault <- self.mainFusdVault!.withdraw(amount: plotPrice)
                
                // Mint a new NFT
                let cemeteryPlot <- self.plotMinterRef!.mintCemeteryPlot(left: left, top: top, width: width, height: height, buyerPayment: <- paymentVault)

                let recipient = getAccount(recipientAddr)

                let receiverRef = recipient.getCapability(Necryptolis.CollectionPublicPath).borrow<&{Necryptolis.NecryptolisCollectionPublic}>()!   

                receiverRef.deposit(token: <- cemeteryPlot)
        }
    }
`;

export function mintPlot(
  mintPlotRequest: MintPlotRequestBody,
  opts: TxOptions = {}
) {
  const { rect, recipientAddress } = mintPlotRequest;
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(rect.left, t.Int32),
        fcl.arg(rect.top, t.Int32),
        fcl.arg(rect.width, t.UInt16),
        fcl.arg(rect.height, t.UInt16),
        fcl.arg(recipientAddress, t.Address),
      ]),
      fcl.proposer(fcl.authz),
      fcl.payer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
    ],
    opts
  );
}
