import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { FunctionComponent, useEffect, useState } from "react";
import InfiniteViewer from "react-infinite-viewer";
import { Coordinates } from "../global/interfaces";
import { HomeIcon } from "../icons/HomeIcon";
import { RelocateIcon } from "../icons/RelocateIcon";
import { ZoomInIcon } from "../icons/ZoomInIcon";
import { ZoomOutIcon } from "../icons/ZoomOutIcon";

interface TopLeftCoordinatesBoxProps {
  updateTopLeftCoordinatesRef: React.MutableRefObject<
    ((coordinates: Coordinates) => void) | undefined
  >;
  viewerRef: React.RefObject<InfiniteViewer>;
}

const TopLeftCoordinatesBox: FunctionComponent<TopLeftCoordinatesBoxProps> = ({
  updateTopLeftCoordinatesRef,
  viewerRef,
}) => {
  useEffect(() => {
    updateTopLeftCoordinatesRef.current = (coordinates: Coordinates) => {
      setCoordinates(coordinates);
      setCustomCoordinates(coordinates);
      setDisplayCoordinates({
        x: coordinates.x.toFixed(0),
        y: (-coordinates.y).toFixed(0),
      });
    };
  }, [updateTopLeftCoordinatesRef]);

  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [customCoordinates, setCustomCoordinates] = useState<Coordinates>();
  const [displayCoordinates, setDisplayCoordinates] = useState<{
    x: string;
    y: string;
  }>();

  return (
    <HStack
      zIndex={1000}
      position="absolute"
      top="18px"
      left="10px"
      color="primary.100"
      p={0}
      id="top-left-info"
    >
      
      <VStack>
        <IconButton
          variant={"ghost"}
          aria-label={"Zoom in"}
          height={"28px"}
          borderRadius={"12px"}
          onClick={() =>
            viewerRef.current?.setZoom(
              (viewerRef as any).current.infiniteViewer.zoom + 0.1
            )
          }
          disabled={
            viewerRef.current
              ? (viewerRef as any).current.infiniteViewer.zoom > 1.5
              : false
          }
          icon={
            <ZoomInIcon
              maxH={"20px"}
              maxW={"20px"}
              color={"var(--chakra-colors-primary-100)"}
            />
          }
        ></IconButton>
        <IconButton
          variant={"ghost"}
          aria-label={"Zoom out"}
          onClick={() =>
            viewerRef.current?.setZoom(
              (viewerRef as any).current.infiniteViewer.zoom - 0.1
            )
          }
          disabled={
            viewerRef.current
              ? (viewerRef as any).current.infiniteViewer.zoom < 0.6
              : false
          }
          borderRadius={"12px"}
          height={"28px"}
          icon={
            <ZoomOutIcon
              maxH={"20px"}
              maxW={"20px"}
              color={"var(--chakra-colors-primary-100)"}
            />
          }
        ></IconButton>
      </VStack> 
     
      <Stack
        bg={"#25343b99"}
        borderWidth="1px"
        borderColor="primary.100"
        borderRadius={"16px"}
        direction={["column", "row"]}
        pr={2}
      >
        <VStack spacing={0}>
          <InputGroup maxH={"31px"} size="sm">
            <InputLeftAddon bg="none" border={0} children="X:" />
            <Input
              width="50px"
              variant="unstyled"
              type="text"
              id="input-x-coordinate"
              value={displayCoordinates?.x || ""}
              onBlur={() => {
                if (/^-?\d+$/.test(displayCoordinates?.x || "")) {
                  setCustomCoordinates({
                    x: parseInt(displayCoordinates?.x || "0"),
                    y: customCoordinates?.y || 0,
                  });
                } else {
                  setCustomCoordinates({
                    x: 0,
                    y: customCoordinates?.y || 0,
                  });
                  setDisplayCoordinates({
                    x: "0",
                    y: displayCoordinates?.y || "0",
                  });
                }
              }}
              onChange={(ev) => {
                setDisplayCoordinates({
                  x: ev?.target.value,
                  y: displayCoordinates?.y || "0",
                });
              }}
            />
          </InputGroup>
          <InputGroup maxH={"31px"} size="sm">
            <InputLeftAddon bg="none" border={0} children="Y:" />
            <Input
              width="50px"
              variant="unstyled"
              type="text"
              id="input-y-coordinate"
              value={displayCoordinates?.y || ""}
              onChange={(ev) => {
                setDisplayCoordinates({
                  x: displayCoordinates?.x || "0",
                  y: ev?.target.value,
                });
              }}
              onBlur={() => {
                if (/^-?\d+$/.test(displayCoordinates?.y || "")) {
                  setCustomCoordinates({
                    x: customCoordinates?.x || 0,
                    y: parseInt(displayCoordinates?.y || "0"),
                  });
                } else {
                  setCustomCoordinates({
                    x: customCoordinates?.x || 0,
                    y: 0,
                  });
                  setDisplayCoordinates({
                    x: displayCoordinates?.x || "0",
                    y: "0",
                  });
                }
              }}
            />
          </InputGroup>
        </VStack>
        {(customCoordinates?.x !== coordinates?.x ||
          customCoordinates?.y !== coordinates?.y) && (
          <IconButton
            mx={["16px !important", "4px !important"]}
            my={["4px !important", "16px !important"]}
            variant="ghost"
            boxShadow="rgb(20 30 20 / 99%) 0px 5px 8px"
            aria-label={"Go to location"}
            id="relocate-button"
            icon={
              <RelocateIcon
                maxH={"24px"}
                maxW={"24px"}
                color={"var(--chakra-colors-primary-100)"}
              />
            }
            onClick={() => {
              if (viewerRef) {
                const containerHeight = (viewerRef as any).current
                  .infiniteViewer.containerHeight;
                const containerWidth = (viewerRef as any).current.infiniteViewer
                  .containerWidth;
                viewerRef.current!.scrollTo(
                  (customCoordinates?.x ?? 0) - containerWidth / 2 + 100,
                  -(customCoordinates?.y ?? 0) - containerHeight / 2 + 100
                );
              }
            }}
          />
        )}

        {coordinates &&
          (Math.abs(coordinates.x) > 100 || Math.abs(coordinates.y) > 100) && (
            <Tooltip label="Go to center">
              <IconButton
                mx={["16px !important", "4px !important"]}
                my={["8px !important", "16px !important"]}
                variant="ghost"
                boxShadow="rgb(20 30 20 / 99%) 0px 5px 8px"
                onClick={() => {
                  viewerRef.current!.scrollCenter();
                }}
                icon={
                  <HomeIcon
                    maxH={"24px"}
                    maxW={"24px"}
                    color={"var(--chakra-colors-primary-100)"}
                  />
                }
                aria-label={"Go to center"}
              />
            </Tooltip>
          )}
      </Stack>
    </HStack>
  );
};

export default TopLeftCoordinatesBox;
