import { TransactionsResponse } from "../global/interfaces";

export const getTransactions = async (): Promise<TransactionsResponse> => {
  let response = null;
  try {
    response = await fetch(`${process.env.REACT_APP_API}/api/v1/transactions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const retval = (await response.json()) as TransactionsResponse;

    return retval;
  } catch (error) {
    return { transactions: [] };
  }
};
