import { Icon } from "@chakra-ui/react";
import React from "react";

export const GraveBottomSvg = (props: any) => {
  const { frontColor, backColor, ...restOfprops } = props;
  return (
    <Icon viewBox="0 0 180 50" width="180" height="50" {...restOfprops}>
      <path fill={props.frontColor} d="M162.2 15.5h-143L7.7 2.6h143z" />
      <path fill={props.backColor} d="M19.2 15.5V27h143V15.5" />
      <path fill={props.backColor} d="M19.2 27 7.7 12.2V2.6l11.5 12.9z" />
    </Icon>
  );
};
