import { Box, Center, Text } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";

interface ForSaleSignPostProps {
  price: number;
  id: number;
}

const ForSaleSignPost: FunctionComponent<ForSaleSignPostProps> = ({
  price, id
}) => {
  return (
    <Box
      position="relative"
      width={"160px"}
      height={"160px"}
      transform={`rotate(${(id * 99 % 20) - 10}deg)`}>
      <Box
        position="absolute"
        left={"67px"}
        top={0}
        width={"9px"}
        height={"160px"}
        bg="primary.600"
      ></Box>
      <Box
        position="absolute"
        left={"70px"}
        top={0}
        width={"9px"}
        height={"160px"}
        bg="primary.500"
      ></Box>
      <Box
        left={"18px"}
        top={"16px"}
        position="absolute"
        width={"120px"}
        height={"40px"}
        bg="primary.400"
      ></Box>
      <Box
        top={"16px"}
        left={"23px"}
        position="absolute"
        width={"120px"}
        height={"40px"}
        bg="primary.100"
      >
        <Center h={"100%"} w={"95%"}>
          <Text color="primary.600">${`${price.toFixed(2)}`}</Text>
        </Center>
      </Box>
    </Box>
  );
};

export default ForSaleSignPost;
