import { Spinner } from "@chakra-ui/spinner";
import { Stat, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/stat";
import React from "react";
import { FunctionComponent } from "react";

interface PlotPriceStatProps {
  price: string | undefined;
}

const PlotPriceStat: FunctionComponent<PlotPriceStatProps> = ({ price }) => {
  return price ? (
    <Stat p={4} textAlign="right">
      <StatLabel>Plot Price</StatLabel>
      <StatNumber>{`$${parseFloat(price).toFixed(2)}`}</StatNumber>
      <StatHelpText>in FUSD</StatHelpText>
    </Stat>
  ) : (
    <Stat p={4} textAlign="right">
      <StatLabel>Plot Price</StatLabel>
      <StatNumber>
        <Spinner />
      </StatNumber>
    </Stat>
  );
};

export default PlotPriceStat;
