import { Center, Spinner, VStack, Text } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { FunctionComponent } from "react";
import { Transaction } from "../../global/interfaces";
import { useTransactions } from "../../hooks/use-transactions.hook";
import TransactionBox from "./TransactionBox";

interface LatestTransactionsProps {}

const LatestTransactions: FunctionComponent<LatestTransactionsProps> = () => {
  const { transactions } = useTransactions();
  return (
    <VStack width="100%" pt={2}>
      <Text>Latest transactions</Text>
      <VStack maxH={"400px"} overflowY="auto" width="90%" spacing={4}>
        {transactions.length ? (
          transactions.map((transaction: Transaction) => {
            return (
              <TransactionBox
                key={transaction.transactionId}
                transaction={transaction}
              />
            );
          })
        ) : (
          <Text px={8} textAlign="center" fontSize={"medium"}>
            No transactions recently.
          </Text>
        )}
      </VStack>
    </VStack>
  );
};

const WrappedLatestTransactions = () => {
  return (
    <Suspense
      fallback={
        <Center py={8} width="100%">
          <Spinner />
        </Center>
      }
    >
      <LatestTransactions />
    </Suspense>
  );
};

export default WrappedLatestTransactions;
