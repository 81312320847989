import { Box } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { CandleBoughtInfo } from "../../global/interfaces";
import Candle from "./Candle";

interface CandlesClusterProps {
  graveId?: number;
  candles?: CandleBoughtInfo[];
}

const CandlesCluster: FunctionComponent<CandlesClusterProps> = ({
  candles,
  graveId,
}: CandlesClusterProps) => {
  if (!candles || candles.length < 1) {
    return <></>;
  }

  const getMlValue = (graveId: number, index: number): number => {
    const val = (graveId * (index + graveId) * 19) % 141;
    return val;
  };

  const getMtValue = (graveId: number, index: number): number => {
    const val = ((graveId * (index + graveId) * 19) % 26) - 20;
    return val;
  };

  return (
    <Box mt={"140px"} position="absolute" width="100%" zIndex={10}>
      {candles?.map((candleInfo, index) => (
        <Candle
          key={`candle-${graveId}-${index}`}
          mlInPixels={getMlValue(graveId ?? 1, index)}
          mtInPixels={getMtValue(graveId ?? 1, index)}
          randomSeed={index + (graveId ?? 1)}
          dateBought={candleInfo.dateBought}
        />
      ))}
    </Box>
  );
};

export default CandlesCluster;
