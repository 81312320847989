import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Accordion
} from "@chakra-ui/react";
import React, { FunctionComponent, Suspense } from "react";
import { useMessages } from "../../hooks/use-messages.hook";
import { InboxIcon } from "../../icons/InboxIcon";
import MessageBox from "./MessageBox";


interface InboxModalProps {
    address: string | undefined,
    isOpen: boolean,
    onClose: () => void;
}

const InboxModal: FunctionComponent<InboxModalProps> = (
    { isOpen, onClose, address }
) => {
    const { messages, setMessages } = useMessages(address);
    return (<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader margin="8px" textAlign="center">
                <InboxIcon
                    height="28px"
                    maxW={"28px"}
                    color={"var(--chakra-colors-primary-100)"}
                /> Inbox
            </ModalHeader>
            <ModalCloseButton margin="12px" />
            <ModalBody>
                <Accordion allowToggle maxH={"500px"} overflowY="scroll">
                    {messages.map(m =>
                        <MessageBox
                            key={m.id}
                            message={m}
                            setMessageToRead={() => setMessages((oldMessagesResponse) => {
                                var selectedMessage = { ...oldMessagesResponse.messages.find(x => x.id === m.id)! }
                                selectedMessage.isRead = true
                                return {
                                    ...oldMessagesResponse,
                                    messages: [...oldMessagesResponse.messages.filter(x => x.id !== m.id), selectedMessage]
                                        .sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
                                }
                            })} />)}
                </Accordion>
            </ModalBody>
        </ModalContent>
    </Modal>);
}

const WrappedInboxModal = (props: InboxModalProps) => {
    return (
        <Suspense
            fallback={
                <></>
            }
        >
            <InboxModal {...props} />
        </Suspense>
    );
};

export default WrappedInboxModal;

