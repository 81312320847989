import { Box } from "@chakra-ui/react";
import React from "react";
import { FunctionComponent } from "react";
import { CemeteryPlotData } from "../../global/interfaces";
import WallCornerPng from "../../assets/wall-corner.png";
import WallCornerTopPng from "../../assets/wall-corner-top.png";
import WallHorizontalPng from "../../assets/wall-horizontal.png";
import WallVerticalPng from "../../assets/wall-upwards.png";

interface AdminCemeteryPlotProps {
  plot: CemeteryPlotData;
  isHighlightedBorder?: boolean;
  setCemeteryPlot: (plot: CemeteryPlotData) => void;
  onCemeteryPlotInfoOpen: () => void;
  onTouchEnd: (_: any) => void;
  onTouchStart: (_: any) => void;
}

const AdminCemeteryPlot: FunctionComponent<AdminCemeteryPlotProps> = ({
  plot,
  isHighlightedBorder,
  setCemeteryPlot,
  onCemeteryPlotInfoOpen,
  onTouchEnd,
  onTouchStart,
}) => {
  const { left, top, width, height } = plot.plot;
  const type = plot.grave?.metadata?.type;

  if (type !== "custom") {
    return (
      <Box
        bg={"primary.900"}
        {...getProperties(type)}
        position="absolute"
        transform={`translate(${left}px, ${top - 300}px)`}
        width={`${width}px`}
        height={`${height}px`}
      >
        {getBackground(type)}
      </Box>
    );
  } else {
    return <></>;
  }
};

function getProperties(type: string) {
  switch (type) {
    case "fence_x":
      return { backgroundImage: WallHorizontalPng, zIndex: -1 };
    case "path":
      return { zIndex: -1 };
    case "fence_y":
      return { zIndex: 1 };
    case "fence_corner_top":
      return { backgroundImage: WallCornerPng };
    case "fence_corner_bottom":
      return { zIndex: 2 };
    default:
      return {};
  }
}

function getBackground(type: string) {
  switch (type) {
    case "fence_x":
      return <></>;
    case "fence_y":
      return (
        <>
          <Box
            position="absolute"
            width="100%"
            height="100%"
            margin="-80px 0 0"
            padding="80px 0 0 0"
            backgroundImage={WallVerticalPng}
          ></Box>
        </>
      );
    case "fence_corner_top":
      return (
        <>
          <Box
            width="110px"
            height="270px"
            position="absolute"
            left="-20px"
            backgroundImage={WallCornerTopPng}
          ></Box>
        </>
      );
    case "fence_corner_bottom":
      return (
        <>
          <Box
            width="110px"
            height="270px"
            position="absolute"
            top="-140px"
            left="-20px"
            backgroundImage={WallCornerPng}
          ></Box>
        </>
      );
    default:
      return <></>;
  }
}

export default AdminCemeteryPlot;
