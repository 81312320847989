import { Icon } from "@chakra-ui/react";
import React from "react";

export const AccountUninitalizedIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill="#B38CB4"
      d="M18.377 45.301v11.99a1.09 1.09 0 1 1-2.18 0v-5.449H9.656v5.449a1.09 1.09 0 1 1-2.18 0V50.75a20.741 20.741 0 0 1 14.799-19.839 14.166 14.166 0 0 1-6.966-6.965c-3.266-7.113-.147-15.526 6.966-18.792 7.113-3.265 15.526-.146 18.79 6.966 3.268 7.113.146 15.525-6.965 18.79 3.543 1.06 6.611 3.028 9.035 5.579h-3.152a18.393 18.393 0 0 0-10.729-4.239c-10.207-.587-18.956 7.207-19.543 17.41h6.485v-4.359a1.09 1.09 0 1 1 2.181 0zM40.178 18.05c0-6.622-5.369-11.991-11.991-11.991-6.619.008-11.983 5.372-11.99 11.991 0 6.622 5.368 11.99 11.99 11.99s11.991-5.368 11.991-11.99z"
    />
    <circle
      fill="none"
      stroke="#B38CB4"
      strokeWidth="2"
      strokeMiterlimit="10"
      cx="44.648"
      cy="48.387"
      r="11.875"
    />
    <path
      fill="#B38CB4"
      d="M46.076 55.299a1.427 1.427 0 0 1-2.856 0 1.427 1.427 0 1 1 2.856 0zM44.449 52.303s-.238-.766-.523-1.916c-.139-.574-.305-1.244-.453-1.961-.166-.719-.285-1.484-.424-2.25-.117-.766-.455-1.533-.484-2.25-.01-.719.221-1.389.479-1.963.281-.574.59-1.053.889-1.389.283-.334.518-.525.518-.525h.4s.232.191.516.525c.301.336.609.814.889 1.389.258.574.488 1.244.479 1.963-.027.717-.367 1.484-.484 2.25-.137.766-.256 1.531-.424 2.25-.148.717-.313 1.387-.451 1.961a40.516 40.516 0 0 1-.523 1.916h-.404z"
    />
  </Icon>
);
