export const readMessage = async (
    address: string,
    messageId: string
) => {
    if (!address || !messageId) {
        return
    }

    try {
        await fetch(
            `${process.env.REACT_APP_API}/api/v1/read-message`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    address, id: messageId
                })
            }
        );
    } catch (error) {
        console.log("error reading a message");
    }
};
