import MoveableHelper from "moveable-helper";
import React, { RefObject, Suspense, useState } from "react";
import InfiniteViewer from "react-infinite-viewer";
import Moveable, { MoveableManagerInterface, Renderer } from "react-moveable";
import { Rect } from "../global/interfaces";
import { usePlotDatas } from "../hooks/use-plot-datas.hook";
import { AddPlotCluster } from "../clusters/addPlot/AddPlotCluster";
import { areRectsColliding } from "../global/utils";

interface Props {
  showPlotCreatorFuncRef: any;
  viewerRef: RefObject<InfiniteViewer>;
  targetRef: RefObject<HTMLDivElement>;
  closePlotCreator: () => void;
}

const PlotCreator = (props: Props) => {
  const { viewerRef, targetRef, closePlotCreator, showPlotCreatorFuncRef } =
    props;
  const [, existingPlots] = usePlotDatas();
  const [isVisible, setIsVisible] = useState(false);
  const moveableRef = React.useRef<Moveable>(null);

  React.useEffect(() => {
    showPlotCreatorFuncRef.current = (x: number, y: number) => {
      setIsVisible(true);
      setTimeout(() => {
        if (moveableRef.current) {
          const offsetY = moveableRef.current.getRect().height * 0.5;
          const offsetX = moveableRef.current.getRect().width * 0.5;
          moveableRef.current.request(
            "draggable",
            { x: Math.floor(x - offsetX), y: Math.floor(y - offsetY) },
            true
          );
        }
      }, 300);
    };
  }, [showPlotCreatorFuncRef]);

  const [helper] = React.useState(() => {
    return new MoveableHelper();
  });
  const [isColliding, setIsColliding] = React.useState<boolean>(false);

  const checkColliding = (event: any): void => {
    if (event && event.moveable) {
      const moveableRect: Rect = event.moveable.getRect();
      let colliding = isCollidingWithOtherPlots(
        moveableRect,
        existingPlots.map((p) => p.plot)
      );
      setIsColliding(colliding);
    }
  };

  const isCollidingWithOtherPlots = (rect: Rect, plots: Rect[]): boolean => {
    plots.push({
      width: 300,
      height: 300,
      left: 0,
      top: 0,
    });
    for (let index = 0; index < plots.length; index++) {
      if (areRectsColliding(plots[index], rect)) {
        return true;
      }
    }

    return false;
  };

  const DimensionViewable = {
    name: "dimensionViewable",
    props: {},
    events: {},
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();

      const text = !isColliding
        ? `${Math.round(rect.offsetWidth)} x ${Math.round(rect.offsetHeight)}`
        : "Colliding";
      // Add key (required)
      // Add class prefix moveable-(required)
      return (
        <div
          key={"dimension-viewer"}
          className={"moveable-dimension"}
          style={{
            position: "absolute",
            left: `${rect.width / 2 + 60}px`,
            top: `${rect.height + 16}px`,
            border: "2px solid",
            background: "var(--chakra-colors-primary-800)",
            borderColor: isColliding
              ? "var(--chakra-colors-salmon)"
              : "var(--chakra-colors-primary-100)",
            padding: "2px 4px",
            color: isColliding
              ? "var(--chakra-colors-salmon)"
              : "var(--chakra-colors-primary-100)",
            fontSize: "13px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: "translate(-50%, 0px)",
          }}
        >
          {text}
        </div>
      );
    },
  } as const;

  const PositionViewable = {
    name: "positionViewable",
    props: {},
    events: {},
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();

      // Add key (required)
      // Add class prefix moveable-(required)
      return (
        <div
          key={"position-viewer"}
          className={"moveable-position"}
          style={{
            position: "absolute",
            left: `${rect.width / 2 - 60}px`,
            top: `${rect.height + 16}px`,
            border: "2px solid",
            background: "var(--chakra-colors-primary-800)",
            borderColor: "var(--chakra-colors-primary-100)",
            padding: "2px 4px",
            color: "var(--chakra-colors-primary-100)",
            fontSize: "13px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: "translate(-50%, 0px)",
          }}
        >
          {`(${Math.round(rect.left)}, ${-Math.round(rect.top)})`}
        </div>
      );
    },
  } as const;

  const ClosePlotCreateorable = {
    name: "closePlotCreateorable",
    props: {},
    events: {},
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();
      // Add key (required)
      // Add class prefix moveable-(required)
      return (
        <button
          key={"close-plot-viewer"}
          onClick={() => {
            closePlotCreator();
            setIsVisible(false);
          }}
          onTouchEnd={() => {
            closePlotCreator();
            setIsVisible(false);
          }}
          style={{
            position: "absolute",
            right: `${-rect.width - 55}px`,
            top: `0px`,
            border: "2px solid",
            background: "var(--chakra-colors-primary-800)",
            borderColor: "var(--chakra-colors-primary-100)",
            padding: "2px 4px",
            color: "var(--chakra-colors-primary-100)",
            fontSize: "13px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: "translate(-50%, 0px)",
          }}
        >
          &nbsp;X&nbsp;
        </button>
      );
    },
  } as const;

  const Editable = {
    name: "editable",
    props: {},
    events: {},
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();
      const { pos2 } = moveable.state;
      // use css for able
      const EditableViewer = moveable.useCSS(
        "div",
        `
        {
            position: absolute;
            right: 10px;
            top: 10px;
            will-change: transform;
            transform-origin: 0px 0px;
        }
        .moveable-button {
            width: 64px;
            height: 64px;
            color: var(--chakra-colors-primary-100);
        }
        `
      );
      // Add key (required)
      // Add class prefix moveable-(required)
      return (
        <EditableViewer
          key="editable-viewer"
          className={"moveable-editable"}
          style={{
            transform: `translate(${pos2[0]}px, (${pos2[1]}px) rotate((${rect.rotation}deg) translate(10px)`,
          }}
        >
          <AddPlotCluster disabled={isColliding} rect={rect} />
        </EditableViewer>
      );
    },
  } as const;

  if (!isVisible) {
    return <></>;
  }
  return (
    <Moveable
      ref={moveableRef}
      scrollable={true}
      draggable={true}
      snappable={true}
      verticalGuidelines={existingPlots
        .map((p) => p.plot.left - 1)
        .concat(existingPlots.map((p) => p.plot.left + p.plot.width))}
      horizontalGuidelines={existingPlots
        .map((p) => p.plot.top)
        .concat(existingPlots.map((p) => p.plot.top + p.plot.height))}
      snapThreshold={10}
      isDisplaySnapDigit={true}
      ables={[
        Editable,
        DimensionViewable,
        ClosePlotCreateorable,
        PositionViewable,
      ]}
      props={{
        editable: true,
        smorable: true,
        dimensionViewable: true,
        closePlotCreateorable: true,
        positionViewable: true,
      }}
      scrollContainer={() => viewerRef.current!.getElement()}
      scrollThreshold={20}
      getScrollPosition={() => {
        return [
          viewerRef.current!.getScrollLeft(),
          viewerRef.current!.getScrollTop(),
        ];
      }}
      onScroll={({ direction }) => {
        viewerRef.current!.scrollBy(direction[0] * 10, direction[1] * 10);
      }}
      onResizeEnd={(e) => {
        checkColliding(e);
      }}
      target={targetRef}
      resizable={true}
      onDragEnd={(e) => {
        checkColliding(e);
      }}
      onDragStart={helper.onDragStart}
      onDrag={helper.onDrag}
      onResizeStart={helper.onResizeStart}
      onResize={helper.onResize}
    />
  );
};

const WrappedPlotCreator = (props: Props) => {
  return (
    <Suspense fallback={<></>}>
      <PlotCreator
        targetRef={props.targetRef}
        viewerRef={props.viewerRef}
        closePlotCreator={props.closePlotCreator}
        showPlotCreatorFuncRef={props.showPlotCreatorFuncRef}
      />
    </Suspense>
  );
};

export default WrappedPlotCreator;
