import { Box } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";

export const GradientDefsFakeGrave: FunctionComponent = () => (
  <Box maxWidth="0px" position="absolute">
    <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
      {GradientDefs()}
    </svg>
  </Box>
);

export function GetGradientId(color: string) {
  if (color.includes("tealDark")) return "tealDarkGradient";
  if (color.includes("stoneDark")) return "stoneDarkGradient";
  if (color.includes("lilacDark")) return "lilacDarkGradient";
  if (color.includes("salmonBright")) return "salmonBrightGradient";
  if (color.includes("lilac")) return "lilacGradient";
  if (color.includes("salmon")) return "salmonGradient";
  if (color.includes("stone")) return "stoneGradient"
  return "tealGradient";
}

export function GradientDefs() {
  return (
    <defs>
      <linearGradient id="tealGradient">
        <stop offset="5%" stopColor="#123635" />
        <stop offset="95%" stopColor="#178781" />
      </linearGradient>
      <linearGradient id="lilacGradient">
        <stop offset="5%" stopColor="#4C374C" />
        <stop offset="95%" stopColor="#D599A8" />
      </linearGradient>
      <linearGradient id="salmonGradient">
        <stop offset="15%" stopColor="#44282e" />
        <stop offset="95%" stopColor="#b09ca1" />
      </linearGradient>
      <linearGradient id="stoneGradient">
        <stop offset="5%" stopColor="#5f5951" />
        <stop offset="95%" stopColor="#e5e0d9" />
      </linearGradient>
      <linearGradient id="salmonBrightGradient">
        <stop offset="15%" stopColor="#64423f" />
        <stop offset="95%" stopColor="#e4c2be" />
      </linearGradient>
      <linearGradient id="tealDarkGradient">
        <stop offset="5%" stopColor="#1f2831" />
        <stop offset="95%" stopColor="#178781" />
      </linearGradient>
      <linearGradient id="stoneDarkGradient">
        <stop offset="5%" stopColor="#2e282c" />
        <stop offset="95%" stopColor="#ada59d" />
      </linearGradient>
      <linearGradient id="lilacDarkGradient">
        <stop offset="15%" stopColor="#2c212c" />
        <stop offset="95%" stopColor="#d1a1bd" />
      </linearGradient>
    </defs>
  );
}
