import {
  Divider,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  Text,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Flex,
  Icon,
  StatHelpText,
  Spinner,
} from "@chakra-ui/react";
import React, { Suspense, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import ThreeStripedModal from "../../components/ThreeStripedModal";
import { TriggerTransactionButton } from "../../components/TriggerTransactionButton";
import { fetchPlotPrice } from "../../flow/fetch-plot-price";
import { listNFT } from "../../flow/list-nft";
import { ListNFTRequestBody, Rect } from "../../global/interfaces";
import { MarketIcon } from "../../icons/MarketIcon";

interface MarketPlotModalProps {
  isOpen: boolean;
  onClose: () => void;
  plotInfo: Rect;
  plotId: number;
  onBack: () => void;
}

const MarketPlotModal: FunctionComponent<MarketPlotModalProps> = ({
  isOpen,
  onClose,
  onBack,
  plotInfo,
  plotId,
}: MarketPlotModalProps) => {
  const [plotPrice, setPlotPrice] = useState(15);
  const [plotBasePrice, setPlotBasePrice] = useState<string | undefined>();

  useEffect(() => {
    if (plotInfo && isOpen) {
      const fetchPrice = async () => {
        setPlotBasePrice(await fetchPlotPrice(plotInfo));
      };

      fetchPrice();
    }
  }, [plotInfo, isOpen]);

  return (
    <ThreeStripedModal
      goBackCallback={onBack}
      isOpen={isOpen}
      onClose={onClose}
      modalIcon={
        <MarketIcon
          maxH="64px"
          maxW="64px"
          p={1}
          color={"var(--chakra-colors-primary-900)"}
        />
      }
      title={"List plot on market"}
      topContent={
        <HStack height="50px" m={2} mb={6} spacing={4}>
          <Stat minW="120px" textAlign="right">
            <StatLabel>Dimension</StatLabel>
            <StatNumber>
              {plotInfo?.width} x {plotInfo?.height}
            </StatNumber>
          </Stat>
          <Divider orientation="vertical" borderColor="primary.100" />
          <Stat minW="120px" textAlign="left">
            <StatLabel>Location</StatLabel>

            <StatNumber>
              ({plotInfo?.left}, {-plotInfo?.top})
            </StatNumber>
          </Stat>
        </HStack>
      }
      midContent={
        plotBasePrice ? (
          <Stat pt={4} textAlign="center">
            <StatLabel>Plot base price</StatLabel>
            <StatNumber>{`$${parseFloat(plotBasePrice).toFixed(
              2
            )}`}</StatNumber>
            <StatHelpText>in FUSD</StatHelpText>
          </Stat>
        ) : (
          <Spinner m={6} p={4} />
        )
      }
      bottomContent={
        <VStack p={4} spacing={4}>
          <Flex pt={4}>
            <Icon mx={2} />
            <Text textAlign="center" fontSize="sm">
              Allows other users to buy the plot for the set price
            </Text>
          </Flex>
          <HStack pt={4}>
            <NumberInput
              borderColor={"primary.800"}
              focusBorderColor="primary.400"
              _hover={{ borderColor: "primary.300" }}
              onChange={(valueString) => setPlotPrice(parseFloat(valueString))}
              size="lg"
              maxW={32}
              precision={2}
              defaultValue={15.0}
              min={0}
              max={100000}
            >
              <NumberInputField _hover={{ borderColor: "primary.300" }} />
              <NumberInputStepper borderColor={"primary.800"}>
                <NumberIncrementStepper
                  color="primary.500"
                  _hover={{ borderColor: "primary.300", bg: "primary.200" }}
                  borderColor={"primary.800"}
                />
                <NumberDecrementStepper
                  color="primary.500"
                  _hover={{ borderColor: "primary.300", bg: "primary.200" }}
                  borderColor={"primary.800"}
                />
              </NumberInputStepper>
            </NumberInput>
            <Text fontSize={"2xl"}>FUSD</Text>
          </HStack>
          <TriggerTransactionButton
            transactionRequestObject={
              {
                nftId: plotId,
                price: plotPrice,
              } as ListNFTRequestBody
            }
            disabled={false}
            doTransaction={listNFT}
            buttonText={"List plot on market"}
            onSuccessCallback={onClose}
            onSuccessMessage={
              "Your plot has now been listed for sale. Check in a few minutes."
            }
          />
        </VStack>
      }
    />
  );
};

const WrappedMarketPlotModal = (props: MarketPlotModalProps) => {
  return (
    <Suspense fallback={<></>}>
      <MarketPlotModal {...props} />
    </Suspense>
  );
};

export default WrappedMarketPlotModal;
