import * as fcl from "@onflow/fcl";
import { tx, TxOptions } from "./utils/tx";

const CODE = fcl.cdc`
    import FungibleToken from 0xFungibleToken
    import NonFungibleToken from 0xNonFungibleToken
    import FUSD from 0xFUSD
    import Necryptolis from 0xNecryptolis
    import NFTStorefront from 0xNFTStorefront
    import MetadataViews from 0xMetadataViews

    pub fun hasFUSD(_ address: Address): Bool {
        let receiver = getAccount(address)
            .getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)
            .check()
        let balance = getAccount(address)
            .getCapability<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance)
            .check()
        return receiver && balance
    }

    pub fun hasCollection(_ address: Address): Bool {
        return getAccount(address)
            .getCapability<&Necryptolis.Collection{NonFungibleToken.CollectionPublic, Necryptolis.NecryptolisCollectionPublic}>(Necryptolis.CollectionPublicPath)
            .check()
    }

    pub fun hasStorefront(_ address: Address): Bool {
        return getAccount(address)
            .getCapability<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(NFTStorefront.StorefrontPublicPath)
            .check()
    }

    pub fun hasResolverCollection(_ address: Address): Bool {
        return getAccount(address)
            .getCapability<&{MetadataViews.ResolverCollection}>(Necryptolis.ResolverCollectionPublicPath)
            .check()
      }

    transaction {
    prepare(acct: AuthAccount) {
        if !hasFUSD(acct.address) {
            if acct.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil {
                acct.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)
            }
            acct.unlink(/public/fusdReceiver)
            acct.unlink(/public/fusdBalance)
            acct.link<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver, target: /storage/fusdVault)
            acct.link<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance, target: /storage/fusdVault)
        }
        if !hasStorefront(acct.address) {
            if acct.borrow<&NFTStorefront.Storefront>(from: NFTStorefront.StorefrontStoragePath) == nil {
                acct.save(<-NFTStorefront.createStorefront(), to: NFTStorefront.StorefrontStoragePath)
            }
            acct.unlink(NFTStorefront.StorefrontPublicPath)
            acct.link<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(NFTStorefront.StorefrontPublicPath, target: NFTStorefront.StorefrontStoragePath)
        }
        if !hasCollection(acct.address) {
            if acct.borrow<&Necryptolis.Collection>(from: Necryptolis.CollectionStoragePath) == nil {
                acct.save(<-Necryptolis.createEmptyCollection(), to: Necryptolis.CollectionStoragePath)
            }
            acct.unlink(Necryptolis.CollectionPublicPath)
            acct.link<&Necryptolis.Collection{NonFungibleToken.CollectionPublic, Necryptolis.NecryptolisCollectionPublic}>(Necryptolis.CollectionPublicPath, target: Necryptolis.CollectionStoragePath)
        }
        if !hasResolverCollection(acct.address) {
            acct.unlink(Necryptolis.ResolverCollectionPublicPath)
            acct.link<&{MetadataViews.ResolverCollection}>(Necryptolis.ResolverCollectionPublicPath, target: Necryptolis.CollectionStoragePath)
        }

        if acct.borrow<&Necryptolis.PlotMinter>(from: Necryptolis.PlotMinterStoragePath) == nil {
            acct.save(<-Necryptolis.createPlotMinter(), to: Necryptolis.PlotMinterStoragePath)
        }

        if acct.borrow<&Necryptolis.PlotMinter>(from: Necryptolis.PlotMinterStoragePath) == nil {
            acct.save(<-Necryptolis.createPlotMinter(), to: Necryptolis.PlotMinterStoragePath)
        }

        if acct.borrow<&Necryptolis.GravestoneManager>(from: Necryptolis.GravestoneManagerStoragePath) == nil {
            acct.save(<-Necryptolis.createGravestoneManager(), to: Necryptolis.GravestoneManagerStoragePath)
        }
    }
}
`;

export function setupAccount(address: string, opts: TxOptions = {}) {
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([]),
      fcl.proposer(fcl.authz),
      fcl.payer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
    ],
    opts
  );
}
