import { Text } from "@chakra-ui/layout";
import { Button, Tooltip } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";

interface AddressGraveIconProps {
  address?: string;
}

const AddressGraveIcon: FunctionComponent<AddressGraveIconProps> = ({
  address,
}) => {
  return (
    <Tooltip label="Copy address">
      <Button
        variant={"grave"}
        backgroundColor="primary.800"
        boxShadow="rgb(20 30 20 / 99%) 0px 5px 8px"
        borderWidth="1px"
        background="primary.700"
        borderColor="primary.100"
        minWidth="32px"
        maxW="32px"
        height="28px"
        borderTopRadius="12px"
        onClick={() => navigator.clipboard.writeText(address || "")}
        m={2}
      >
        <Text fontSize="md" mt={0.5}>
          0x
        </Text>
      </Button>
    </Tooltip>
  );
};

export default AddressGraveIcon;
