import { Icon } from "@chakra-ui/react";
import React from "react";

export const AddPlotIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path fill="none" d="M42.3 28.4H61v19.9H42.3z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color || "var(--chakra-colors-primary-100)"}
      d="M50.5 43.2h1.9v3.7h-1.9zM50.5 36.6h1.9v3.7h-1.9zM50.5 30h1.9v3.7h-1.9z"
    />
    <path fill="none" d="M19.8 48.4h22.5v18.7H19.8z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color || "var(--chakra-colors-primary-100)"}
      d="M21.5 56.5h4.2v1.9h-4.2zM29 56.5h4.2v1.9H29zM36.4 56.5h4.2v1.9h-4.2z"
    />
    <g>
      <path fill="none" d="M1.1 33.5h18.7v14.9H1.1z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color || "var(--chakra-colors-primary-100)"}
        d="M9.8 34.6h1.9v2.8H9.8zM9.8 39.5h1.9v2.8H9.8zM9.8 44.5h1.9v2.8H9.8z"
      />
    </g>
    <g>
      <path fill="none" d="M1.1 18.5h18.7v14.9H1.1z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color || "var(--chakra-colors-primary-100)"}
        d="M9.8 19.6h1.9v2.8H9.8zM9.8 24.6h1.9v2.8H9.8zM9.8 29.6h1.9v2.8H9.8z"
      />
    </g>
    <g>
      <path fill="none" d="M19.8-.2h15.4v18.7H19.8z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color || "var(--chakra-colors-primary-100)"}
        d="M31.2 8.5h2.9v1.9h-2.9zM26.1 8.5H29v1.9h-2.9zM21 8.5h2.9v1.9H21z"
      />
    </g>
    <g
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color || "var(--chakra-colors-primary-100)"}
    >
      <path d="M50.6 49.7h1.9v3.1h-1.9zM52.5 58.4v-3.1h-1.9v1.2h-1.3v1.9zM43.7 56.5h3.1v1.9h-3.1z" />
    </g>
    <g
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color || "var(--chakra-colors-primary-100)"}
    >
      <path d="M15.4 56.7h3.1v1.9h-3.1zM9.8 58.5h3.1v-1.8h-1.2v-1.3H9.8zM9.8 49.8h1.9v3.1H9.8z" />
    </g>
    <g
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color || "var(--chakra-colors-primary-100)"}
    >
      <path d="M9.6 14.1h1.9v3.1H9.6zM9.6 8.5v3.1h1.9v-1.2h1.3V8.5zM15.3 8.5h3.1v1.9h-3.1z" />
    </g>
    <circle
      fill="none"
      stroke={props.color || "var(--chakra-colors-primary-100)"}
      strokeWidth="1.8"
      strokeMiterlimit="10"
      cx="45.7"
      cy="17.1"
      r="10.8"
    />
    <path
      stroke={props.color || "var(--chakra-colors-primary-100)"}
      strokeMiterlimit="10"
      d="M52.3 15h-4.4a.5.5 0 0 1-.5-.5v-4.4c0-.5-.5-1-1-1h-1.5a1 1 0 0 0-1 1v4.4c0 .3-.2.5-.5.5h-4.3a1 1 0 0 0-1 1v1.5c0 .6.4 1 1 1h4.3c.3 0 .5.2.5.5v4.4c0 .5.5 1 1 1h1.5c.5 0 1-.5 1-1V19c0-.3.2-.5.5-.5h4.4c.5 0 1-.4 1-1V16c0-.5-.5-1-1-1zm.5 2.5c0 .3-.3.5-.5.5h-4.4a1 1 0 0 0-1 1v4.4c0 .3-.2.5-.5.5h-1.5a.5.5 0 0 1-.5-.5V19c0-.5-.4-1-1-1h-4.3a.5.5 0 0 1-.5-.5V16c0-.2.2-.5.5-.5h4.3c.6 0 1-.4 1-1v-4.4c0-.3.3-.5.5-.5h1.5c.3 0 .5.2.5.5v4.4c0 .6.4 1 1 1h4.4c.2 0 .5.3.5.5v1.5z"
    />
  </Icon>
);
