import { atom, selector, useRecoilValue } from "recoil";
import { fetchPlotSalesInfo } from "../flow/fetch-plot-sales-info";

const plotSalesInfoState = atom({
  key: "plotSalesInfo",
  default: selector({
    key: "plotSalesInfo/default",
    get: async ({ get }) => {
      const response = await fetchPlotSalesInfo();
      return response;
    },
  }),
});

export function usePlotSalesInfo() {
  const plotSalesInfo = useRecoilValue(plotSalesInfoState);

  return plotSalesInfo;
}
