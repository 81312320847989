import {
  CemeteryPlotData,
  PlotDataResponse,
  ScreenCoordinates,
} from "../global/interfaces";
import { toCemeteryPlotData } from "../global/utils";

export const getPlotDatas = async (
  coordinates: ScreenCoordinates
): Promise<CemeteryPlotData[]> => {
  if (
    coordinates.bottomRight.x === 0 &&
    coordinates.bottomRight.y === 0 &&
    coordinates.topLeft.x === 0 &&
    coordinates.topLeft.y === 0
  ) {
    return [];
  }
  let response = null;
  try {
    //http://localhost:7071/api/GetPlots?x=-1&y=300
    response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/plots?x1=${coordinates.topLeft.x}&y1=${coordinates.topLeft.y}&x2=${coordinates.bottomRight.x}&y2=${coordinates.bottomRight.y}`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const retval = (await response.json()) as PlotDataResponse[];

    return retval.map((r) => toCemeteryPlotData(r));
  } catch (error) {
    console.log("error getting plots.");
    return [];
  }
};
