import { Icon } from "@chakra-ui/react";
import React from "react";

export const CornerHolesGraveIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill={props.color}
      d="m56 12-5 48-1 1H14l-1-1-5-48c5 0 9-4 9-9h30c0 5 4 9 9 9z"
    />
  </Icon>
);
