import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, VStack, Text, Button } from "@chakra-ui/react";
import React from "react";
import { FunctionComponent } from "react";
import { readMessage } from "../../api/read-message.api";
import { Message } from "../../global/interfaces";
import { AddGraveIcon } from "../../icons/AddGraveIcon";
import { AddPlotIcon } from "../../icons/AddPlotIcon";
import { BuryIcon } from "../../icons/BuryIcon";
import { CandleIcon } from "../../icons/CandleIcon";
import { CutBushIcon } from "../../icons/CutBushIcon";
import { GraveBasicIcon } from "../../icons/GraveBasicIcon";
import { MarketIcon } from "../../icons/MarketIcon";

interface MessageBoxProps {
    message: Message,
    setMessageToRead: () => void;
}

const MessageBox: FunctionComponent<MessageBoxProps> = ({ message, setMessageToRead }) => {
    return (
        <AccordionItem borderColor={"primary.600"}>
            {({ isExpanded }) => (
                <>
                    <h2>
                        <AccordionButton  _expanded={{ bg: 'primary.700', fontWeight: "bold" }} onClick={() => {
                            if (!isExpanded && !message.isRead) {
                                readMessage(message.receiver, message.id)
                                setMessageToRead()
                            }
                        }
                        }>
                            {MessageIcon(message)}
                            <Box ml={1} flex='1' textAlign='left' color={message.isRead ? "primary.400" : "primary.100"}>
                                {message.title}

                            </Box>
                            <Text fontSize={"xs"}>{new Date(message.createdOn).toDateString().substring(4, 10)}</Text>
                            <AccordionIcon />
                            {!message.isRead && <Box p={2} ml={2} borderRadius={"50%"} bg="primary.100" width="10px" height={"10px"}></Box>}
                        </AccordionButton>
                    </h2>
                    <AccordionPanel bg='primary.700'>
                        {MessageContent(message)}
                    </AccordionPanel>
                </>
            )
            }
        </AccordionItem >);
}

export default MessageBox;


function MessageIcon(message: Message) {
    return (
        message.type === "CandleLit" ? (
            <CandleIcon height="32px"
                maxW={"32px"}
                color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"} />
        ) :

            message.type === "PlotArrived" ? (
                <AddPlotIcon
                    maxH={"32px"}
                    maxW={"32px"}
                    color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"}
                />
            )
                :
                message.type === "GravestoneCreated" ? (
                    <AddGraveIcon
                        maxH={"32px"}
                        maxW={"32px"}
                        color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"}
                    />
                )
                    :
                    message.type === "PlotBought" ? (
                        <MarketIcon
                            maxH={"32px"}
                            maxW={"32px"}
                            color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"}
                        />
                    )
                        :

                        message.type === "PlotListed" ? (
                            <MarketIcon
                                maxH={"32px"}
                                maxW={"32px"}
                                color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"}
                            />
                        )
                            :

                            message.type === "PlotTrimmed" ? (
                                <CutBushIcon
                                    maxH={"32px"}
                                    maxW={"32px"}
                                    color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"}
                                />
                            )
                                :

                                message.type === "NFTBuried" ? (
                                    <BuryIcon
                                        maxH={"32px"}
                                        maxW={"32px"}
                                        color={message.isRead ? "var(--chakra-colors-primary-400)" : "var(--chakra-colors-primary-100)"}
                                    />
                                )
                                    : <></>)
}

function MessageContent(message: Message) {
    let content = <></>
    switch (message.type) {
        case "CandleLit":
            content = (
                <Text mb={2}>A new candle has been lit on your grave by {message.actor}.</Text>
            )
            break;
        case "GravestoneCreated":
            content = (
                <Text mb={2}>You've successfully created a gravestone.</Text>
            )
            break;
        case "NFTBuried":
            content = (
                <Text mb={2}>An NFT has been buried. May it rest in peace.</Text>
            )
            break;
        case "PlotArrived":
            content = (
                <Text mb={2}>A new Necryptolis NFT just arrived in your account. The possibilities are endless.</Text>
            )
            break;
        case "PlotBought":
            content = (
                <Text mb={2}>Your plot has been bought! Someone else now enjoys the riches.</Text>
            )
            break;
        case "PlotListed":
            content = (
                <Text mb={2}>You've successfully listed your Necryptolis NFT on the market.</Text>
            )
            break;
        case "PlotTrimmed":
            content = (
                <Text mb={2}>You're plot has been trimmed. Looks flawless now!</Text>
            )
            break;
        default:
            content = (
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
            )
            break;
    }

    return (
        <VStack fontSize={"md"}>
            {content}
            {message.nft &&
                <Button
                    onClick={() => {
                        window.location.href =
                            window.location.href.split("?")[0] +
                            `?x=${message.nft.plotData.left}&y=${-message.nft.plotData.top}&id=${message.nft.id}`;
                    }}
                    variant="ghost"
                    rightIcon={<GraveBasicIcon maxH={"32px"}
                        maxW={"32px"}
                        color={"var(--chakra-colors-primary-100)"} />}
                >Click here to visit</Button>}
        </VStack>);
}
