import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const themeColors = {
  black: "#191b1a",
  lilac: "#747081",
  lilacDark: "#694F6E",
  lilacBright: "#918691",
  salmon: "#985F68",
  salmonBright: "#a06a64",
  salmonDark: "#613942",
  stone: "#918C86",
  stoneDark: "#6a5c62",
  stoneBright: "#ada59d",
  teal: "#447478",
  tealBright: "#178A88",
  tealDark: "#30565F",
  flame: "#bdb2a1",
  primary: {
    100: "#99c9b3",
    200: "#7dbbad",
    300: "#579c9a",
    400: "#458886",
    500: "#3a7076",
    600: "#294257",
    700: "#25343b",
    800: "#233031",
    900: "#191b1a",
  },
};

export const theme = extendTheme({
  shadows: { outline: '0' },
  config: {
    initialColorMode: "dark",
  },
  fonts: {
    body: "'Montserrat', 'Arial', 'Helvetica', sans-serif",
    heading: "'Montserrat', 'Arial', 'Helvetica', sans-serif",
  },
  colors: themeColors,
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          bg: "primary.800",
          color: "primary.100",
          borderRadius: "50px",
        },
      }),
    },
    Tooltip: {
      baseStyle: {
        color: "primary.100",
        bg: "primary.700",
        borderWidth: "1px",
        borderRadius: "8px",
        borderColor: "primary.100"
      },
    },
    Link: {
      variants: {
        underlined: {
          color: "primary.100",
          textDecoration: "underline",
          _hover: {
            color: "primary.300",
            textDecoration: "none",
          },
        },
      }
    },
    Button: {
      baseStyle: {
        color: "primary.100",
        borderWidth: "1px",
        borderRadius: "0px",
        borderColor: "primary.100",
      },
      variants: {
        ghost: {
          color: "primary.100",
          borderRadius: "4px",
          _hover: {
            color: "primary.100",
            _hover: {
              background: "primary.600",
            },
          },
        },
        grave: {
          borderRadius: "4px",
          borderTopLeftRadius: "28px",
          borderTopRightRadius: "28px",
          borderWidth: "1px",
          borderColor: "primary.100",
          backgroundColor: "primary.800",
          boxShadow: "rgb(0 0 0 / 99%) 0px 5px 10px",
          _hover: {
            color: "primary.100",
            _hover: {
              background: "primary.600",
            },
          },
        },
        inverted: {
          borderRadius: "4px",
          color: "primary.100",
          backgroundColor: "primary.900",
          _hover: {
            color: "primary.100",
            _hover: {
              background: "primary.600",
            },
          },
        },
      },
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        textColor: mode("primary.900", "primary.100")(props),
        bg: mode("primary.900", "primary.900")(props),
        fontSize: 20,
        height: "100%",
        minHeight: "100%",
      },
      html: {
        height: "100%",
        margin: "0px",
      },
    }),
  },
});
