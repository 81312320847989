import { send, decode, script, args, arg, cdc } from "@onflow/fcl";
import { Address } from "@onflow/types";

//TODO
const CODE = cdc`
    import FungibleToken from 0xFungibleToken
    import NonFungibleToken from 0xNonFungibleToken
    import FUSD from 0xFUSD
    import Necryptolis from 0xNecryptolis
    import NFTStorefront from 0xNFTStorefront
    import MetadataViews from 0xMetadataViews
  
    pub fun hasFUSD(_ address: Address): Bool {
        let receiver = getAccount(address)
            .getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)
            .check()
        let balance = getAccount(address)
            .getCapability<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance)
            .check()
        return receiver && balance
    }

    pub fun hasCollection(_ address: Address): Bool {
        return getAccount(address)
            .getCapability<&Necryptolis.Collection{NonFungibleToken.CollectionPublic, Necryptolis.NecryptolisCollectionPublic}>(Necryptolis.CollectionPublicPath)
            .check()
    }

    pub fun hasStorefront(_ address: Address): Bool {
        return getAccount(address)
            .getCapability<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(NFTStorefront.StorefrontPublicPath)
            .check()
    }

    pub fun hasResolverCollection(_ address: Address): Bool {
      return getAccount(address)
          .getCapability<&{MetadataViews.ResolverCollection}>(Necryptolis.ResolverCollectionPublicPath)
          .check()
    }

    pub fun main(address: Address): Bool {    
        return hasFUSD(address) && hasCollection(address) && hasStorefront(address) && hasResolverCollection(address);
    }
  
`;

export async function fetchIsUserInitalized(
  address: string | undefined
): Promise<Boolean> {
  if (!address) return Promise.resolve(false);

  try {
    const isInitalizedResult = await send([
      script(CODE),
      args([arg(address, Address)]),
    ]);
    const decoded = await decode<boolean>(isInitalizedResult);

    return decoded;
  } catch (err) {
    console.log("error happened!", err);
    if (typeof err === "object" && err) {
      console.log("keys", Object.keys(err));
    } else {
      console.log("type", typeof err);
    }
    return false;
  }
}
