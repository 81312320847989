import { Icon } from "@chakra-ui/react";
import React from "react";

export const LoggedOutIcon = (props: any) => (
  <Icon viewBox="0 0 64 64" width="64" height="64" {...props}>
    <path
      fill="#99C8B2"
      d="M22.8 46.7v12a1 1 0 1 1-2.2 0v-5.5h-6.5v5.5a1 1 0 1 1-2.2 0V52C12 43 18 35 26.7 32.3a14.2 14.2 0 1 1 11.8 0c3.6 1 6.6 3 9 5.6h-3A18.5 18.5 0 0 0 14.1 51h6.4v-4.4a1 1 0 1 1 2.2 0zm21.8-27.3a12 12 0 1 0-24 0 12 12 0 0 0 24 0z"
    />
    <path
      fill="#C17767"
      d="M55.6 43.3a1 1 0 0 0-1.6 0l-5 5-4.9-5a1 1 0 1 0-1.5 1.5l5 5-5 5a1 1 0 1 0 1.5 1.4l5-5 5 5a1 1 0 0 0 1.5 0 1 1 0 0 0 0-1.5l-5-5 5-4.9a1 1 0 0 0 0-1.5z"
    />
    <circle
      fill="none"
      stroke="#C17767"
      strokeWidth="2"
      strokeMiterlimit="10"
      cx="49.1"
      cy="49.8"
      r="11.9"
    />
  </Icon>
);
